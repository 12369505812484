import { useCallback, useEffect, useState } from "react";
import {
  deleteCacheItem,
  getCacheItem,
  setCacheItem,
} from "../helpers/cache.simple";

export function useCacheLocalStorage(cacheKey, ttl, apiCallFunctionMemorize) {
  const [cachedData, setCachedData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const cachedDataValue = await getCacheItem(cacheKey);
      if (cachedDataValue) {
        setCachedData(cachedDataValue);
        setError(null);
      } else {
        try {
          const newData = await apiCallFunctionMemorize();
          console.log("newData: ", newData);
          if (newData) {
            await setCacheItem(cacheKey, newData, ttl);
            setCachedData(newData);
            setError(null);
          }
        } catch (error) {
          console.log(
            "fetchData: Error fetching data from API:",
            error?.message
          );
          // throw error;
          setError({ hasErrorAuth: true });
        }
      }
    };

    fetchData();
  }, [cacheKey, ttl, apiCallFunctionMemorize]);

  const updateCache = async () => {
    try {
      const newData = await apiCallFunctionMemorize();
      if (newData) {
        await setCacheItem(cacheKey, newData, ttl);
        setCachedData(newData);
      }
    } catch (error) {
      console.log("updateCache: Error fetching data from API:", error?.message);
      // throw error;
      setError({ hasErrorAuth: true });
    }
  };

  const updateCacheMemo = useCallback(updateCache, [
    cacheKey,
    ttl,
    apiCallFunctionMemorize,
  ]);

  const deleteCache = async () => {
    await deleteCacheItem(cacheKey);
  };

  const reloadCache = useCallback(() => {
    updateCacheMemo();
  }, [updateCacheMemo]);

  return [cachedData, updateCacheMemo, deleteCache, error];
}
