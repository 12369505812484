import axios from "axios";
import coins from "../storage/coins.json";

export const getDetailedCoinData = async (coinId) => {
  try {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${coinId}?localization=false&tickers=true&market_data=true&community_data=false&developer_data=false&sparkline=false`
    );
    return response?.data;
  } catch (e) {
    console.log(e);
    return {
      id: coinId,
    };
  }
};

export const getCoinMarketChart = async (coinId, selectedRange) => {
  try {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${coinId}/market_chart?vs_currency=usd&days=${selectedRange}}&interval=hourly`
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getMarketList = async (page = 1) => {
  try {
    // const url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=${page}&sparkline=false&price_change_percentage=24h`;
    const url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=${page}`;
    const response = await axios.get(url);

    return response?.data;
  } catch (e) {
    console.log("e?.message", e?.message);
  }
};

export const getFavouritesCoins = async (page = 1, coinIds) => {
  try {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${coinIds}&order=market_cap_desc&per_page=50&page=${page}&sparkline=false&price_change_percentage=24h`
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getAllCoins = async () => {
  try {
    // console.log("getAllCoins:data: ", coins);
    let newCoins = [];
    if (coins) {
      newCoins = (coins || []).map((item) => {
        return {
          ...item,
          name: `${item?.name} (${item?.symbol?.toUpperCase()})`,
        };
      });
    }
    return newCoins || [];
    // const fs = require('fs');
    // const response = await axios.get(
    //   `https://api.coingecko.com/api/v3/coins/list?include_platform=false`
    // );
    // const jsonString = JSON.stringify(response?.data, null, 2); // The third argument (2) is for indentation

    // // Specify the file path
    // const filePath = 'coins.json';

    // // Write the JSON data to the file
    // fs.writeFile(filePath, jsonString, (err) => {
    //   if (err) {
    //     console.error('Error writing JSON file:', err);
    //   } else {
    //     console.log('JSON file has been written successfully.');
    //   }
    // });
    // return response?.data;
  } catch (e) {
    console.error(e);
  }
};

export const getOHLC = async ({ coinId, vs_currency, days }) => {
  try {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${coinId}/ohlc`,
      {
        params: {
          vs_currency,
          days,
        },
      }
    );

    // Extract OHLC data from the API response
    const ohlcData = response?.data;
    console.log("ohlcData: ", ohlcData);

    if (!ohlcData) {
      console.log(" no ohlcData: ", ohlcData);
      return [];
    }
    // Format data for the chart
    const formattedData = ohlcData.map((item) => {
      return {
        time: new Date(item[0]).toISOString(),
        open: item[1],
        high: item[2],
        low: item[3],
        close: item[4],
      };
    });

    return formattedData;
    // console.log("formattedData: ", JSON.stringify(formattedData, null, 2));
  } catch (error) {
    console.error("Error fetching BTC chart data:", error);
    return [];
  }
};

//  const filePath = 'ohlc.json';
//
// // Write the JSON data to the file
// fs.writeFile(filePath, JSON.stringify(formattedData, null, 2), (err) => {
//   if (err) {
//     console.error('Error writing JSON file:', err);
//   } else {
//     console.log('JSON file has been written successfully.');
//   }
// });

//   } catch (error) {
//     console.error('Error fetching BTC chart data:', error);
//   }
// };
