import axios from "axios";
import { SERVER_URL, SERVER_URL_DEV } from "../configs/api";

export const getUserAuth = async (credential) => {
  try {
    const resAuth = await axios.post(`${SERVER_URL}/webauth/google`, {
      token: credential,
    });
    return resAuth?.data;
  } catch (error) {
    console.log("error: ", error);
    return;
  }
};
