import axios from "axios";
import { SERVER_URL } from "../configs/api";

export const getUserPermission = async (email) => {
  try {
    if (!email) {
      console.log("No email");
      return;
    }

    const resPermission = await axios.post(`${SERVER_URL}/permission/project`, {
      email,
    });
    return resPermission?.data;
  } catch (error) {
    console.log("getPermission:error: ", error);
  }
};
