import { createSlice } from "@reduxjs/toolkit";

export const challangeSlice = createSlice({
  name: "challenge",
  initialState: {
    isOpen: false,
    addCard: undefined,
  },
  reducers: {
    addChallenge: (state, action) => {
      const { isOpen, cgid } = action.payload || {};
      state.addCard = {
        isOpen,
        card: {
          group: { cgid },
        },
      };
    },
    editChallenge: (state, action) => {
      const { isOpen, card, isEdit = true } = action.payload || {};
      state.addCard = {
        isOpen,
        isEdit,
        card,
      };
    },
    removeAddChallenge: (state, action) => {
      if (state.addCard) {
        delete state.addCard;
      }
    },
  },
});

export const { addChallenge, removeAddChallenge, editChallenge } =
  challangeSlice.actions;

export const getAddCard = (state) => state.challenge.addCard;

export default challangeSlice.reducer;
