import { CHALLENGE_SERVER_URL } from "../../configs/api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { challengeAPI } from "./challenge";
import { OR } from "../../utils/operator";

export const challengeGroupAPI = createApi({
  reducerPath: "challengeGroup",
  baseQuery: fetchBaseQuery({ baseUrl: CHALLENGE_SERVER_URL }),
  endpoints: (builder) => ({
    // getChallengeByUserId: builder.query({
    //   query: (uid) => {
    //     console.log("uid: ", uid);
    //     return {
    //       url: `/challenge/group/get-all`,
    //       method: "POST",
    //       body: { uid },
    //     };
    //   },
    // }),
    createChallengeGroup: builder.mutation({
      query: (newChallengeGroup) => {
        console.log("newChallengeGroup: ", newChallengeGroup);
        return {
          url: `/challenge/group/create`,
          method: "POST",
          body: newChallengeGroup,
        };
      },
      async onQueryStarted({ uid }, { dispatch, queryFulfilled }) {
        try {
          const { data: createdChaGroup } = await queryFulfilled;

          if (!createdChaGroup?.success || !createdChaGroup?.data) return;
          const newChaGroup = {
            group: createdChaGroup?.data?.newChallengeGroup,
            challenges: [],
          };

          dispatch(
            challengeAPI.util.updateQueryData(
              "getChallengeByUserId",
              uid,
              (draftChallenges) => {
                if (
                  !OR(
                    draftChallenges?.success,
                    draftChallenges?.data,
                    draftChallenges?.data?.challengeGroups
                  )
                ) {
                  return;
                }

                draftChallenges.data.challengeGroups.push(newChaGroup);
              }
            )
          );
        } catch (e) {
          console.log("Error: ", e);
        }
      },
    }),
    deleteChallengeGroupByUserId: builder.mutation({
      query: ({ uid, cgid }) => {
        console.log("uid, cgid: --->  ", uid, cgid);
        return {
          url: `/challenge/group/delete`,
          method: "POST",
          body: { uid, cgid },
        };
      },
      async onQueryStarted({ uid, cgid }, { dispatch, queryFulfilled }) {
        try {
          const { data: createdChaGroup } = await queryFulfilled;

          if (!createdChaGroup?.success || !createdChaGroup?.data) return;
          dispatch(
            challengeAPI.util.updateQueryData(
              "getChallengeByUserId",
              uid,
              (draftChallenges) => {
                if (
                  !OR(
                    draftChallenges?.success,
                    draftChallenges?.data,
                    draftChallenges?.data?.challengeGroups
                  )
                ) {
                  return;
                }
                const grpIndex = draftChallenges.data.challengeGroups.findIndex(
                  (cg) => cg?.group?.cgid === cgid
                );
                if (grpIndex === -1) return;
                draftChallenges.data.challengeGroups.splice(grpIndex, 1);
              }
            )
          );
        } catch (e) {
          console.log("Error: ", e);
        }
      },
    }),
  }),
});

export const {
  useCreateChallengeGroupMutation,
  useDeleteChallengeGroupByUserIdMutation,
} = challengeGroupAPI;
