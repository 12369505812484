import React, { useState, useEffect, useMemo } from "react";
import { ThreeDots } from "react-loader-spinner";
import {
  Box,
  AspectRatio,
  Image as NativeBaseImage,
  Pressable,
  Circle,
} from "native-base";
import Upload from "../Upload";
import { IoCloseCircle } from "react-icons/io5";
import ColorSelection from "./ColorSelection";
import { CHALLENGE_IMAGE_SERVER_URL } from "../../../configs/api";
import { COLOR_CALENDAR_MATRIX, COLOR_MATRIX } from "../constants";
import {
  FILE_TYPE,
  getFileTypeBaseOnFileName,
  getFileTypeBaseOnMime,
} from "../../../utils/file";
import ReactPlayer from "react-player";

const EDIT_COLOR_CARD_1 = "EDIT_COLOR_CARD_1";
const EDIT_COLOR_CARD_2 = "EDIT_COLOR_CARD_2";
const HeaderImageCard = ({
  cardColor,
  setCardColor,
  setShowModal,
  previewImage,
  setPreviewImage,
  isLoading,
  editElement,
  setEditElement,
}) => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const fileType = previewImage
    ? getFileTypeBaseOnFileName(previewImage?.name)
    : "";
  return (
    <Box
      width={240}
      height={240}
      style={{
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
      }}
    >
      {previewImage?.name && fileType === FILE_TYPE.IMAGE ? (
        <AspectRatio w="100%" ratio={0.8} maxH={240}>
          <NativeBaseImage
            source={{
              uri: previewImage?.name
                ? `${CHALLENGE_IMAGE_SERVER_URL}/${previewImage?.name}`
                : "",
            }}
            alt={"Header preview"}
          />
        </AspectRatio>
      ) : null}
      {fileType === FILE_TYPE.VIDEO && previewImage?.name ? (
        <AspectRatio w="100%" ratio={0.8} maxH={240}>
          <div className="flex justify-center items-center bg-black">
            <ReactPlayer
              className=""
              controls={true}
              width={"100%"}
              height={"auto"}
              url={
                previewImage?.name
                  ? `${CHALLENGE_IMAGE_SERVER_URL}/${previewImage?.name}`
                  : ""
              }
            />
          </div>
        </AspectRatio>
      ) : null}
      {uploadLoading && (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#020202"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      )}

      <Upload
        onPreview={(file) => {
          console.log("uploadfile: ", file);
          setPreviewImage(file);
        }}
        {...{ setUploadLoading }}
      />
      <Pressable
        onPress={() => {
          setEditElement(EDIT_COLOR_CARD_1);
          // setOpenColorPicker((openColorPicker) => !openColorPicker);
          // setOpenColorPicker2(false);
        }}
        position="absolute"
        right="8px"
        top="8px"
        bg={cardColor}
        w="24px"
        h="24px"
        borderRadius="50%"
      ></Pressable>

      <Pressable
        onPress={() => {
          setEditElement(EDIT_COLOR_CARD_2);
          // setOpenColorPicker2((openColorPicker2) => !openColorPicker2);
          // setOpenColorPicker(false);
        }}
        position="absolute"
        right="40px"
        top="8px"
        bg={cardColor}
        w="24px"
        h="24px"
        borderRadius="50%"
      ></Pressable>
      {editElement === EDIT_COLOR_CARD_1 ? (
        <ColorSelection
          setCardColor={setCardColor}
          setOpenColorPicker={setEditElement}
          colorBoard={COLOR_MATRIX}
        />
      ) : null}
      {editElement === EDIT_COLOR_CARD_2 ? (
        <ColorSelection
          setCardColor={setCardColor}
          setOpenColorPicker={setEditElement}
          colorBoard={COLOR_CALENDAR_MATRIX}
        />
      ) : null}
    </Box>
  );
};

export default HeaderImageCard;
