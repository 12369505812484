import React from "react";
import { MONEY_FLOW, MONEY_FLOW_TYPE, RETROACTIVE_TYPE } from "../../constants";
import moment from "moment";
import {
  formatOnlyDate,
  formatOnlyDateMonth,
  sortDatetimeByProperty,
} from "../../../../utils/datetime";
import { groupBy, values } from "lodash";

function MoneyFlow(props) {
  const { month } = props || {};
  // console.log("month: ", month);
  let list = sortDatetimeByProperty(
    MONEY_FLOW.filter((mf) => mf.moneyFlowType !== MONEY_FLOW_TYPE.NONE),
    "datetime"
  );

  // const month = 4;
  list = list.filter((l) => {
    return !month ? true : moment(l.datetime).get("M") + 1 === month;
  });

  const grps = values(groupBy(list, "moneyFlowType"));

  let totals = [];
  for (let grp of grps) {
    const tt = grp.reduce((tt, item) => {
      return tt + item?.usdValue;
    }, 0);
    const item = {
      title: grp[0]?.moneyFlowType,
      total: tt,
    };
    totals.push(item);
  }

  return (
    <div className="flex justify-center flex-wrap">
      <div className="flex-1 bg-white p-4 w-1/2">
        <div className="py-2 m-2 text-center font-bold">
          MONEY FLOW - {month}
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
              <tr>
                <th scope="col" className="px-3 py-3">
                  Date
                </th>
                <th scope="col" className="px-3 py-3">
                  Symbol
                </th>
                <th scope="col" className="px-3 py-3">
                  Wallet
                </th>
                <th scope="col" className="px-3 py-3">
                  Type
                </th>
                <th scope="col" className="px-3 py-3">
                  Quantity
                </th>
                <th scope="col" className="px-3 py-3">
                  Spent
                </th>
                <th scope="col" className="px-3 py-3">
                  URL
                </th>
                <th scope="col" className="px-3 py-3">
                  Note
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, key) => {
                const {
                  datetime,
                  symbol,
                  moneyFlowType,
                  quantity,
                  usdValue,
                  retroactiveType,
                  unlocks,
                  wallet,
                  notes,
                  isWithdraw,
                } = item || {};

                let color = "#6B7280";
                if (retroactiveType === RETROACTIVE_TYPE.STACK) {
                  color = "#765b46";
                }

                return (
                  <tr key={key} className={`${isWithdraw ? "bg-red-300" : ""}`}>
                    <td className="px-3 py-3">
                      {formatOnlyDateMonth(datetime)}
                    </td>
                    <td
                      className={`px-3 py-3 font-bold text-[${color
                        .replace('"', "")
                        .replace('"', "")}] `}
                    >
                      {symbol}
                    </td>
                    <td className="px-3 py-3">{wallet}</td>
                    <td className="px-3 py-3">{moneyFlowType}</td>
                    <td className="px-3 py-3">
                      {quantity} {symbol}
                    </td>
                    <td className="px-3 py-3">{usdValue}$</td>
                    <td className="px-3 py-3">
                      {!unlocks?.urls
                        ? "-"
                        : unlocks?.urls?.map((ul) => (
                            <a className="px-1" href={ul.url} alt={ul.name}>
                              {ul.name}
                            </a>
                          ))}
                    </td>
                    <td className="px-3 py-3">{notes}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              {totals.map((item) => (
                <tr className="font-semibold text-gray-900 dark:text-white bg-white">
                  <th scope="row" className="px-3 py-3 text-base">
                    {item?.title}
                  </th>
                  <td className="px-3 py-3">{item?.total}$</td>
                  {/* <td className="px-3 py-3">{"totalSpent"}$</td>
                  <td className="px-3 py-3">{"list"}</td>
                  <td
                    className={`px-3 py-3 ${
                      "totalSpent" >= 0 ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {"list"}%
                  </td> */}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
      </div>
      {/* ); */}
      {/* })} */}
    </div>
  );
}

export default MoneyFlow;
