import React from "react";

function CommonButton(props) {
  const {
    onClick = () => {},
    title,
    style = {},
    children,
    className,
  } = props || {};
  // return <button onClick={onClick}>{title}</button>;
  return (
    <button
      className={className}
      style={{
        color: "#fff",
        backgroundColor: "#007262",
        padding: 8,
        fontSize: 14,
        fontWeight: "bold",
        ...(style || {}),
      }}
      onClick={onClick}
    >
      {title || children || "BUTTON"}
    </button>
  );
}

export default CommonButton;
