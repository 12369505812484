import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  AspectRatio,
  Image as NativeBaseImage,
  Stack,
  Center,
  Heading,
  Text,
  HStack,
  Circle,
} from "native-base";
import TimeAgo from "react-timeago";
import { emptyChallengeCover } from "../../assets/images";
import { IoCloseCircle } from "react-icons/io5";
import {
  CHALLENGE_IMAGE_SERVER_URL,
  UPLOAD_FILE_SERVER,
} from "../../configs/api";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import { useDeleteChallengeByUserIdMutation } from "../../store/services/challenge";
import _ from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import EditCircle from "../../icons/EditCircle";
import { useDispatch } from "react-redux";
import { editChallenge } from "../../store/challangeSlice";
import { TAG_TYPES } from "./constants";
import Chip from "@mui/material/Chip";
import { getFileTypeBaseOnFileName } from "../../utils/file";
// import ReactPlayer from "react-player";
import videoPlayImage from "../../assets/round-play-button.png";

const CardItem = (props) => {
  const dispatch = useDispatch();
  const {
    card = {},
    filterTag,
    setFilterTag,
    playVideo,
    setPlayVideo,
  } = props || {};
  // console.log("card: ", card);
  const {
    name = "",
    type = "",
    description = "",
    image = "",
    startTime = Date.now(),
    completedTime = Date.now(),
    cid,
    color,
    group,
    tags,
    video,
  } = card || {};
  console.log("card: ", card);

  const isVideo = getFileTypeBaseOnFileName(image);
  // console.log("isVideo: ", isVideo);

  const { user } = useLoginGoogle();
  const videoRef = useRef(null);

  const [deleteChallengeByUserId] = useDeleteChallengeByUserIdMutation();
  const hasEnd = moment(completedTime).valueOf() > moment(startTime).valueOf();

  const pressDelete = async () => {
    console.log("pressDelete: ", cid);
    if (!cid || !user?.uid || !group?.cgid) {
      toast.error("No cid, uid or cgid");
      return;
    }

    const resDelAxios = await deleteChallengeByUserId({
      cid: cid,
      uid: user?.uid,
      cgid: group?.cgid,
    });
    if (resDelAxios?.data?.success) {
      toast.success("Delete challenge successful!");
    } else {
      toast.error("Delete challenge failed!");
    }
  };

  const clickEdit = () => {
    console.log("clickEdit: ", card);
    if (!card) {
      toast.error("No Card");
      return;
    }
    dispatch(editChallenge({ isOpen: true, card }));
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const clickPlayVideo = (videoName) => {
    console.log("videoName: ", videoName);
    // setOpenModal(true);
    setPlayVideo(videoName);
  };

  const checkIsPlaying = () => {
    if (videoRef.current) {
      return !videoRef.current.paused;
    }
    return false;
  };

  const [openModal, setOpenModal] = useState(false);

  if (!image && video) {
    // console.log("video: ", video);
    return (
      <div
        key={cid}
        className="align-middle mr-2 mt-2 shrink-0 w-60"
        style={{
          backgroundColor: color || "gray",
        }}
      >
        <div className="relative">
          <div className="relative w-60 h-36 overflow-hidden bg-gray-700">
            <video
              ref={videoRef}
              className="object-cover"
              src={`${CHALLENGE_IMAGE_SERVER_URL}/${video}`}
              // autoPlay
              loop
              muted
            />
            <img
              className={`${""} absolute w-12 h-12 left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 object-cover`}
              src={videoPlayImage}
              alt={"Header preview"}
              onClick={() => clickPlayVideo(video)}
            />
          </div>
          <div className="text-base text-white p-1">{name}</div>
          <div
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "#171717",
              borderRadius: 100,
            }}
            onClick={clickEdit}
          >
            <EditCircle width={24} height={24} fill={"#fff"} />
          </div>
          <Circle
            position="absolute"
            top="8px"
            left="8px"
            backgroundColor={"#171717"}
            onClick={pressDelete}
          >
            <IoCloseCircle size="24" color="#fff" />
          </Circle>
        </div>
      </div>
    );
  }

  return (
    <div
      key={cid}
      className="align-middle mr-2 mt-2 shrink-0 w-60"
      style={{ minHeight: 500 }}
    >
      <Box
        rounded="lg"
        overflow="hidden"
        borderColor="coolGray.200"
        borderWidth="1"
        _dark={{
          borderColor: "coolGray.600",
          backgroundColor: color || "gray.700",
        }}
        _web={{
          shadow: 2,
          borderWidth: 0,
        }}
        _light={{
          backgroundColor: "gray.50",
        }}
      >
        <Box backgroundColor={"red"}>
          <div className="relative w-60 h-60">
            <img
              className="absolute w-60 h-60 top-1/2 transform -translate-y-1/2 object-cover"
              src={
                image
                  ? `${CHALLENGE_IMAGE_SERVER_URL}/${image}`
                  : emptyChallengeCover
              }
              alt={"Header preview"}
            />
          </div>
          {/* <AspectRatio
            maxW={240}
            maxH={240}
            // ratio={0.8}
            backgroundColor={"red"}
            style={{
              overflow: "hidden",
            }}
          > */}
          {/* <div className="m-62 h-62 overflow-hidden"> */}
          {/* <img
            className="object-cover"
            style={{
              maxHeight: 240,
              maxWidth: 240,
            }}
            src={
              image
                ? `${CHALLENGE_IMAGE_SERVER_URL}/${image}`
                : emptyChallengeCover
            }
            alt={name}
          /> */}
          {/* </div> */}

          {/* <NativeBaseImage
              // size={240}
              maxW={240}
              maxH={240}
              // resizeMode="cover"
              source={{
                uri: "",
              }}
              alt={name}
              style={{
                backgroundColor: "red",
              }}
            /> */}
          {/* </AspectRatio> */}
          <Circle
            position="absolute"
            top="8px"
            left="8px"
            backgroundColor={"#171717"}
            onClick={pressDelete}
          >
            <IoCloseCircle size="24" color="#fff" />
          </Circle>
          <div
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "#171717",
              borderRadius: 100,
            }}
            onClick={clickEdit}
          >
            <EditCircle width={24} height={24} fill={"#fff"} />
          </div>
        </Box>
        <Stack p="4" space={3}>
          <Stack space={2}>
            <Heading size="md" ml="-1" minH="50px">
              {name}
            </Heading>
            <Text
              fontSize="xs"
              _light={{
                color: "violet.500",
              }}
              _dark={{
                color: "violet.400",
              }}
              fontWeight="500"
              ml="-0.5"
              mt="-1"
            >
              {type}
            </Text>
          </Stack>
          <div
            style={{ minHeight: "104px", height: "104px", overflow: "hidden" }}
          >
            <Text style={{ color: "#fff" }} fontWeight="400">
              {description}
            </Text>
          </div>
          <HStack alignItems="center" space={4} justifyContent="space-between">
            {tags?.length > 0 ? (
              tags?.map((tag) => {
                const fullTag = TAG_TYPES.find((t) => t.value === tag);
                return (
                  <Chip
                    key={tag}
                    label={fullTag?.label}
                    variant="outlined"
                    onClick={() => {
                      if (fullTag?.value) {
                        setFilterTag(fullTag?.value);
                      }
                    }}
                    style={{
                      color: "#fff",
                      borderColor: "#fff",
                    }}
                  />
                );
              })
            ) : (
              <div className="h-8"></div>
            )}
          </HStack>
          <HStack alignItems="center" space={4} justifyContent="space-between">
            <HStack alignItems="center">
              <Text
                color={"coolGray.600"}
                _dark={{
                  color: "warmGray.200",
                }}
                fontWeight={"400"}
              >
                {/* {hasEnd && <TimeAgo date={createdAt} />} */}
                {moment(startTime).format("DD-MM-YYYY")}
              </Text>
            </HStack>
            <HStack alignItems="center">
              <Text
                color="coolGray.600"
                _dark={{
                  color: "warmGray.200",
                }}
                fontWeight="400"
              >
                {hasEnd && moment(completedTime).format("DD-MM-YYYY")}
              </Text>
            </HStack>
          </HStack>
        </Stack>
      </Box>
    </div>
  );
};

export default CardItem;
