import * as React from "react";
const UploadVideo = (props) => (
  <svg viewBox="356.2132 255.2233 80 74" width={80} height={74} {...props}>
    <g transform="matrix(1, 0, 0, 1, 346.2131958007812, -710.138916015625)">
      <path
        style={{
          color: "#000000",
          enableBackground: "accumulate",
        }}
        d="m 14,965.36218 c -2.21601,0 -4.0000001,1.784 -4.0000001,4 l 0,8 8.0937501,0 10.34375,-12 -14.4375,0 z m 19.6875,0 -10.34375,12 14.75,0 10.34375,-12 -14.75,0 z m 20,0 -10.34375,12 14.75,0 10.34375,-12 -14.75,0 z m 20,0 -10.34375,12 16.65625,0 0,-8 c 0,-2.216 -1.78399,-4 -4,-4 l -2.3125,0 z m -63.6875002,16 0,44.00002 c 0,2.216 1.7839902,4 4.0000002,4 l 46.53125,0 c -0.33191,-1.2836 -0.53125,-2.6126 -0.53125,-4 0,-8.8366 7.16343,-16 16,-16 1.38738,0 2.71636,0.1994 4,0.5312 l 0,-28.53122 -70.0000001,0 z m 30.0000002,14 16,10.00002 -16,10 0,-20.00002 z m 36,16.00002 c -7.73199,0 -14,6.268 -14,14 0,7.732 6.26801,14 14,14 7.73198,0 14,-6.268 14,-14 0,-7.732 -6.26802,-14 -14,-14 z m 0,5 c 0.57711,0 1.07243,0.2653 1.4375,0.6562 1.66486,1.4837 3.30646,3.3365 4.96875,4.9376 0.80455,0.8043 0.78114,2.0314 0,2.8124 -0.78114,0.7812 -2.03138,0.7812 -2.8125,0 L 78,1023.1747 l 0,9.1875 c 0,1.1046 -0.8953,2 -2,2 -1.1047,0 -2,-0.8954 -2,-2 l 0,-9.1875 -1.59375,1.5937 c -0.78112,0.7812 -2.03136,0.7812 -2.8125,0 -0.78114,-0.781 -0.79674,-2.0081 0,-2.8124 1.62691,-1.6371 3.33311,-3.4238 4.96875,-4.9376 0.36508,-0.3909 0.86039,-0.6562 1.4375,-0.6562 z"
        fill="#000000"
        stroke="none"
        marker="none"
        visibility="visible"
        display="inline"
        overflow="visible"
      />
    </g>
  </svg>
);
export default UploadVideo;
