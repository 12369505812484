import React, { useEffect, useState } from "react";
import CommonButton from "../basic/CommonButton";
import Modal from "react-modal";
import useGoogleLoginCustom from "../../hooks/useGoogleLoginCustom";
import TextField from "@mui/material/TextField";
import {
  addColorEvent,
  IMAGE_DEFAULT,
  PORTFOLIO_TYPE,
  UPLOAD_TYPE,
} from "./constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonMUI from "./components/ButtonMUI";
import { formatNumberDigitCurrency } from "../../utils/number";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextareaMUI from "./components/TextareaMUI";
import { createTransaction } from "./Transaction/transaction.function";
import ModalFB from "../../elements/Flowbite/ModalFB";
import { isArray } from "lodash";
import * as Lucide from "lucide-react";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import { EVENT_SERVER_URL } from "../../configs/api";
import UploadFileFB from "../../elements/Flowbite/UploadFileFB";
import { uploadFile } from "../../apis/upload";
import { isHttpUrl } from "../../utils/url";

const EVENT_TYPE = {
  NONE: "NONE",
  FUTURE: "FUTURE",
  SWING: "SWING TRADE",
  BINANCE_TRADE: "BINANCE TRADE",
  EXPEARIMENT: "EXPEARIMENT",
  TECHNICAL_ANALYSIS: "TECHNICAL ANALYSIS",
  SAVING: "SAVING",
  // MISS: "MISS",
  SPOT: "SPOT",
  NEWS: "NEWS",
  PLAN: "PLAN",
  INSIGHT: "INSIGHT",
  MISS: "MISS",
  REALITY_CRYPTO: "REALITY CRYPTO", //Thực trạng diễn biến giá, tin tức
  PERSONAL_OPINION: "PERSONAL OPINION",
};

const WIN_COLOR = "#1CB05B";
const LOSE_COLOR = "#DE1738";
const BLACK_COLOR = "#000";
const CLOSE_COLOR = "#4169e1";
const WIN_TRADE = {
  color: WIN_COLOR,
  bgColor: "#fff",
  bColor: WIN_COLOR,
};
const LOSE_TRADE = {
  color: LOSE_COLOR,
  bgColor: "#fff",
  bColor: LOSE_COLOR,
};
const CLOSE_TRADE = {
  color: CLOSE_COLOR,
  bgColor: "#fff",
  bColor: CLOSE_COLOR,
};

const COLOR_TYPE = {
  NONE: "NONE",
  WIN_TRADE: "WIN TRADE",
  LOSE_TRADE: "LOSE TRADE",
  CLOSE_TRADE: "CLOSE TRADE",
  LIFE: "LIFE",
  INFO: "INFO",
  CASE_STUDY: "CASE STUDY",
  LEARN_TRADE: "LEARN TRADE",
};

const FOLLOW_EVENT_TYPE = {
  NONE: "NONE",
  MORE_INFO: "MORE INFO",
  RESULT: "RESULT",
  OPTIMIZE: "OPTIMIZE",
};

function TimelineModalComponent(props) {
  const {
    openModal,
    setOpenModal,
    clickSelectedUser,
    setTimeline,
    timeline,
    timelineFull,
  } = props || {};
  // const { loginGoogle, users, getUser, removeUser, activeUser } =
  //   useGoogleLoginCustom();

  const timelineShorthands = timelineFull?.map((tl) => {
    const rs = {
      _id: tl?._id,
      title: tl?.title,
    };
    return rs;
  });

  const { user: activeUser } = useLoginGoogle();

  const clickSelectUser = (user) => {
    clickSelectedUser(user);
    setOpenModal(false);
  };

  // const jsonData = {
  //   uid: "086c49ec-3ddb-58da-974f-763c951139cb",
  //   type: "SWING_TRADE",
  //   colorType: "LOSE_COLOR",
  //   title: "Event 3",
  //   datetime: moment("2023-03-05T11:30:00").toDate(),
  //   image: "",
  //   subtitle: ["Muốn gỡ kèo nóng vội -> mất tiếp 100$"],
  //   notes: "",
  // };

  const [type, setType] = useState(EVENT_TYPE.NONE);
  const [colorType, setColorType] = useState(COLOR_TYPE.WIN_TRADE);
  const [title, setTitle] = useState();
  const [datetime, setDatetime] = useState(moment());

  const [images, setImages] = useState([]);
  const [imageTmp, setImageTmp] = useState(IMAGE_DEFAULT);
  const [subtitle, setSubtitle] = useState([]);
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [notes, setNotes] = useState("");
  const [followEventId, setFollowEventId] = useState(undefined);
  const [followEventType, setFollowEventType] = useState(
    FOLLOW_EVENT_TYPE.NONE
  );

  // useEffect(() => {
  //   if (imageTmp) {
  //     if (images.includes(imageTmp)) {
  //       setImages((images) => [...images, imageTmp]);
  //     }
  //   }
  // }, [imageTmp, images]);

  const reset = () => {
    // setPortfolioType(PORTFOLIO_TYPE.ALL);
    setColorType(COLOR_TYPE.WIN_TRADE);
    setTitle("");
    setType(EVENT_TYPE.NONE);
    setSubtitle([]);
    setImages([]);
    setImageTmp("");
    // setPricePerCoin();
    setNotes("");
    setDatetime(moment());
    setFollowEventId(undefined);
    setFollowEventType(FOLLOW_EVENT_TYPE.NONE);
  };
  // const currencies = [
  //   {
  //     value: "USD",
  //     label: "$",
  //   },
  //   {
  //     value: "EUR",
  //     label: "€",
  //   },
  //   {
  //     value: "BTC",
  //     label: "฿",
  //   },
  //   {
  //     value: "JPY",
  //     label: "¥",
  //   },
  // ];

  const clickAddEvent = async () => {
    console.log("clickAddEvent", {
      activeUser,
      type,
      colorType,
      title,
      datetime,
      images,
      subtitle,
      notes,
      followEventId,
      followEventType,
    });

    if (!activeUser || !activeUser?.uid) {
      toast.error("No activeUser");
      return;
    }

    if (!colorType) {
      toast.error("Không có colorType");
      return;
    }
    if (!type) {
      toast.error("Không có type");
      return;
    }
    if (!title) {
      toast.error("Không có title");
      return;
    }
    if (!type) {
      toast.error("Không có type");
      return;
    }
    if (!subtitle || !isArray(subtitle)) {
      toast.error("Không có subtitle");
      return;
    }
    if (!datetime) {
      toast.error("Không có datetime");
      return;
    }

    const aEvent = {
      uid: activeUser?.uid,
      type: type,
      colorType,
      title: title,
      datetime: moment(datetime).toDate(),
      images,
      subtitle,
      notes,
      followEventId,
      followEventType,
    };
    console.log("aEvent: ", aEvent);
    // return;

    const url = `${EVENT_SERVER_URL}/crypto/event/create`;
    const resCreate = await axios.post(url, aEvent);

    if (resCreate?.data?.success) {
      reset();
      setOpenModal(false);

      setTimeline((tl) => [
        ...tl,
        { ...(resCreate?.data?.data?.newEvent || {}), ...addColorEvent() },
      ]);
      toast.info("Create transaction succcesful!");
    } else {
      toast.info("Create transaction failed!");
    }
  };

  const uploadImage = async (e) => {
    console.log("uploadImage: ");
    const resUpload = await uploadFile(e.target.files[0], {
      uploadType: UPLOAD_TYPE.EVENT,
    });
    console.log("resUpload:----- ", resUpload);
    if (resUpload?.url && isHttpUrl(resUpload?.url)) {
      console.log("resUpload?.url: ", resUpload?.url);
      setImageTmp(resUpload?.url);
      if (!images.includes(resUpload?.url)) {
        setImages([...images, resUpload?.url]);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setOpenModal(false)}
        className=""
        style={{
          overlay: {
            backgroundColor: "darkgray",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 12,
          },
        }}
        // contentLabel="Example Modal"
      >
        <div
          style={{
            // backgroundColor: "green",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // padding: 4,
          }}
        >
          <h4 style={{ fontWeight: "bold", margin: 8 }}>ADD EVENT</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              required
              label="Type"
              defaultValue={EVENT_TYPE.NONE}
              SelectProps={{
                native: true,
              }}
              // helperText="Please select your currency"
              style={{
                margin: 8,
                width: "50%",
              }}
              value={type}
              onChange={(event) => setType(event.target.value)}
            >
              {Object.entries(EVENT_TYPE).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </TextField>
            <TextField
              id="outlined-select-currency-native"
              select
              required
              label="Color type"
              defaultValue={COLOR_TYPE.WIN_TRADE}
              SelectProps={{
                native: true,
              }}
              // helperText="Please select your currency"
              style={{
                margin: 8,
                width: "50%",
              }}
              value={colorType}
              onChange={(event) => setColorType(event.target.value)}
            >
              {Object.entries(COLOR_TYPE).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </TextField>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <TextField
              required
              id="outlined-required"
              label="Title"
              style={{
                margin: 8,
                width: "50%",
              }}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <div
              className="w-1/2 m-2"
              style={{
                height: 55,
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                style={
                  {
                    // backgroundColor: "red",
                    // margin: 8,
                  }
                }
              >
                <DatePicker
                  value={datetime}
                  onChange={(date) => setDatetime(date)}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="flex flex-row w-full flex-wrap">
            <div className="w-1/2 h-auto m-2 max-h-56 flex justify-center items-center min-w-320 max-w-xs">
              <div className="w-1/2 flex items-center flex-col">
                <TextField
                  className="p-2 w-full"
                  required
                  id="outlined-number"
                  label="Image"
                  onChange={(e) => {
                    console.log("e.target.value: ", e.target.value);
                    // setImageTmp(e.target.value);
                    // if (isHttpUrl(e.target.value)) {
                    //   setImages((images) => [...images, e.target.value]);
                    // }
                  }}
                  defaultValue={""}
                  value={imageTmp}
                />
                <UploadFileFB
                  onChange={(e) => uploadImage(e)}
                  onDrop={(e) => {
                    e.preventDefault();
                    uploadImage(e);
                  }}
                  hintText=""
                  imagePreview={imageTmp || IMAGE_DEFAULT}
                />
                {/* <ButtonMUI label="View" onClick={() => {}} /> */}
              </div>
              <div className="w-1/2 flex flex-col">
                {isArray(images) &&
                  images?.map((image, key) => {
                    return (
                      <div key={key} className="w-full">
                        <img
                          className="max-h-8 max-w-xs"
                          src={image || ""}
                          alt="cover"
                        />
                      </div>
                    );
                  })}
              </div>
              {/* <img
                className="w-1/2 max-h-56  my-2 pr-2"
                src={images[0] || ""}
                alt="cover"
              /> */}
            </div>
            <div className="w-1/2 bg-slate-200 max-h-56 rounded-md m-2 max-w-xs flex flex-col items-start overflow-auto min-w-320 min-h-128">
              <div className="flex justify-center items-center  w-full">
                <TextField
                  required
                  id="outlined-number"
                  // type="Price Per Coin"
                  label="subtitle"
                  onChange={(e) => {
                    setCurrentSubtitle(e.target.value);
                  }}
                  value={currentSubtitle}
                  className="m-2 w-4/5"
                />
                <ButtonMUI
                  className="m-2 w-1/5"
                  label="Add"
                  onClick={() => {
                    setSubtitle((sub) => [...sub, currentSubtitle]);
                    setCurrentSubtitle("");
                  }}
                />
              </div>
              <div className="">
                {subtitle.map((sub, subKey) => {
                  return (
                    <div key={subKey} className="flex flex-row jus">
                      <div className="py-1 px-2">
                        {subKey + 1}. {sub}
                      </div>
                      <div
                        className="py-1 px-1 cursor-pointer"
                        onClick={() => {
                          setSubtitle((aSub) => {
                            const newSub = aSub.filter((s) => s !== sub);
                            return newSub;
                          });
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              required
              label="Follow event id"
              defaultValue={undefined}
              SelectProps={{
                native: true,
              }}
              // helperText="Please select your currency"
              style={{
                margin: 8,
                width: "50%",
              }}
              value={followEventId}
              onChange={(event) => {
                console.log("(event.target: ", event.target.value);
                setFollowEventId(event.target.value);
              }}
            >
              <option key={-1} value={undefined}>
                NONE
              </option>
              {timelineShorthands?.map((timeline) => (
                <option key={timeline?._id} value={timeline?._id}>
                  {timeline?.title}
                </option>
              ))}
              {/* </select> */}
            </TextField>
            <TextField
              id="outlined-select-currency-native"
              select
              required
              label="Follow event type"
              defaultValue={undefined}
              SelectProps={{
                native: true,
              }}
              // helperText="Please select your currency"
              style={{
                margin: 8,
                width: "50%",
              }}
              value={followEventType}
              onChange={(event) => {
                console.log("(event.target: ", event.target.value);
                setFollowEventType(event.target.value);
              }}
            >
              {Object.entries(FOLLOW_EVENT_TYPE)?.map(([key, followE]) => (
                <option key={key} value={key}>
                  {followE}
                </option>
              ))}
              {/* </select> */}
            </TextField>
          </div>
          <div style={{ width: "100%", margin: 8 }}>
            <TextareaMUI
              style={{ width: "100%" }}
              onChange={(e) => {
                // console.log("data: ", e.target.value);
                setNotes(e.target.value);
              }}
              value={notes}
            />
          </div>

          {/* <div
            style={{
              // margin: 8,
              borderRadius: 8,
              padding: 16,
              backgroundColor: "rgb(239, 242, 245)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={
                {
                  // backgroundColor: "red",
                }
              }
            >
              Total Spent
            </div>
            <div
              style={{
                fontSize: 24,
                fontWeight: "bold",
              }}
            >{`$ ${0}`}</div>
          </div> */}
          <div
            style={{
              margin: 24,
            }}
          >
            <ButtonMUI label="Add Event" onClick={clickAddEvent} />
          </div>

          {/* <TextField
          id="outlined-select-currency"
          select
          label="Select"
          defaultValue="EUR"
          helperText="Please select your currency"
          style={{
            margin: 8,
          }}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}

          {/* <CommonButton
          style={{
            marginTop: 8,
          }}
          title="OPEN MODAL"
          onClick={() => {
            clickSelectedUser(user);
            setOpenModal(false);
          }}
        /> */}
        </div>
      </Modal>
    </>
  );
}

export default TimelineModalComponent;
