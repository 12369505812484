import React, { useState } from "react";
import Game from "./Game";
import { Stack, VStack, Heading, Center, Divider, Button } from "native-base";
import NavigateMenu from "../../screens/NavigateMenu";
import { FaPlusCircle } from "react-icons/fa";
import { useSocket } from "../../Provider/SocketProvider";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";

const StackJoin = () => {
  const socket = useSocket();
  const { user } = useLoginGoogle();
  // console.log('user: ', user);
  const [isJoin, setIsJoin] = useState(false);
  const [rooms, setRooms] = useState([]);

  const pressJoin = () => {
    setIsJoin(true);
  };

  const pressCreateRoom = () => {
    console.log("pressCreateRoom: ", socket.id, user.uid, user.name);
    socket.emit("caro:createRoom", {
      socketId: socket.id,
      userId: user.uid,
      name: user.name,
    });
  };

  const roomRender = (room, key) => {
    return (
      <div key={key}>
        <Center
          key={key}
          m="2"
          variant="outline"
          bg="primary.500"
          // size="32"
          width={200}
          height={120}
          rounded="sm"
          _text={{
            color: "warmGray.50",
            fontWeight: "medium",
          }}
          shadow="3"
        >
          <span
            style={{
              color: "#fff",
              margin: "5px",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            Room 1
          </span>
          <Button
            onPress={pressJoin}
            isLoading={isJoin}
            isLoadingText="Submitting 4"
            variant="outline"
            _text={{
              color: "warmGray.50",
              fontWeight: "medium",
            }}
          >
            Join
          </Button>
        </Center>
      </div>
    );
  };

  return (
    <VStack space="2.5" mt="4" m="2">
      <Heading size="md">
        Room
        <div onClick={pressCreateRoom} style={{ justifyContent: "center" }}>
          <FaPlusCircle
            name="home"
            size={16}
            color={"#9A10BF"}
            style={{ margin: "4px" }}
          />
        </div>
      </Heading>
      <Stack direction="row" mb="2.5" mt="1.5" space={3}>
        {rooms.map(roomRender)}
      </Stack>
      <Divider />
    </VStack>
  );
};

function WrapGame() {
  return (
    <>
      <NavigateMenu />
      <StackJoin />
      <Center>
        <Game />
      </Center>
    </>
  );
}

export default WrapGame;
