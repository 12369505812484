import {
  Button,
  Label,
  Radio,
  Checkbox,
  TextInput,
  Textarea,
} from "flowbite-react";
import React, { useEffect, useState } from "react";
import Upload from "../Challenge/Upload";
import { SERVER_URL_DEV } from "../../configs/api";
import { FILE_TYPE, getFileTypeBaseOnMime } from "../../utils/file";
import { AspectRatio, Image } from "native-base";
import { toast } from "react-toastify";
import { wrapAxios } from "../../apis/wrapApi";
import { ATTRIBUTE_IMAGES, CARD_TEMPLATES } from "../../constants/yugiohcard";
import { HiMail } from "react-icons/hi";
import { Copy, Link, Search } from "lucide-react";
import { stringToSlug } from "../../utils/string";
import { SPACE } from "../Plan/constants";
import _ from "lodash";
import { clickCopy } from "../../utils/clipboard";
import { FaLink } from "react-icons/fa";
import AccordionGroup from "../Plan/components/customs/AccordionGroup";

function CustomInput(props) {
  const { label, value, setValue } = props || {};
  return (
    <div className="flex p-1 max-w-lg items-center justify-between">
      <div className="block p-1 min-w-min">
        <Label value={label} />
      </div>
      <TextInput
        className="m-1"
        type="text"
        icon={Link}
        required
        onChange={(e) => {
          setValue(e?.target?.value || null);
          // setSubTitle(e.target.value);
          // setTagGeneration(createTitleWithTag({ title, subTitle }));
        }}
        defaultValue={""}
        value={value}
      />
    </div>
  );
}

function getAttributeTemplate(category, attribute) {
  if (category === "Spell") {
    return ATTRIBUTE_IMAGES.SPELL;
  } else if (category === "Monster") {
    return ATTRIBUTE_IMAGES[attribute];
  } else if (category === "Trap") {
    return ATTRIBUTE_IMAGES.TRAP;
  }
}

function getCardTemplate(card, category) {
  const { normal, effect, fusion, ritual } = card;
  if (normal) {
    return CARD_TEMPLATES.NORMAL;
  } else if (effect) {
    return CARD_TEMPLATES.EFFECT;
  } else if (fusion) {
    return CARD_TEMPLATES.FUSION;
  } else if (ritual) {
    return CARD_TEMPLATES.RITUAL;
  } else if (category === "Spell") {
    return CARD_TEMPLATES.SPELL;
  } else if (category === "Trap") {
    return CARD_TEMPLATES.TRAP;
  }
}

const mapDataFromCard = (cardInfo) => {
  const {
    name,
    cleanName,
    konamiCode,
    category,
    normal,
    effect,
    fusion,
    ritual,
    flip,
    toon,
    attribute,
    type,
    level,
    atk,
    def,
    description,
    extraDeck,
  } = cardInfo || {};

  const cardTemplate = getCardTemplate(
    { normal, effect, fusion, ritual },
    category
  );

  const attributeTemplate = getAttributeTemplate(category, attribute);

  // const code = String(cardId).padStart(6, "0");
  const cardGeneration = {
    // id: cardId,
    // code,
    // cardImage: `${code}.png`,
    name,
    cardTemplate,
    level: Number(level),
    atk,
    def,
    attribute,
    attributeTemplate,
    category,
    subCategory: null,
    normal,
    effect,
    fusion,
    ritual,
    flip,
  };

  return cardGeneration;
};

const TAGS = ["#gamiQ", "#yugioh", "#yugiohpowerofchaos", "#joeythepassion"];

const CreateYugiohCard = () => {
  const [url, setUrl] = useState(
    "https://www.formatlibrary.com/cards/banisher-of-the-light"
  );
  const [cardInfo, setCardInfo] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);
  console.log("uploadFiles: ", uploadFiles);
  const [genImage, setGenImage] = useState(null);

  const clickGenerationCard = async () => {
    if (!uploadFiles?.length) {
      toast.info("No uploadFile");
      return;
    }

    if (!cardInfo) {
      toast.info("No cardOInfo");
      return;
    }

    const resData = await wrapAxios(`${SERVER_URL_DEV}/yugiohcard/generation`, {
      imageUpload: uploadFiles[0],
      card: cardInfo,
      imageUploads: uploadFiles,
    });
    setGenImage(resData?.data?.cardImage);
  };

  const clickGetCardData = async () => {
    if (!url) {
      toast.error("No Url");
      return;
    }

    const changeUrl = url.replace("/cards/", "/api/cards/");
    console.log("changeUrl: ", changeUrl);

    const resData = await wrapAxios(
      `${SERVER_URL_DEV}/yugiohcard/formatlibrary/getCardByUrl`,
      {
        url: changeUrl,
      }
    );

    console.log(
      "resData?.data?.cardInfo?.card: ",
      resData?.data?.cardInfo?.card
    );

    const mapCard = mapDataFromCard(resData?.data?.cardInfo?.card);
    console.log("mapCard: ", mapCard);

    setCardInfo(mapCard);

    setName(mapCard.name);
    setLevel(mapCard.level);
    setAttribute(mapCard.attribute);
    setAtk(mapCard.atk);
    setDef(mapCard.def);
    setCategory(mapCard.category);
    setSubCategory(mapCard.subCategory);
    setNormal(mapCard.normal);
    setEffect(mapCard.effect);
    setFusion(mapCard.fusion);
    setRitual(mapCard.ritual);

    // const defaultCard =   {
    //   id: 50,
    //   code: '000050',
    //   cardImage: joinTempDirectory(imageUpload?.name),
    //   name: 'Monster Highest ATK',
    //   cardTemplate: joinElementDirectory(`elements/card-templates/${objTypes[type]}`),
    //   level: 4,
    //   atk: 1950,
    //   def: 1318,
    //   attribute: ATTRIBUTES.DARK,
    //   attributeTemplate: joinElementDirectory('elements/attributes/earth.png'),
    //   category,
    //   subCategory: undefined
    // }
  };

  const [keyword, setKeyword] = useState("");

  const searchString = stringToSlug(keyword, " ") + " yugioh deviantart";

  const [name, setName] = useState(null);
  const [level, setLevel] = useState(null);
  const [atk, setAtk] = useState(null);
  const [def, setDef] = useState(null);
  const [attribute, setAttribute] = useState(null);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [effect, setEffect] = useState(null);
  const [fusion, setFusion] = useState(null);
  const [ritual, setRitual] = useState(null);
  const [normal, setNormal] = useState(null);

  useEffect(() => {
    setCardInfo((info) => ({
      ...info,
      name,
      level,
      atk,
      def,
      attribute,
      category,
      subCategory: subCategory || null,
      normal: normal === "false" ? false : true,
      effect: effect === "false" ? false : true,
      fusion: fusion === "false" ? false : true,
      ritual: ritual === "false" ? false : true,
    }));
  }, [
    name,
    level,
    atk,
    def,
    attribute,
    category,
    subCategory,
    normal,
    effect,
    fusion,
    ritual,
  ]);

  const [title, setTitle] = useState(null);
  const [subTitle, setSubTitle] = useState(null);
  const [tagGeneration, setTagGeneration] = useState(null);

  const createTitleWithTag = ({ title = "", subTitle = "" }) => {
    const fullTitle = `${title} - ${subTitle} - ${TAGS.join(" ")}`;
    return fullTitle;
  };

  const clickAddTag = () => {
    if (!title) {
      toast.info("Must have title");
      return;
    }

    if (!subTitle) {
      toast.info("Must have subTitle");
      return;
    }

    const fullTitle = createTitleWithTag({ title, subTitle });

    if (fullTitle.length > 100) {
      toast.info("Title must have length < 100 character");
      return;
    }
    setTagGeneration(fullTitle);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <AccordionGroup
        titleGroup={`GENERATION CARD`}
        defaultShow={true}
        // className="flex flex-1 w-full"
      >
        <div className="flex flex-row">
          <div className="flex flex-col">
            <div className="max-w-md p-2">
              <div className="mb-2 block">
                <Label htmlFor="email4" value="Your keyword" />
              </div>
              <TextInput
                id="search"
                type="text"
                icon={Search}
                // rightIcon={Search}
                placeholder="Jinzo"
                required
                onChange={(e) => {
                  setKeyword(e.target.value);
                  setUrl(
                    `https://www.formatlibrary.com/cards/${stringToSlug(
                      e.target.value,
                      "-"
                    )}`
                  );
                }}
              />
            </div>

            <div className="p-2 flex flex-row items-center ">
              Copy: {SPACE}
              {_.startCase(keyword)}
              <div
                className="flex mr-1 mb-1 m-2 justify-center items-center w-8 h-8 rounded-full bg-black cursor-pointer"
                onClick={() => clickCopy(_.startCase(keyword))}
              >
                <Copy fontWeight="bold" size="16" color="#fff" />
              </div>
            </div>

            <div className="p-2">
              Search {SPACE}
              <a
                href={`https://www.google.com/search?tbm=isch&q=${searchString}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {searchString}
              </a>
            </div>

            {uploadFiles?.map((uploadFile) => {
              console.log(
                "getFileTypeBaseOnMime(uploadFile?.mimetype): ",
                getFileTypeBaseOnMime(uploadFile?.mimetype)
              );
              return (
                <>
                  {getFileTypeBaseOnMime(uploadFile?.mimetype) ===
                    FILE_TYPE.IMAGE && (
                    <div className="w-60 h-60">
                      <img
                        src={`${SERVER_URL_DEV}/${uploadFile?.name || ""}`}
                        alt="Upload cover"
                      />
                    </div>
                  )}
                </>
              );
            })}

            <div className="flex w-20 h-20 bg-stone-500 relative">
              <Upload
                onPreview={(file) => setUploadFiles([file])}
                {...{
                  setUploadLoading: () => {},
                  serverApi: `${SERVER_URL_DEV}/upload/temp`,
                }}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="p-2">
              Search in formatlibrary {SPACE}
              <a
                href={`https://www.formatlibrary.com/cards/${stringToSlug(
                  keyword,
                  "-"
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {stringToSlug(keyword, "-")}
              </a>
            </div>
            <div className="flex p-2 max-w-md items-center">
              {/* <div className="block p-1">
            <Label htmlFor="website" value="Website" />
          </div> */}
              <TextInput
                className="m-1 w-3/4"
                id="search"
                type="text"
                icon={Link}
                // rightIcon={Search}
                // placeholder="https://www.formatlibrary.com/cards/banisher-of-the-light"
                required
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
              {/* 
          <label
            for="website"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Website URL
          </label>
          <input
            defaultValue={
              "https://www.formatlibrary.com/cards/banisher-of-the-light"
            }
            type="url"
            id="website"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            // placeholder="flowbite.com"
            required
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          /> */}
              <Button
                className="font-semibold m-1 w-1/4"
                gradientDuoTone="redToYellow"
                onClick={clickGetCardData}
              >
                Crawl
              </Button>
            </div>

            {cardInfo && (
              <div className="flex max-w-md flex-col gap-4 m-2">
                <Textarea
                  id="card"
                  placeholder="Leave a comment..."
                  required
                  value={JSON.stringify(cardInfo, null, 2)}
                  rows={18}
                />
                {/* <Button type="submit">Submit</Button> */}
                <Button
                  className="font-semibold"
                  gradientDuoTone="redToYellow"
                  onClick={clickGenerationCard}
                >
                  Generation
                </Button>
              </div>
            )}
          </div>

          <div className="flex max-w-md flex-col m-2">
            <CustomInput
              {...{ value: name, setValue: setName, label: "Name" }}
            />
            <CustomInput
              {...{ value: level, setLevel: setLevel, label: "Level" }}
            />
            <CustomInput {...{ value: atk, setValue: setAtk, label: "Atk" }} />
            <CustomInput {...{ value: def, setValue: setDef, label: "Def" }} />
            <CustomInput
              {...{
                value: attribute,
                setValue: setAttribute,
                label: "Attribute",
              }}
            />
            <CustomInput
              {...{ value: category, setValue: setCategory, label: "Category" }}
            />
            <CustomInput
              {...{
                value: subCategory,
                setValue: setSubCategory,
                label: "SubCategory",
              }}
            />
            <CustomInput
              {...{ value: normal, setValue: setNormal, label: "Normal" }}
            />
            <CustomInput
              {...{ value: effect, setValue: setEffect, label: "Effect" }}
            />
            <CustomInput
              {...{ value: fusion, setValue: setFusion, label: "Fusion" }}
            />
            <CustomInput
              {...{ value: ritual, setValue: setRitual, label: "Ritual" }}
            />
          </div>
          <div className="flex max-w-md flex-col gap-4 m-2">
            {genImage && (
              <div className="flex m-2 ">
                <div className="w-64 h-96">
                  <img
                    src={`${SERVER_URL_DEV}/${genImage || ""}`}
                    alt="Generation cover"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </AccordionGroup>

      <AccordionGroup
        titleGroup={`GENERATION TEXT`}
        defaultShow={true}
        // className="flex flex-1 w-full"
      >
        <div className="flex flex-col items-center">
          <div className="flex p-2 max-w-lg items-center">
            <div className="block p-1 min-w-min">
              <Label htmlFor="website" value="Subtitle" />
            </div>
            <TextInput
              className="m-1"
              type="text"
              icon={Link}
              required
              onChange={(e) => {
                setSubTitle(e.target.value);
                setTagGeneration(createTitleWithTag({ title, subTitle }));
              }}
              defaultValue={""}
              value={subTitle}
            />
          </div>
          <div className="flex p-2 max-w-lg items-center">
            <div className="block p-1">
              <Label htmlFor="website" value="Title" />
            </div>
            <TextInput
              className="m-1 w-3/4"
              type="text"
              icon={Link}
              required
              onChange={(e) => {
                setTitle(e.target.value);
                setTagGeneration(createTitleWithTag({ title, subTitle }));
              }}
              defaultValue={""}
              value={title}
            />
          </div>
          <div className="flex p-2 max-w-lg items-center">
            <div className="block p-1">
              <Label htmlFor="website" value="Gen Text" />
            </div>
            <TextInput
              className="m-1 w-3/4"
              type="text"
              icon={Link}
              required
              onChange={(e) => {
                setTagGeneration(e.target.value);
              }}
              defaultValue={""}
              value={tagGeneration}
            />
          </div>
          <Button
            className="m-2 font-semibold w-1/4"
            gradientDuoTone="redToYellow"
            onClick={clickAddTag}
          >
            Add Tag
          </Button>
        </div>
      </AccordionGroup>
    </div>
  );
};

export default CreateYugiohCard;
