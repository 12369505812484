import React, { useState, useEffect, useMemo } from "react";
import { Stack, Text, Input } from "native-base";
import _ from "lodash";
import moment from "moment/moment";

const START_TIME = "START_TIME";
const StartTimeHeadingCard = ({
  newStartTime,
  setNewStartTime,
  editElement,
  setEditElement,
}) => {
  // console.log(
  //   "newStartTime: ",
  //   newStartTime,
  //   moment(newStartTime).format("DD-MM-YYYY")
  // );
  const formatNewStartTime = moment(newStartTime || 0).format("DD-MM-YYYY");
  const handleClick = _.debounce((e) => {
    if (e.detail !== 2) return;
    setEditElement(START_TIME);
  }, 100);

  const onClickHeadingInput = _.debounce((e) => {
    if (e.detail !== 2) return;
    setEditElement(null);
  }, 200);

  return (
    <>
      {editElement === START_TIME ? (
        <Input
          height={18}
          size="sm"
          placeholder="Heading"
          w="100%"
          maxWidth="112px"
          textAlign="center"
          defaultValue={formatNewStartTime}
          onClick={onClickHeadingInput}
          fontWeight="500"
          isFocused={true}
          color={"gray.700"}
          pr={0}
          pl={0}
          onChangeText={(text) => {
            console.log("text:1 ", text);
            const isValid = moment(text, "DD-MM-YYYY").isValid();
            if (!isValid) return;
            setNewStartTime(moment(text, "DD-MM-YYYY").toDate());
          }}
          onKeyPress={({ nativeEvent: { key: keyValue } }) => {
            // console.log('e: ', keyValue)
            if (keyValue === "Enter") {
              setEditElement(null);
            }
          }}
          // _light={{
          //   color: "violet.500",
          // }}
        />
      ) : (
        <Stack space={2}>
          <Text
            fontSize="12px"
            color="#fff"
            // _light={{
            //   color: "#fff",
            // }}
            // _dark={{
            //   color: "violet.400",
            // }}
            fontWeight="500"
            ml="-0.5"
            mt="-1"
            onClick={handleClick}
          >
            {formatNewStartTime}
          </Text>
        </Stack>
      )}
    </>
  );
};

export default StartTimeHeadingCard;
