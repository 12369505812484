import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import { Download } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 1000,
    height: window.height,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const itemData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function TitlebarImageList({ header, itemData }) {
  console.log("itemData: ", itemData);
  const classes = useStyles();

  const clickDownLoad = ({ img, title }) => {
    console.log("clickDownLoad: ");

    // Specify the path to the image in the public folder
    // const imagePath = `${process.env.PUBLIC_URL}/images/my-image.jpg`;

    // Create a link element
    const link = document.createElement("a");
    link.href = img;
    link.download = `${title}.png`; // Name of the downloaded file

    // Append to the DOM and trigger click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  };

  return (
    <div className={classes.root}>
      <ImageList rowHeight={345} className={classes.imageList}>
        <ImageListItem
          className="flex justify-center items-center"
          key="Subheader"
          cols={2}
          style={{ height: "auto" }}
        >
          <div className="font-bold text-3xl p-2">{header}</div>
          {/* <ListSubheader className="font-bold text-3xl" component="div">
            {header}
          </ListSubheader> */}
        </ImageListItem>
        {itemData.map((item) => (
          <ImageListItem key={item.img} cols={1 / 2} rows={1}>
            <img src={item.img} alt={item.title} />
            <ImageListItemBar
              title={`${item.title}`}
              subtitle={<span></span>}
              actionIcon={
                <IconButton
                  aria-label={`info about ${item.title}`}
                  className={classes.icon}
                  onClick={() => clickDownLoad(item)}
                >
                  <Download />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
