import { CARO_CONFIG } from "./config";
import _ from 'lodash';

const check = (direction, squares, location) => {
  let iCount = 0;
  let xChange = location.x;
  let yChange = location.y;
  let currentChar = squares[location.x][location.y];
  console.log('currentChar: ', currentChar);
  let prevent = 0;
  let line = [];
  while(true){

    switch(direction){
      case 'ToRight':
        yChange++;
        break;
      case 'ToDown':
        xChange++;
        break;
      case 'ToRightDown':
        xChange++;
        yChange++;
        break;
      case 'ToLeftDown':
        yChange--;
        xChange++;
        break;
      case 'ToLeft':
        yChange--;
        break;
      case 'ToLeftUp':
        yChange--;
        xChange--;
        break;
      case 'ToRightUp':
        xChange--;
        yChange++;
        break;
      case 'ToUp':
        xChange--;
        break;
    }
    console.log('yChange: ', yChange);
    // out of board
    console.log('yChange >= CARO_CONFIG.defaultWidth: ', yChange >= CARO_CONFIG.defaultWidth);
    if(
      yChange >= CARO_CONFIG.defaultWidth ||
      yChange < 0 ||
      xChange >= CARO_CONFIG.defaultHeight ||
      xChange < 0
    ){
      prevent++;
      return { count: iCount, prevent, line };
    }

    // chưa có quân cờ
    const nextCheck = squares[xChange][yChange];
    if(_.isNull(nextCheck)){
      return { count: iCount, prevent, line };
    }

    if(nextCheck === currentChar){
      // đụng cờ của mình
      iCount++;console.log();
      line.push({ x: xChange, y: yChange });
    } else {
      // đụng cờ của đối thủ
      prevent++;
      return { count: iCount, prevent, line };
    }
  }
}

const checkPlus = (directions, squares, location) => {
  // kiểm tra hàng ngang
  let count = 1;
  let prevent = 0; // số đầu bị chặn
  let line = [{ x: location.x, y: location.y }];
  for(let direction of directions){
    const result = check(direction, squares, location);
    count += result.count;
    prevent += result.prevent;
    line = [...line, ...result.line];
  }
  // console.log('{ count, prevent, line }: ', { count, prevent, line });
  if(prevent === 2){
    console.log("Chặn 2 đầu");
  }
  if(count >= CARO_CONFIG.nSquareToWin && prevent !== 2){
    console.log("  ===> Win", squares[location.x][location.y], 'thắng', line);
    const winner = {
      val: squares[location.x][location.y],
      line,
    }
    return winner;
  }
  return null;
}

export function calculateWinner(squares, location) {
  if(!location) return null;
  
  const directions = [
    ["ToRight", "ToLeft"],
    ["ToUp", "ToDown"],
    ["ToRightUp", "ToLeftDown"],
    ["ToRightDown", "ToLeftUp"]
  ];
  for(let direction of directions){
    const result = checkPlus(direction, squares, location);
    if(result){
      console.log('result: ', result);
      return result;
    }
  }
  return null;
}