import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// import { Waypoint } from "react-waypoint";
import "./style.css";
import { STATUS, data } from "./list";
// import { facebookConfig } from "../social-config";
// import { schoolPride } from "../confetti";
import { entries, groupBy } from "lodash";
import {
  calculatorImageSizeFitWindowSize,
  getImageSize,
  setImageCenter,
  setImageCenterByImageUrl,
} from "../../utils/image";
import { disableScroll } from "../../utils/browserAPI";
import ImagePreview from "../../modules/ImagePreview";

function TripChecklist() {
  const imageRef = useRef(null);
  const [photos, setPhotos] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [filter, setFilter] = useState([STATUS.WANT_TO_GO]);
  const [show, setShow] = useState(false);
  const [selectItem, setSelectItem] = useState(false);

  const filterPhotos = photos.filter((it) => {
    if (filter?.length === 0) return true;
    return filter.includes(it.status);
  });

  const fetchData = useCallback(() => {
    setShowLoading(false);
    setPhotos(data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const pickImage = async (item) => {
    setShow(!show);
    setSelectItem(item);
    setImageCenterByImageUrl(imageRef, item?.url);
    disableScroll(true);
  };

  const renderImage = (photo, index) => {
    const { width, height, url, status, title } = photo || {};
    return (
      <div key={index} className="gallery__photo-wrapper">
        <div
          className="gallery__photo-container"
          style={{
            paddingBottom: (height / width) * 100 + "%",
          }}
        >
          <img alt="main" className="gallery__photo" src={url} />
          <div
            onClick={() => {
              pickImage(photo);
            }}
            className="gallery__photo-hover"
          />
        </div>

        <div className="gallery__photo-info">
          {status !== STATUS.NONE && (
            <div className="gallery__photo-info__like">
              {/* <i className="fa fa-heart" /> */}
              {status === STATUS.COMPLETED && (
                <>
                  <i style={{ color: "#2e8b57" }} className="fa fa-check"></i>
                  <span>Done</span>
                </>
              )}
              {status === STATUS.WANT_TO_GO && (
                <>
                  <i style={{ color: "#4169e1" }} className="fa fa-flag"></i>
                  <span>Want to go</span>
                </>
              )}
              {status === STATUS.QUESTION && (
                <>
                  <i style={{ color: "#cf352e" }} className="fa fa-flag"></i>
                  <span>???</span>
                </>
              )}
            </div>
          )}

          {title && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgb(0 0 0 / 60%)",
                width: "100%",
                height: 42,
                bottom: 20,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 1 + "rem",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  padding: 8,
                  color: "#fff",
                  // fontWeight: "bold",
                }}
              >
                {title}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderGrid = () => {
    const data1 = filterPhotos.filter((d, i) => i % 3 === 0);
    const data2 = filterPhotos.filter((d, i) => i % 3 === 1);
    const data3 = filterPhotos.filter((d, i) => i % 3 === 2);
    return (
      <div className="gallery__grid-photos">
        <div className="gallery__grid-photos__grid">
          {data1.map((photo, index) => renderImage(photo, index * 3))}
        </div>
        <div className="gallery__grid-photos__grid">
          {data2.map((photo, index) => renderImage(photo, index * 3 + 1))}
        </div>
        <div className="gallery__grid-photos__grid">
          {data3.map((photo, index) => renderImage(photo, index * 3 + 2))}
        </div>
      </div>
    );
  };

  // const renderList = () => {
  //   return (
  //     <div className="gallery__list-photos">{photos.map(renderImage)}</div>
  //   );
  // };

  const changeFilter = (checkbox) => {
    setFilter((ft) => {
      if (ft.includes(checkbox)) {
        return [];
      } else {
        return [checkbox];
      }
      // if (ft.includes(checkbox)) {
      //   return ft.filter((it) => it !== checkbox);
      // } else {
      //   return [...ft, checkbox];
      // }
    });
  };

  const groupByStatus = entries(groupBy(data, "status"));
  const listCheckbox = groupByStatus.map(([name, items]) => {
    return { name, items };
  });

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        width: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="gallery" style={{ backgroundColor: "#fff" }}>
        <h1
          className="gallery__header"
          style={{ fontSize: 3 + "rem", paddingTop: 8, paddingBottom: 8 }}
        >
          DU NGOẠN
        </h1>

        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {listCheckbox.map((status, key) => {
            return (
              <label
                key={key}
                className="container"
                style={{ fontSize: 1 + "rem" }}
              >
                {status.name}
                {` (${status.items?.length})`}
                <input
                  type="checkbox"
                  checked={filter.includes(status.name) ? "checked" : null}
                  onChange={(e) => changeFilter(status.name)}
                />
                <span className="checkmark"></span>
              </label>
            );
          })}
        </div>
        {renderGrid()}

        {showLoading ? <div className="_loading" /> : null}
      </div>
      <ImagePreview
        ref={imageRef}
        {...{
          setShow,
          show,
          selectItem,
        }}
      />
    </div>
  );
}

export default TripChecklist;
