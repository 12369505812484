import moment from "moment";
import axios from "axios";
import { isArray } from "lodash";
import { parseRecurrence } from "./calendar";
import { COLOR_CALENDAR, GOOGLE_CALENDAR_API_KEY } from "../config";

export const mapCalendarToGantt = (calendarItem) => {
  const { summary, start, end, creator, id, updated } = calendarItem;
  // console.log("calendarItem: ", calendarItem);
  let colorId = calendarItem?.colorId;
  if (typeof colorId === "undefined") {
    colorId = 6;
  }

  return {
    ...calendarItem,
    id: id,
    calendarId: creator?.email,
    start: moment(start?.dateTime).toISOString(),
    end: moment(end?.dateTime).toISOString(),
    name: summary,
    // progress: 100,
    // isDisabled: true,
    // type: "task",
    updated,
    colorId,
    // dependencies: ["Task 0"],
    // project: "ProjectSample",
    // displayOrder: 3,
    styles: {
      // backgroundColor: "red",
      // backgroundSelectedColor: "red",
      progressColor: COLOR_CALENDAR[colorId]
        ? COLOR_CALENDAR[colorId]
        : "#FF74B1",
      // progressSelectedColor: "red",
    },
  };
  // id*	string	Task id.
  // name*	string	Task display name.
  // type*	string	Task display type: task, milestone, project
  // start*	Date	Task start date.
  // end*	Date	Task end date.
  // progress*	number	Task progress. Sets in percent from 0 to 100.
  // dependencies	string[]	Specifies the parent dependencies ids.
  // styles	object	Specifies the taskbar styling settings locally. Object is passed with the following attributes:
  // - backgroundColor: String. Specifies the taskbar background fill color locally.
  // - backgroundSelectedColor: String. Specifies the taskbar background fill color locally on select.
  // - progressColor: String. Specifies the taskbar progress fill color locally.
  // - progressSelectedColor: String. Specifies the taskbar progress fill color globally on select.
  // isDisabled	bool	Disables all action for current task.
  // fontSize	string	Specifies the taskbar font size locally.
  // project	string	Task project name
  // hideChildren	bool	Hide children items. Parameter works with project type only
};

export const getEventDetail = async (calendarId, eventId, permissionToken) => {
  try {
    const url = `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(
      calendarId
    )}/events/${eventId}?key=${GOOGLE_CALENDAR_API_KEY}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${permissionToken}`,
        Accept: "application/json",
      },
    });
    return res && res?.data;
  } catch (error) {
    console.log("error: ", error);
    return;
  }
};

export const getWeeklyEvent = async (calendarId, permissionToken, strQuery) => {
  try {
    const cId = encodeURIComponent(calendarId);
    console.log("cId:---- ", cId);
    // const static2 = `https://www.googleapis.com/calendar/v3/calendars/nguyenquanit17%40gmail.com/events?timeMin=2024-02-11T17%3A00%3A00.000Z&timeMax=2024-02-18T16%3A59%3A59.999Z`;
    // console.log("static: ", static2);
    // key=${GOOGLE_CALENDAR_API_KEY}&
    const CALENDAR_URL = `https://www.googleapis.com/calendar/v3/calendars/${cId}/events?${strQuery}`;
    const res = await axios.get(CALENDAR_URL, {
      headers: {
        Authorization: `Bearer ${permissionToken}`,
        Accept: "application/json",
      },
    });
    if (!res || !res?.data) {
      return;
    }
    console.log("res?.data: ", res?.data, JSON.stringify(res?.data, null, 2));
    return res?.data;
  } catch (error) {
    console.log("getWeeklyEvent:error: ", error);
    return;
  }
};

export const getTaskAPI = async (calendarId, eventId, permissionToken) => {
  try {
    const url = `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(
      calendarId
    )}/events/${eventId}?key=${GOOGLE_CALENDAR_API_KEY}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${permissionToken}`,
        Accept: "application/json",
      },
    });
    return res && res?.data;
  } catch (error) {
    console.log("error: ", error);
    return;
  }
};

export const getExpireTaskByRecurrence = (recurrence) => {
  // console.log("getExpireTask: ");

  // console.log(
  //   "parseRecurrence",
  //   recurrence,
  //   isArray(recurrence),
  //   parseRecurrence(recurrence)
  // );

  if (!recurrence) {
    console.log("No recurrence: ", recurrence);
    return;
  }
  const rrule = recurrence.find((entry) => entry.startsWith("RRULE:"));
  const untilMatch = /UNTIL=([^;]+)/.exec(rrule);

  if (untilMatch) {
    const untilValue = untilMatch[1];
    // console.log("UNTIL Value:", untilValue);
    return untilValue;
  } else {
    // console.log("UNTIL not found in RRULE");
    return false;
  }
};

// "FR,MO,TH,TU,WE"
const BY_DAY_OBJECT = {
  MO: 0,
  TU: 1,
  WE: 2,
  TH: 3,
  FR: 4,
};

export function extractRecurrenceToObject(recurrences) {
  try {
    const obj = {};
    if (!recurrences?.length) {
      console.log("recurrences: no recurence", recurrences);
      return {};
    }
    recurrences.forEach((ele) => {
      if (ele.includes("RRULE:")) {
        const [ruleKey, ruleValue] = ele.split(":");
        if (ruleKey) {
          obj.RRULE = true;
        }
        const arr = ruleValue.split(";");
        for (let str of arr) {
          let [key, value] = str.split("=");

          if (key === "BYDAY") {
            obj[key] = value
              .split(",")
              .map((str) => BY_DAY_OBJECT[str])
              .sort();
          } else {
            obj[key] = value;
          }
        }
      }
      if (ele.includes("EXDATE;")) {
        const [exdateStr, tzid] = ele.split(";");
        if (exdateStr) {
          obj.EXDATE = true;
        }
        const time = tzid.split(":").slice(1)[0].split(",");
        obj.TZID = tzid.split("=")[1].split(":")[0];
        obj.TIME = time;
      }
    });
    return obj;
  } catch (error) {
    console.log("error:extractRecurrenceToObject ", error);
    return {};
  }
}
