import React from "react";

const colorPaletes = [
  "#D864A9",
  "#9D71C9",
  "#3E7CCF",
  "#007FBA",
  "#007B91",
  "#007262",
];
colorPaletes[-1] = "Generic Gradient";
const colorPaletes2 = ["#D864A9", "#54424B", "#BBA5B0", "#BC812A", "#825000"];
colorPaletes2[-1] = "Classy Palette";

function ColorComponent() {
  const renderColor = (colorPaletes) => {
    return (
      <div className="bg-white flex items-center flex-col m-4">
        <div>
          <a href="https://mycolor.space/?hex=%23D864A9&sub=1">Color</a>
        </div>
        <div className="bg-white flex justify-center items-center flex-wrap">
          {colorPaletes.map((color, key) => {
            return (
              <div
                key={key}
                className="p-2 text-sm"
                style={{
                  backgroundColor: color,
                }}
              >
                {color}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderColor(colorPaletes)}
      {renderColor(colorPaletes2)}
    </div>
  );
}

export default ColorComponent;
