import React, { useEffect, useState } from "react";

function AccordionGroup(props) {
  const {
    titleGroup,
    hideTitle = false,
    children,
    groupWrapStyle = {},
    groupTitleStyle = {},
    defaultShow = false,
    // show,
    // setShow,
    className,
  } = props || {};
  const [showLocal, setShowLocal] = useState(defaultShow);

  return (
    <div
      className={`${className}`}
      style={{
        // margin: 24,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "#fff",
        ...(groupWrapStyle || {}),
      }}
    >
      {!hideTitle && (
        <div
          style={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
            padding: 8,
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#54424B",
            // borderRadius: 6,
            ...(groupTitleStyle || {}),
          }}
          onClick={() => setShowLocal(!showLocal)}
        >
          {titleGroup}
        </div>
      )}
      <div
        style={{
          display: showLocal ? "flex" : "none",
          flexDirection: "column",
        }}
      >
        {/*
        <AccordionMUI
          title={title}
          titleStyle={{
            backgroundColor: "#fff",
            fontWeight: "bold",
            textAlign: "center",
            ...(titleStyle || {}),
          }}
          style={{
            margin: 24,
          }}
        > */}
        {/* <MonthlyInvesment /> */}
        {children}
        {/* </AccordionMUI> */}
      </div>
    </div>
  );
}

export default AccordionGroup;
