export const OR = (...params) => {
  if (!params || params?.length === 0) {
    console.log("No params: ", params);
    return;
  }
  for (let param of params) {
    if (param) {
      return param;
    }
  }
};

export const spreadObject = (obj) => {
  return { ...(obj || {}) };
};
