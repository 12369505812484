import _ from "lodash";

export const padTwoNumber = (number) => {
  const strNumber = String(number);
  return strNumber.padStart(2, "0");
};

export const truncateMiddle = (str, frontLen, backLen, truncateStr) => {
  if (str === null) {
    return "";
  }
  var strLen = str.length;
  // Setting default values
  frontLen = ~~frontLen; // will cast to integer
  backLen = ~~backLen;
  truncateStr = truncateStr || "&hellip;";
  if (
    (frontLen === 0 && backLen === 0) ||
    frontLen >= strLen ||
    backLen >= strLen ||
    frontLen + backLen >= strLen
  ) {
    return str;
  } else if (backLen === 0) {
    return str.slice(0, frontLen) + truncateStr;
  } else {
    return str.slice(0, frontLen) + truncateStr + str.slice(strLen - backLen);
  }
};

export const tripNonDigit = (string) => {
  if (!_.isString(string)) {
    return "";
  }

  try {
    parseFloat(string);
  } catch (error) {
    console.log("error: ", error);
  }

  return string.replace(/[^\d.]/g, "");
};

// const allPossibleFormats = [
//   'D MMMM YYYY',
//   'D MMMM YYYY HH:mm',
//   'DD-MM-YY',
//   'DD-MM-YYYY',
//   'DD.MM.YYYY',
//   'DD.MM.YYYY HH:mm',
//   'DD/MM/YY',
//   'DD/MM/YYYY',
//   'DD/MM/YYYY HH:mm:ss',
//   'HH:mm:ss',
//   'M/D/YYYY',
//   'D/M/YYYY',
//   'MM-DD-YY',
//   'MM-DD-YYYY',
//   'MM-DD-YYYY HH:mm:ss',
//   'MM/DD/YY',
//   'MM/DD/YYYY',
//   'MM/DD/YYYY HH:mm:ss',
//   'MMM D YYYY',
//   'MMM D YYYY LT',
//   'MMMM Do YYYY',
//   'MMMM Do YYYY LT',
//   'YYYY-DD-MM HH:mm:ss',
//   'YYYY-MM',
//   'YYYY-MM-DD',
//   'YYYY-MM-DD HH:mm',
//   'YYYY-MM-DD HH:mm:ss',
//   'YYYY-MM-DD LT',
//   'YYYY-MM-DD h:mm:ss A',
//   'YYYY-MM-DDTHH:mm:ssZ',
//   'ddd, MMM D YYYY LT',
//   'dddd D MMMM YYYY HH:mm',
//   'dddd, MMMM Do YYYY LT'
// ];

// moment('Chicago Illinois 46702', allPossibleFormats, true).isValid(); // false
// moment('18/01/1944', allPossibleFormats, true).isValid(); // true
// moment('22-10-2020', allPossibleFormats, true).isValid(); // true
// moment('1944-01-18 12:00:00', allPossibleFormats, true).isValid(); // true
// moment('2001-01-01 00:00:00', allPossibleFormats, true).isValid(); // true
// moment('2001-01', allPossibleFormats, true).isValid(); // true

export const stringClean = (str) => {
  if (!_.isString(str)) {
    return "";
  }
  return str.trim();
};

export function addTagsToUrls(inputString) {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Replace each URL with a link tag
  const stringWithLinks = inputString.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });

  return stringWithLinks;
}

export function stringToSlug(str, spaceReplace = "-") {
  return str
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s-]/g, "") // Remove special characters and punctuation
    .trim() // Remove leading and trailing whitespace
    .replace(/\s+/g, spaceReplace) // Replace spaces with hyphens
    .replace(/-+/g, spaceReplace); // Replace multiple hyphens with a single hyphen
}
