import React, { useState, useEffect } from "react";
import { Box, Stack, Heading, VStack, Pressable } from "native-base";
import CardItem from "./CardItem";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { useDeleteChallengeGroupByUserIdMutation } from "../../store/services/challengeGroup";
import { toast } from "react-toastify";
import _ from "lodash";
import { addChallenge, getAddCard } from "../../store/challangeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  COLOR_CALENDAR_MATRIX,
  COLOR_CALENDAR_TOOLTIP,
  COLOR_MATRIX,
  TAG_TYPES,
} from "./constants";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import moment from "moment";
import { sortDatetimeByProperty } from "../../utils/datetime";

const GroupCard = (props) => {
  const dispatch = useDispatch();
  const addCard = useSelector(getAddCard);
  const isOpen = addCard?.isOpen;

  const { group, challenges, playVideo, setPlayVideo } = props;
  const { name, cgid, user } = group;
  const [filterColor, setFilterColor] = useState(null);
  const [filterTag, setFilterTag] = useState(null);
  const [deleteChallengeGroupByUserId] =
    useDeleteChallengeGroupByUserIdMutation();

  useEffect(() => {
    if (filterColor) {
      setFilterTag(null);
    }
  }, [filterColor]);

  const pressCreateCard = () => {
    if (!cgid) {
      toast("Không có groupId");
      return;
    }

    if (isOpen) {
      console.log("no Open");
      return;
    }

    dispatch(addChallenge({ cgid, isOpen: true }));
  };

  const pressDeleteGroup = async () => {
    const resDelAxios = await deleteChallengeGroupByUserId({
      uid: user?.uid,
      cgid: cgid,
    });
    console.log("resDelAxios: ", resDelAxios);
    if (resDelAxios?.data?.success) {
      toast.success("Delete challenge group successful!");
    } else {
      toast.error("Delete challenge group failed!");
    }
  };

  // const [displayCurrentMonth, setDisplayCurrentMonth] = useState(true);
  const [displayMonth, setDisplayMonth] = useState(moment().format("MM-YYYY"));

  let color = _.uniq(challenges.map((ch) => ch.color));
  let uniqMonth = _.uniq(
    challenges
      .map((ch) => {
        return `${moment(ch?.startTime).format("MM-YYYY")}`;
        // const isThisYear = moment(ch?.startTime).get("Y") === moment().get("Y");
        // const isThisMonth =
        //   !displayCurrentMonth ||
        //   (displayCurrentMonth &&
        //     ch?.startTime &&
        //     isThisYear &&
        //     moment(ch?.startTime).get("M") + 1 === currentMonth);
        // if (isThisMonth) {
        //   return `${moment(ch?.startTime).format("DD-MM")}`;
        // }
        // return false;
      })
      .filter((it) => it)
  );
  // console.log("uniqMonth: ", uniqMonth);

  let filterChallenges = challenges.filter((ch) => {
    const isThisYear = moment(ch?.startTime).get("Y") === moment().get("Y");
    const isThisMonth =
      displayMonth === "All"
        ? true
        : displayMonth === moment(ch?.startTime).format("MM-YYYY");
    // !displayCurrentMonth ||
    // (displayCurrentMonth &&
    //   ch?.startTime &&
    //   isThisYear &&
    //   moment(ch?.startTime).get("M") + 1 === currentMonth);

    const isFilterColor =
      !filterColor || (filterColor && ch.color === filterColor);
    const isFilterTag =
      !filterTag || (filterTag && ch?.tags?.includes(filterTag));
    return isFilterColor && isFilterTag && isThisMonth;
  });

  // console.log("filterChallenges: ", filterChallenges);

  // sort startTime
  filterChallenges = sortDatetimeByProperty(filterChallenges, "startTime");

  const allTag = filterChallenges.reduce(
    (total, ch) => [...total, ...(ch.tags || [])],
    []
  );
  // const grp = _.groupBy(challenges, "value");

  let allTagUniq = _.uniq(allTag)
    .map((tv) => TAG_TYPES.find((t) => t?.value === tv))
    .filter((it) => it);

  return (
    <VStack key={cgid} space="2.5" mt="4" px="8" overflow={"hidden"}>
      <Heading size="md">
        {name}
        <Pressable
          onPress={pressCreateCard}
          style={{ justifyContent: "center" }}
        >
          <FaPlusCircle
            name="home"
            size={16}
            color="white"
            style={{ margin: "4px" }}
          />
        </Pressable>
        <Pressable
          onPress={pressDeleteGroup}
          style={{ justifyContent: "center" }}
        >
          <FaMinusCircle
            name="home"
            size={16}
            color="white"
            style={{ margin: "4px" }}
          />
        </Pressable>
      </Heading>
      <Stack direction="row" mb="2" mt="1" space={2}>
        <div className="flex flex-col me-4">
          {["All", ...(uniqMonth || [])]?.map((uMonth) => {
            return (
              <div className="flex items-center me-4">
                <input
                  checked={displayMonth === uMonth}
                  id="green-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={(e) => {
                    // setDisplayCurrentMonth(!displayCurrentMonth);
                    setDisplayMonth(uMonth);
                    // if(uMonth === 'All')
                    // console.log("e: ", );
                  }}
                />
                <label
                  htmlFor="green-checkbox"
                  className="ms-2 text-sm font-medium text-white dark:text-gray-300"
                >
                  {uMonth}
                </label>
              </div>
            );
          })}
        </div>
        {/* <label></label> */}
      </Stack>
      <Stack direction="row" mb="2" mt="1" space={2}>
        {color.map((cl) => {
          const tooltipText = COLOR_CALENDAR_TOOLTIP[cl] || "No description";
          return (
            <Tooltip key={cl} title={tooltipText} placement="top-start">
              <div
                style={{
                  display: "flex",
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  padding: 8,
                  margin: 2,
                  backgroundColor: cl,
                  cursor: "pointer",
                  borderColor: filterColor === cl ? "#fff" : "#000",
                  borderWidth: 2,
                }}
                onClick={() => {
                  if (filterColor === cl) {
                    setFilterColor(null);
                  } else {
                    setFilterColor(cl);
                  }
                }}
              ></div>
            </Tooltip>
          );
        })}
      </Stack>
      <Stack direction="row" mb="2" mt="1" space={2}>
        {allTagUniq.map((tag, keyTag) => {
          return (
            <Chip
              key={keyTag}
              label={tag?.label}
              variant="outlined"
              onClick={() => {
                setFilterTag(filterTag === tag?.value ? null : tag?.value);
              }}
              style={{
                color: filterTag === tag?.value ? "#FFFF00" : "#fff",
                borderColor: filterTag === tag?.value ? "#FFFF00" : "#fff",
              }}
            />
          );
        })}
      </Stack>

      <div className="flex flex-1 justify-center items-center">
        <div className="flex flex-1 flex-row flex-wrap ">
          {filterChallenges.map((item, i) => {
            return (
              <CardItem
                key={i}
                card={item}
                {...{ filterTag, setFilterTag, playVideo, setPlayVideo }}
              />
            );
          })}
        </div>
      </div>
    </VStack>
  );
};

export default GroupCard;
