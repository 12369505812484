import React, { useContext } from "react";
import io from "socket.io-client";
import { CARO_SOCKET_SERVER } from "../configs/api";
// export const socket = io("https://template.codeq.dev/", {
export const socket = io(CARO_SOCKET_SERVER, {
  transports: ["websocket"],
});

const SocketContext = React.createContext();

const SocketProvider = (props) => {
  const { children } = props;
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = (props) => {
  const socket = useContext(SocketContext);
  return socket;
};

export default SocketProvider;
