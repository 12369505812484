import React, { useEffect, useRef, useState } from "react";
import { IoCloseCircle, IoCopy, IoCopyOutline } from "react-icons/io5";
import { UPLOAD_FILE_SERVER } from "../../configs/api";
import axios from "axios";
import { isDevMode } from "../../helpers/dev";
import { AddCircle, ContentCopy, CopyAllRounded } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  FaAudible,
  FaFileAudio,
  FaLink,
  FaPauseCircle,
  FaPlay,
  FaPlayCircle,
  FaRegPauseCircle,
} from "react-icons/fa";
import {
  FILE_TYPE,
  checkIsAudioByName,
  checkIsImage,
  checkIsImageByName,
  checkIsVideoByName,
  getFileTypeBaseOnFileName,
  getMimeTypeByFileName,
} from "../../utils/file";
import { truncateMiddle } from "../../utils/string";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import {
  UPLOAD_TYPE,
  UPLOAD_TYPE_DEFAUT,
} from "../../components/Plan/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { clickCopy } from "../../utils/clipboard";

function DefaultGalleryFB({
  list = [],
  setList,
  openModal,
  setOpenModal,
  accessFile,
  setAccessFile,
}) {
  const { user = {} } = useLoginGoogle();
  console.log("accessFile:111 ", accessFile);

  const deleteApi = async (image, uid) => {
    console.log("clickDelete: ", clickDelete);

    const response = await axios.post(`${UPLOAD_FILE_SERVER}/upload/remove`, {
      uid,
      image,
    });
    console.log("response.data: ", response.data);
    return response?.data;
  };

  const clickDelete = async (image, uid) => {
    const res = await deleteApi(image, uid);
    if (res?.success) {
      const newList = list.filter((file) => file.name !== res?.removeFile);
      setList(newList);
    }
  };

  const renderImage = (file, key) => {
    const filename = file?.name;
    const fileUrl = `${UPLOAD_FILE_SERVER}/uploads/${filename}`;
    return (
      <div
        key={key}
        className="flex items-center justify-center bg-slate-200 relative rounded-xl"
      >
        <LazyLoadImage
          effect="blur"
          className="max-h-72 h-auto max-w-full rounded-lg object-cover"
          src={fileUrl}
          alt=""
        />

        <div
          className="absolute top-1 right-1 rounded-full bg-black cursor-pointer"
          onClick={() => clickDelete(filename, user?.uid)}
        >
          <IoCloseCircle size="24" color="#fff" />
        </div>

        {renderPart({ file, fileUrl })}
      </div>
    );
  };
  const RenderSound = ({ file, setAccessFile, accessFile }) => {
    const filename = file?.name;
    const audioRef = useRef(null);
    const fileUrl = `${UPLOAD_FILE_SERVER}/uploads/${filename}`;
    const mimetype = getMimeTypeByFileName(filename);
    // const [play, setPlay] = useState(false);

    useEffect(() => {
      if (accessFile !== filename) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }, [accessFile, filename]);

    // Function to play the audio
    const playAudio = () => {
      if (accessFile !== filename) {
        setAccessFile(filename);
      } else {
        setAccessFile(null);
      }
    };

    return (
      <div className="flex items-center justify-center bg-slate-200 relative rounded-xl">
        <FaFileAudio size={128} />

        <div
          className="absolute top-1 right-1 rounded-full bg-black cursor-pointer"
          onClick={() => clickDelete(filename, user?.uid)}
        >
          <IoCloseCircle size="24" color="#fff" />
        </div>
        {renderPart({ file, fileUrl })}
        <div
          className="absolute p-1 top-2 left-2 rounded-full bg-black cursor-pointer"
          onClick={() => playAudio()}
          style={{}}
        >
          {accessFile === filename ? (
            <FaPauseCircle fontWeight="bold" size="24" color="#fff" />
          ) : (
            <FaPlayCircle fontWeight="bold" size="24" color="#fff" />
          )}
        </div>
        <div
          className="hidden absolute p-2 rounded-md top-2 left-2x bg-black text-yellow-50"
          style={{ width: 164 }}
        >
          <audio ref={audioRef} style={{ width: 150 }} controls preload="auto">
            <source src={fileUrl} type={mimetype} />
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    );
  };

  const RenderVideo = ({ file, setAccessFile, accessFile }) => {
    const filename = file?.name;
    console.log("RenderVideo: ");
    const videoRef = useRef(null);
    const fileUrl = `${UPLOAD_FILE_SERVER}/uploads/${filename}`;
    console.log("fileUrl:video ", fileUrl);
    const mimetype = getMimeTypeByFileName(filename);

    useEffect(() => {
      if (accessFile !== filename) {
        videoRef.current.pause();
      } else {
        // videoRef.current.play();
      }
    }, [accessFile, filename]);

    // Function to play the audio
    const playVideo = () => {
      if (accessFile !== filename) {
        setAccessFile(filename);
      } else {
        setAccessFile(null);
      }
    };

    return (
      <div
        className="overflow-auto flex items-center justify-center bg-slate-200 relative rounded-xl"
        style={{ width: 192, height: 192 }}
      >
        <div
          className="absolute z-10 top-1 right-1 rounded-full bg-black cursor-pointer"
          onClick={() => clickDelete(filename)}
        >
          <IoCloseCircle size="24" color="#fff" />
        </div>

        {renderPart({ file, fileUrl })}
        <div
          className="absolute z-10 p-1 top-2 left-2 rounded-full bg-black cursor-pointer"
          onClick={() => {
            playVideo();
            setOpenModal(true);
          }}
          style={{}}
        >
          {accessFile === filename ? (
            <FaPauseCircle fontWeight="bold" size="24" color="#fff" />
          ) : (
            <FaPlayCircle fontWeight="bold" size="24" color="#fff" />
          )}
        </div>
        <div
          className="absolute z-0 p-0 rounded-md top-0 left-2x  text-yellow-50 items-center flex justify-center"
          style={{ width: 192 }}
        >
          <video
            ref={videoRef}
            style={{
              maxHeight: 192,
              maxWidth: 192,
            }}
          >
            <source src={fileUrl} type={mimetype} />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {list.map((file, key) => {
        const filename = file?.name || "";
        const filetype = getFileTypeBaseOnFileName(filename);
        // console.log("filetype: ", filetype, file);
        if (filetype === FILE_TYPE.IMAGE) {
          return renderImage(file, key);
        } else if (filetype === FILE_TYPE.AUDIO) {
          return (
            <RenderSound
              key={filename}
              {...{ file, accessFile, setAccessFile }}
            />
          );
        } else if (filetype === FILE_TYPE.VIDEO) {
          return (
            <RenderVideo
              key={filename}
              {...{ file, accessFile, setAccessFile }}
            />
          );
        }
      })}
    </div>
  );
}

export default DefaultGalleryFB;

const renderPart = ({ file, fileUrl }) => {
  const filename = file?.name;
  console.log("filename: ", filename);
  let uploadType = UPLOAD_TYPE_DEFAUT;
  if (checkIsImageByName(filename)) {
    uploadType = file?.uploadType || UPLOAD_TYPE_DEFAUT;
  } else if (checkIsAudioByName(filename)) {
    uploadType = UPLOAD_TYPE.SOUND;
  } else if (checkIsVideoByName(filename)) {
    uploadType = UPLOAD_TYPE.VIDEO;
  }
  console.log("uploadType: ", uploadType);
  return (
    <div className="absolute z-10 flex flex-1 justify-between items-end bottom-0 left-0 w-full">
      <div className="flex flex-col">
        <div className="ml-2 mb-1 rounded-md text-xs font-bold bg-blue-500 bg-opacity-70 text-white py-1 px-2 text-center">
          {uploadType}
        </div>
        <div className="ml-2 mb-1 rounded-md bg-stone-700 bg-opacity-70 text-white py-1 px-2">
          {truncateMiddle(filename, 2, 7, "...")}
        </div>
      </div>
      <div
        className="flex mr-1 mb-1 justify-center items-center w-8 h-8 rounded-full bg-black cursor-pointer"
        onClick={() => clickCopy(fileUrl)}
      >
        <FaLink fontWeight="bold" size="16" color="#fff" />
      </div>
    </div>
  );
};
