import React from 'react';
import './index.css';
import Square from './Square';
import SquareRow from './SquareRow';
import Board from './Board';
import { CARO_CONFIG } from './config';
import { calculateWinner } from './utils';
import NavigateMenu from '../../screens/NavigateMenu';

export default class Game extends React.Component {
  constructor(props) {
    super(props);
    let tmpArr = Array(CARO_CONFIG.defaultHeight);
    for (let i = 0; i < CARO_CONFIG.defaultHeight; i++) {
      tmpArr[i] = Array(CARO_CONFIG.defaultWidth).fill(null);
    }
    this.state = {
      history: [{
        squares: tmpArr,
        location: null,
      }],
      stepNumber: 0,
      xIsNext: true,
      isDescending: true,
    };
  }
  jumpTo(step) {
    this.setState({
      stepNumber: step,
      xIsNext: (step % 2) === 0,
    })
  }
  handleClick(i, j) {
    const history = this.state.history?.slice(0, this.state.stepNumber + 1);
    const current = history[this.state.stepNumber];
    const squares = current.squares?.slice();
    current.squares.map((row, idx) => {
      squares[idx] = current.squares[idx]?.slice();
      return true;
    })
    if (calculateWinner(squares, current.location) || squares[i][j]) {
      return;
    }
    squares[i][j] = this.state.xIsNext ? 'X' : 'O';
    this.setState({
      history: history.concat([{
        squares: squares,
        location: {x: i, y: j}
      }]),
      stepNumber: history.length,
      xIsNext: !this.state.xIsNext,
    });
  }

  sort = () => {
    this.setState({isDescending: !this.state.isDescending});
  }

  render() {
    const history = this.state.history;
    const current = history[this.state.stepNumber];
    const winner = calculateWinner(current.squares, current.location);

    let moves = history.map((step, move) => {
      const desc = move ?
        'Go to move #' + move + ' (' + step.location.x + ',' + step.location.y + ')' :
        'Go to game start';
      return (this.state.stepNumber === move) ? (
        <li key={move}>
          <button className="btn-bold" onClick={() => this.jumpTo(move)}>{desc}</button>
        </li>
      ) : (
        <li key={move}>
        <button onClick={() => this.jumpTo(move)}>{desc}</button>
      </li>
      );
    });
    if (!this.state.isDescending) {
      moves = moves.reverse();
    }

    let status;
    if (winner) {
      status = 'Winner: ' + winner.val;
    } else {
      status = 'Next player: ' + (this.state.xIsNext ? 'X' : 'O');
    }

    let arrow = this.state.isDescending ? '↓' : '↑'
    return (
        <div className="content">
          <div className="game">
            <div className="game-board">
              <Board
                squares={current.squares}
                onClick={(i, j) => this.handleClick(i, j)}
                winner={winner}
                />
            </div>
            {/* <div className="game-info">
              <div>
                <button onClick={this.sort}>Thứ tự bước {arrow}</button>
              </div>
              <div>{status}</div>
              <ol>{moves}</ol>
            </div> */}
          </div>
        </div>
    );
  }
}