import moment from "moment";
import { isDateInCurrentWeek } from "../../../utils/datetime";
import {
  extractRecurrenceToObject,
  getEventDetail,
  getExpireTaskByRecurrence,
  getWeeklyEvent,
} from "./calendar.helpers";
import { cacheFunction } from "../../../helpers/cache.simple";
import { convertObjectToQueryString } from "../../../utils/object";
import { CACHE_TASK, CALENDAR_ID } from "../config";

export function parseRecurrence(recurrence) {
  // Kiểm tra xem recurrence là string hay array
  if (typeof recurrence === "string") {
    return parseRecurrenceString(recurrence);
  } else if (Array.isArray(recurrence)) {
    return parseRecurrenceArray(recurrence);
  } else {
    console.log("recurrence: ", recurrence);
    throw new Error("Recurrence không phải là string hoặc array");
  }
}

function parseRecurrenceString(recurrenceString) {
  // Kiểm tra xem recurrenceString là recurrence hay không
  if (!recurrenceString.startsWith("RRULE:")) {
    console.log("recurrenceString:111 ", recurrenceString);
    throw new Error("RecurrenceString không phải là recurrence");
  }

  // Tạo một đối tượng trống
  const recurrenceObject = {};

  // Duyệt qua từng quy tắc trong recurrence
  const recurrenceRules = recurrenceString.split(";");
  for (const rule of recurrenceRules) {
    // Loại bỏ dấu "RRULE:" khỏi quy tắc
    const ruleWithoutPrefix = rule.substring(6);

    // Tách quy tắc thành các key-value
    const ruleKeyValuePairs = ruleWithoutPrefix.split(";");

    // Thêm từng key-value vào đối tượng
    for (const keyValue of ruleKeyValuePairs) {
      const [key, value] = keyValue.split(":");
      recurrenceObject[key] = value;
    }
  }

  // Trả về đối tượng
  return recurrenceObject;
}

function parseRecurrenceArray(recurrenceArray) {
  // Tạo một mảng các đối tượng recurrence
  const recurrenceObjects = [];

  // Duyệt qua từng quy tắc trong recurrenceArray
  for (const recurrenceString of recurrenceArray) {
    const recurrenceObject = parseRecurrenceString(recurrenceString);
    recurrenceObjects.push(recurrenceObject);
  }

  // Trả về mảng các đối tượng recurrence
  return recurrenceObjects;
}

export const getEventCalendar = async (permissionToken) => {
  try {
    if (!permissionToken) {
      console.log("No permissionToken", permissionToken);
      return;
    }

    const mStartThisWeek = moment().startOf("isoWeek");
    const mEndThisWeek = moment().endOf("isoWeek");
    console.log(
      "mEndThisWeek: ",
      mEndThisWeek.format("MMMM Do YYYY, h:mm:ss a")
    );
    console.log(
      "mStartThisWeek: ",
      mStartThisWeek.format("MMMM Do YYYY, h:mm:ss a")
    );

    // const resData = await getWeeklyEvent(
    //   CALENDAR_ID,
    //   permissionToken,
    //   convertObjectToQueryString({
    //     timeMin: mStartThisWeek.toISOString(),
    //     timeMax: mEndThisWeek.toISOString(),
    //   })
    // );
    // const func = getWeeklyEvent(
    //   CALENDAR_ID,
    //   permissionToken,
    //   convertObjectToQueryString({
    //     timeMin: mStartThisWeek.toISOString(),
    //     timeMax: mEndThisWeek.toISOString(),
    //   })
    // );

    const resData = await cacheFunction(getWeeklyEvent, CACHE_TASK, 0)(
      CALENDAR_ID,
      permissionToken,
      convertObjectToQueryString({
        timeMin: mStartThisWeek.toISOString(),
        timeMax: mEndThisWeek.toISOString(),
      })
    );

    const items = resData?.items;
    console.log("items: ", items);

    if (!items) {
      console.log("Can't get data");
      return;
    }

    // filter event display
    let filterItems = items.filter((it) => it?.status === "confirmed");

    const followEvents = filterItems.filter((item) => item && !item?.created);
    const fullFollowEvents = followEvents.map(
      (event) => event?.recurringEventId
    );
    const allFollowEvents = await Promise.all(
      fullFollowEvents.map((eventId) =>
        getEventDetail(CALENDAR_ID, eventId, permissionToken)
      )
    );

    let arrRes = [];
    try {
      for (let item of filterItems) {
        const isInWeek = isDateInCurrentWeek(item?.start?.dateTime);
        if (isInWeek) {
          arrRes.push(item);
          continue;
        }

        if (!item?.recurrence?.length) {
          continue;
        }
        const objRec = extractRecurrenceToObject(item?.recurrence);

        if (objRec.FREQ !== "WEEKLY") {
          arrRes.push(item);
          continue;
        }

        const resEvents = Array.from(Array(5), (it, index) => index)
          // .filter((it) => item?.colorId === "10")
          .map((it, index) => {
            // WEEKLY Reccurrence
            if (!objRec.BYDAY.includes(index)) {
              return false;
            }

            let startThatDay = mStartThisWeek.clone().add(index, "day");

            const date = startThatDay.date();
            const month = startThatDay.month();
            const year = startThatDay.year();
            const start = moment(item.start.dateTime).clone().set({
              year,
              month,
              date,
            });
            const end = moment(item.end.dateTime).clone().set({
              year,
              month,
              date,
            });
            const newItem = {
              ...item,
              start: {
                dateTime: start.toISOString(),
              },
              end: {
                dateTime: end.toISOString(),
              },
            };
            return newItem;
          })
          .filter((it) => it);
        arrRes.push(...resEvents);

        // const originStart = moment().clone();
        // const day = originStart.date();
        // const month = originStart.month();
        // const year = originStart.year();

        // const start = moment(item.start.dateTime).clone().set({
        //   year,
        //   month,
        //   date: day,
        // });
        // const end = moment(item.end.dateTime).clone().set({
        //   year,
        //   month,
        //   date: day,
        // });
        // // console.log("end: ", start, end, day, month, year);
        // const newItem = {
        //   ...item,
        //   start: {
        //     dateTime: start.toISOString(),
        //   },
        //   end: {
        //     dateTime: end.toISOString(),
        //   },
        // };
        // arrRes.push(newItem);
      }
    } catch (error) {
      console.log("error: 1111", error);
    }
    let mergeFollowItem = arrRes
      .map((item) => {
        if (!item?.created) {
          const fillData = allFollowEvents.find(
            (it) => it.id === item?.recurringEventId
          );
          const originStart = moment(item.originalStartTime.dateTime).clone();

          const day = originStart.date();
          const month = originStart.month();
          const year = originStart.year();
          const start = moment(fillData.start.dateTime)
            .clone()
            .set({ year, month, date: day });
          const end = moment(fillData.end.dateTime)
            .clone()
            .set({ year, month, date: day });
          // console.log("end: ", start, end, day, month, year);
          const resData = {
            ...fillData,
            id: item?.id,
            start: {
              dateTime: start.toISOString(),
            },
            end: {
              dateTime: end.toISOString(),
            },
            status: "confirmed",
          };
          return resData;
        } else {
          return item;
        }
      })
      // remove recurrence, delete in day
      .reduce((total, item, index, array) => {
        const { recurringEventId } = item || {};
        if (recurringEventId) {
          const ind = total.findIndex((it) => it?.id === recurringEventId);
          if (ind !== 0) {
            total.splice(ind, 1, item);
          } else {
            return total;
          }
        } else {
          total.push(item);
        }
        return total;
      }, []);
    // remove expire recurrence
    const filterExpire = mergeFollowItem.filter((item) => {
      const exp = getExpireTaskByRecurrence(item?.recurrence);
      // expire
      if (exp && moment(exp).isBefore(moment().startOf("day"))) {
        return false;
      }
      return true;
    });
    mergeFollowItem = filterExpire;

    // // remove overlap task
    // let filterOverlap = mergeFollowItem.reduce(
    //   (total, item, index, array) => {
    //     let arrOverlap = [];
    //     for (let i in total) {
    //       let it = total[i];
    //       const isOverlap = checkOverlap(
    //         [it?.start?.dateTime, it?.end?.dateTime],
    //         [item?.start?.dateTime, item?.end?.dateTime]
    //       );
    //       const isAfter = moment(item?.updated).isAfter(moment(it?.updated));
    //       if (isOverlap && isAfter) {
    //         arrOverlap.push(i);
    //       }
    //     }
    //     if (arrOverlap?.length) {
    //       // console.log("arrOverlap?.length: ", arrOverlap?.length, arrOverlap);
    //       arrOverlap.forEach((index) => {
    //         total.splice(index, 1);
    //       });
    //     }
    //     total.push(item);
    //     return total;
    //   },
    //   []
    // );
    // console.log("filterOverlap: ", filterOverlap);
    // mergeFollowItem = filterOverlap;

    const inWeek = mergeFollowItem.filter((item) => {
      const isTrue = isDateInCurrentWeek(item?.start?.dateTime);
      return isTrue && item?.status === "confirmed";
    });

    const sort = inWeek.sort(
      (date1, date2) =>
        new Date(date1.end.dateTime) - new Date(date2.end.dateTime)
    );
    // .sortBy((item) => item?.end?.dateTime);
    // console.log("sort: ", sort);

    let fTasks = sort
      .filter((t) => t?.summary && t?.start?.dateTime && t?.end?.dateTime)
      .map((t) => {
        try {
          return {
            ...t,
            title: t.summary,
            // start: {
            //   ...t.start,
            //   dateTime: moment(t.start?.dateTime).toDate(),
            // },
            // end: {
            //   ...t.end,
            //   dateTime: moment(t.end?.dateTime).toDate(),
            // },
            calUpdated: moment(t.updated).toDate(),
            calStart: moment(t.start?.dateTime).toDate(),
            calEnd: moment(t.end?.dateTime).toDate(),
          };
        } catch (error) {
          console.log("error: ", error);
          return false;
        }
      })
      .filter((t) => t);

    return fTasks;
  } catch (error) {
    console.log("error: ", error);
  }
};
