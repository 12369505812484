import moment from "moment";
import momenttz from "moment-timezone";

export function isDateInCurrentWeek(date) {
  // const gtm7 = 'Asia/Ho_Chi_Minh';
  const gtm7 = momenttz.tz.guess();
  const now = momenttz().tz(gtm7);
  const startOfWeek = now.clone().startOf("isoWeek");
  const endOfWeek = now.clone().endOf("isoWeek");

  return momenttz(date).isBetween(startOfWeek, endOfWeek);
}

// export const checkInThisWeek = (checkTime, timezome) => {
//   const mCheckTime = moment(checkTime).utc(timezome);
//   if (!mCheckTime.isValid()) {
//     console.log("CHECK TIME Failed!");
//     return false;
//   }
//   const mStartThisWeek = moment().utc(timezome).startOf("isoWeek");
//   const mEndThisWeek = moment().utc(timezome).endOf("isoWeek");
//   if (mCheckTime.isBetween(mStartThisWeek, mEndThisWeek)) {
//     return true;
//   }
//   return false;
// };

export function checkOverlap(
  [interval1Start, interval1End],
  [interval2Start, interval2End]
) {
  if (moment(interval1Start).valueOf() <= moment(interval2Start).valueOf()) {
    return (
      moment(interval1Start).isSameOrBefore(moment(interval2End)) &&
      moment(interval1End).isSameOrAfter(moment(interval2Start)) &&
      !moment(interval1End).isSame(moment(interval2Start))
    );
  } else {
    return (
      moment(interval2Start).isSameOrBefore(moment(interval1End)) &&
      moment(interval2End).isSameOrAfter(moment(interval1Start)) &&
      !moment(interval2End).isSame(moment(interval1Start))
    );
  }
}

export const sortDatetimeByProperty = (
  collection,
  property,
  isIncrease = false
) => {
  const array = collection.sort((a, b) => {
    var dateA = moment(property ? a[property] : a).toDate();
    var dateB = moment(property ? b[property] : b).toDate();
    return isIncrease ? dateB - dateA : dateA - dateB;
  });
  return array;
};

export function convertMinuteToHourMinute(numberOfMinutes) {
  //create duration object from moment.duration
  var duration = moment.duration(numberOfMinutes, "minutes");

  //calculate hours
  var hh =
    duration.years() * (365 * 24) +
    duration.months() * (30 * 24) +
    duration.days() * 24 +
    duration.hours();

  //get minutes
  var mm = duration.minutes();

  return {
    hours: hh,
    minutes: mm,
  };
}

export const formatHourMinute = (time) => {
  const { hours, minutes } = time || {};
  let hm = "";
  if (hours) {
    hm += `${hours}h`;
  }
  if (minutes) {
    hm += `${minutes}p`;
  }
  // const hm = `${hours}h${minutes}p`;
  return hm;
};

export const formatOnlyDate = (datetime) => {
  return moment(datetime).format("DD-MM-YYYY");
};

export const formatOnlyDateMonth = (datetime) => {
  return moment(datetime).format("DD-MM");
};

export function extractTimeString(timeString) {
  const timeRegex = /(\d+)h\s*(\d+)m/;
  const match = timeString.match(timeRegex);

  if (match) {
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    return { hours, minutes, seconds: "" };
  } else {
    return { hours: "", minutes: "", seconds: "" };
  }
}
// Ví dụ sử dụng
// const timeString = "2h 59m";
// const result = extractTimeString(timeString);
// console.log(result); // { hour: 2, minute: 59 }

export function extractTimeStringXeggex(timeString) {
  // Biểu thức chính quy để tìm giờ, phút và giây
  const timeRegex = /(\d+)\s*HOUR.+\s(\d+)\s*MIN.+?\s*(\d+)\s*SEC.+/i;
  const match = timeString.match(timeRegex);

  // Kiểm tra xem biểu thức chính quy có khớp không
  if (match) {
    // Chuyển đổi giá trị khớp thành số và tạo đối tượng kết quả
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const seconds = parseInt(match[3], 10);
    return { hours, minutes, seconds };
  } else {
    // Nếu chuỗi không hợp lệ, trả về null hoặc một giá trị mặc định
    return { hours: "", minutes: "", seconds: "" };
  }
}

// // Ví dụ sử dụng
// const timeString = "11HOUR(S) 59MIN(S) 30SEC(S)";
// const result = extractTimeStringXeggex(timeString);
// console.log(result); // { hour: 11, minutes: 59, seconds: 30 }

export function extractTimeStringHarvestMoon(timeString) {
  // Biểu thức chính quy để tìm số giờ và phút:giây
  const timeRegex = /Next Harvest\s*:\s*(\d+):(\d+)/;
  const match = timeString.match(timeRegex);

  // Kiểm tra xem biểu thức chính quy có khớp không
  if (match) {
    // Chuyển đổi giá trị khớp thành số và tính toán giờ, phút, giây
    const totalMinutes = parseInt(match[1], 10);
    const seconds = parseInt(match[2], 10);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes, seconds };
  } else {
    // Nếu chuỗi không hợp lệ, trả về null hoặc một giá trị mặc định
    return null;
  }
}

// // Ví dụ sử dụng
// const timeString = "Next Harvest : 110:41";
// const result = extractTimeComponents(timeString);
// console.log(result); // { hours: 1, minutes: 50, seconds: 41 }
