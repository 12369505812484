import { useCallback, useEffect, useState } from "react";
import {
  deleteCacheItem,
  getCacheItem,
  setCacheItem,
} from "../helpers/cache.simple";

export default function useLocalStorageTrigger(
  cacheKey,
  ttl = 0,
  initData = undefined,
  apiCallFunctionMemorize,
  triggerValue
) {
  const [cachedData, setCachedData] = useState(initData);
  const [error, setError] = useState(null);
  // const [triggerChanged, setTriggerChanged] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const cachedDataValue = await getCacheItem(cacheKey);
      if (cachedDataValue) {
        console.log("GET FROM CACHE", cacheKey, triggerValue);
        setCachedData(cachedDataValue);
        setError(null);
      } else {
        try {
          const newData = await apiCallFunctionMemorize();
          // console.log("Request to API: newData: ", newData);
          if (newData) {
            await setCacheItem(cacheKey, newData, ttl);
            setCachedData(newData);
            setError(null);
          }
        } catch (error) {
          console.log(
            "fetchData:Trigger:1 Error fetching data from API:",
            error?.message
          );
          setError({ hasErrorAuth: true });
        }
      }
    };

    fetchData();
  }, [cacheKey, ttl, apiCallFunctionMemorize, triggerValue]);

  const updateCache = async () => {
    try {
      const newData = await apiCallFunctionMemorize();
      if (newData) {
        await setCacheItem(cacheKey, newData, ttl);
        setCachedData(newData);
      }
    } catch (error) {
      console.log(
        "fetchData:Trigger:2 Error fetching data from API:",
        error?.message
      );
      setError({ hasErrorAuth: true });
    }
  };

  const updateCacheMemo = useCallback(updateCache, [
    cacheKey,
    ttl,
    apiCallFunctionMemorize,
  ]);

  const deleteCache = async () => {
    await deleteCacheItem(cacheKey);
  };

  const reloadCache = useCallback(() => {
    updateCacheMemo();
  }, [updateCacheMemo]);

  return [cachedData, updateCacheMemo, deleteCache, reloadCache, error];
}

// const [cachedData, updateCache, deleteCache, reloadCache, error] = useCacheWithTrigger(
//   "yourCacheKey",
//   3600,
//   yourApiCallFunction,
//   yourTriggerValue
// );
