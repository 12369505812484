import React from "react";

function MasonryGridGalleryFB() {
  const listImages = [
    "https://files.codeq.dev/uploads/file-1709958844412-549517842.png",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-2.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg",
    "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg",
  ];
  function groupArrayIntoMiniGroups(array, groupSize) {
    const miniGroups = [];
    for (let i = 0; i < array.length; i += groupSize) {
      miniGroups.push(array.slice(i, i + groupSize));
    }
    return miniGroups;
  }
  const list = groupArrayIntoMiniGroups(listImages, 4);
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {list.map((imgs, key) => {
        return (
          <div key={key} className="grid gap-4">
            {imgs.map((img, key2) => {
              return (
                <div key={key2}>
                  <img
                    className="h-auto max-w-full rounded-lg"
                    src={img}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default MasonryGridGalleryFB;
