import { NativeBaseProvider, extendTheme } from "native-base";
import React from "react";
import SocketProvider from "./Provider/SocketProvider";
import SocketLotoProvider from "./Provider/SocketLotoProvider";
import Typing from "./components/Typing";
import ChatComponent from "./ChatComponent";
import GoogleLoginProvider from "./Provider/GoogleAuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Route, Link } from "react-router-dom";
import { Provider } from "react-redux";
import MainRouter from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./store";
import "./configs/log";
import LoadingProvider from "./Provider/LoadingProvider";
// lazyload style
import "react-lazy-load-image-component/src/effects/blur.css";

function App() {
  const theme = extendTheme({
    colors: {
      // Add new color
      primary1: {
        50: "#E3F2F9",
        100: "#C5E4F3",
        200: "#A2D4EC",
        300: "#7AC1E4",
        400: "#47A9DA",
        500: "#0088CC",
        600: "#007AB8",
        700: "#006BA1",
        800: "#005885",
        900: "#003F5E",
      },
      // Redefinig only one shade, rest of the color will remain same.
      amber: {
        400: "#d97706",
      },
    },
    config: {
      // Changing initialColorMode to 'dark'
      initialColorMode: "dark",
    },
  });

  return (
    <Provider store={store}>
      <NativeBaseProvider theme={theme}>
        <SocketProvider>
          <SocketLotoProvider>
            <GoogleOAuthProvider clientId="713534379317-79h4efshrtsqahh5a63j8r3nkflt29rd.apps.googleusercontent.com">
              {/* <ChatComponent /> */}
              <GoogleLoginProvider>
                <LoadingProvider>
                  <BrowserRouter>
                    <MainRouter />
                  </BrowserRouter>
                </LoadingProvider>
              </GoogleLoginProvider>
            </GoogleOAuthProvider>
          </SocketLotoProvider>
        </SocketProvider>
        {/* <Typing /> */}
        <ToastContainer
          theme="dark"
          position="top-center"
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          autoClose={1000}
          limit={3}
          style={{ zIndex: 999 }}
        />
        {/* <Typing /> */}
      </NativeBaseProvider>
    </Provider>
  );
}

export default App;
