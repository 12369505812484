import axios from "axios";
import { SERVER_URL } from "../../configs/api";

export const getListWord = async (uid) => {
  try {
    const resWord = await axios.post(`${SERVER_URL}/word/getAll`, {
      uid,
    });
    const { words = [] } = resWord?.data || {};
    return words;
  } catch (error) {
    console.log("error: ", error);
    return [];
  }
};
