import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  AspectRatio,
  Stack,
  Center,
  Heading,
  Text,
  HStack,
  Input,
} from "native-base";
import _ from "lodash";

const EDIT_HEADING_CARD = "EDIT_HEADING_CARD";
const HeadingCard = ({
  newHeading,
  setNewHeading,
  editElement,
  setEditElement,
}) => {
  const handleClick = _.debounce((e) => {
    if (e.detail !== 2) return;
    setEditElement(EDIT_HEADING_CARD);
  }, 100);

  const onClickHeadingInput = _.debounce((e) => {
    if (e.detail !== 2) return;
    setEditElement(null);
  }, 200);
  return (
    <>
      {editElement === EDIT_HEADING_CARD ? (
        <Input
          backgroundColor={"red"}
          height={25}
          size="2xl"
          color={"gray.700"}
          placeholder="Heading"
          w="100%"
          maxWidth="300px"
          defaultValue={newHeading}
          onClick={onClickHeadingInput}
          fontWeight="bold"
          isFocused={true}
          onChangeText={(text) => setNewHeading(text)}
          onKeyPress={({ nativeEvent: { key: keyValue } }) => {
            // console.log('e: ', keyValue)
            if (keyValue === "Enter") {
              setEditElement(false);
            }
          }}
          pl={0}
        />
      ) : (
        <Heading size="md" ml="-1" onClick={handleClick}>
          {newHeading}
        </Heading>
      )}
    </>
  );
};

export default HeadingCard;
