import { useWindowSize } from "@uidotdev/usehooks";
import _ from "lodash";
import { useEffect } from "react";
import ReactFlow, { MiniMap, Background, Controls } from "reactflow";
import "reactflow/dist/style.css";
import TreeNode from "./TreeNode";
import { traverseTreeEdges, traverseTreeNodes } from "./flow.function";

function Flow(props) {
  const { root = {} } = props || {};
  const { width, height } = useWindowSize();

  const nodesRender = traverseTreeNodes({ node: root, width });
  // console.log("traverseTreeNodes:nodes", nodesRender);

  const edgesRender = traverseTreeEdges(root);
  // console.log("traverseTreeEdges:edgesRender: ", edgesRender);
  // // edgesRender

  return (
    <div
      style={{
        // padding: 100,
        backgroundColor: "#001524",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <ReactFlow
        // defaultNodes={defaultNodes}
        // defaultEdges={defaultEdges}
        edges={edgesRender}
        nodes={nodesRender}
        fitView
        nodesDraggable={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        panOnDrag={false} // ngăn chặn scroll flow
        preventScrolling={false} // cho phép scroll web
      >
        <Background color="#aaa" gap={16} />
        <Controls />
        {/* <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable /> */}
      </ReactFlow>
    </div>
  );
}

export default Flow;
