import axios from "axios";
import { UPLOAD_FILE_SERVER } from "../configs/api";

// data: save in image file
// uploadType:

export const uploadFile = async (file, data = {}) => {
  const formData = new FormData();
  formData.append("file", file);
  if (Object.keys(data).length > 0) {
    formData.append("data", JSON.stringify(data));
  }
  try {
    const response = await axios.post(
      `${UPLOAD_FILE_SERVER}/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("response: ", response);
    return response?.data;
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

export const getFiles = async (uid) => {
  try {
    console.log("UPLOAD_FILE_SERVER: ", UPLOAD_FILE_SERVER);
    const response = await axios.post(`${UPLOAD_FILE_SERVER}/upload/files`, {
      uid,
    });
    return response?.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
