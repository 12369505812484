import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "flowbite-react";
import ReactPlayer from "react-player";
import {
  SERVER_URL,
  SERVER_URL_DEV,
  SERVER_URL_LOAD_AUDIO,
  UPLOAD_FILE_SERVER,
  UPLOAD_FILE_SERVER_URL_DEV,
  UPLOAD_FILE_SERVER_URL_PRODUCTION,
} from "../../configs/api";
import { useWindowSize } from "@uidotdev/usehooks";
import { resizeImageToFitContainer } from "../../utils/image";
import { IoCloseCircle } from "react-icons/io5";
import axios from "axios";

function PlayerFullScreen({ openModal, setOpenModal, videoUrl, videoName }) {
  const videoRef = useRef(null);
  const [videoInfo, setVideoInfo] = useState({
    width: 0,
    height: 0,
  });
  // const [fetchVideoBlob, setFetchVideoBlob] = useState(null);
  const { width, height } = useWindowSize();

  // const handleReady = async (player) => {
  //   console.log("handleReady: ");
  //   if (player) {
  //     const { videoWidth, videoHeight } = player.getInternalPlayer();
  //     console.log("videoWidth, videoHeight: ", videoWidth, videoHeight);
  //     setVideoInfo(
  //       resizeImageToFitContainer(
  //         { width: videoWidth, height: videoHeight },
  //         { width, height }
  //       )
  //     );
  //     console.log("handleReady: ", handleReady);
  //   }
  // };

  const handleLoad = async () => {
    if (videoRef.current) {
      console.log("handleLoad: ");
      const { videoWidth, videoHeight } = videoRef.current;

      // const blob = await fetchVideo();
      const size = resizeImageToFitContainer(
        { width: videoWidth, height: videoHeight },
        { width, height }
      );
      setVideoInfo(size);
    }
  };

  // const fetchVideo = async (videoName) => {
  //   console.log("fetchVideo:videoName: ", videoName);
  //   try {
  //     const response = await axios.get(
  //       `${SERVER_URL}/stream/video?filename=${videoName}`,
  //       {
  //         responseType: "blob",
  //       }
  //     );
  //     console.log("response: ", response);
  //     const blob = response?.data;
  //     console.log("setVideo---------------------: ", blob);
  //     // return URL.createObjectURL(blob);
  //     setFetchVideoBlob(URL.createObjectURL(blob));
  //   } catch (error) {
  //     console.error("Error fetching video:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchVideo(videoName);
  // }, [videoName]);

  const closeModal = () => {
    setOpenModal(false);
    document.body.style.overflow = "";
  };

  return (
    <Modal
      show={openModal}
      position={"center"}
      popup={false}
      onClose={closeModal}
      dismissible={true}
      size={videoInfo?.width > videoInfo?.height ? "4xl" : "md"}
      style={{
        // padding: 2,
        // backgroundColor: "red",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="flex justify-center items-center">
        <div className="flex justify-center items-center p-2">
          <video
            ref={videoRef}
            src={videoUrl}
            onLoadedData={handleLoad}
            autoPlay={true}
            controls={true}
          />
          {/* <ReactPlayer
            ref={videoRef}
            className=""
            playing={true}
            onReady={handleLoad}
            controls={true}
            width={videoInfo?.width - 64 || 0}
            height={"auto"}
            url={videoUrl}
          /> */}
        </div>
        {/* <Modal.Body className="flex justify-center items-center p-2"> */}
        {/* <ReactPlayer
          className=""
          playing={true}
          onReady={handleReady}
          controls={true}
          width={videoInfo?.width - 64 || 0}
          height={"auto"}
          url={fetchVideoBlob}
        /> */}

        {/* </Modal.Body> */}
        {/* <Modal.Footer className="flex items-center justify-center p-2"> */}
        <div
          className="absolute top-1 right-1 rounded-full bg-black cursor-pointer"
          onClick={() => closeModal()}
        >
          <IoCloseCircle size="24" color="#fff" />
        </div>
        {/* <Button color="gray" onClick={() => setOpenModal(false)}>
          Decline
        </Button> */}
        {/* </Modal.Footer> */}
      </div>
    </Modal>
  );
}

export default PlayerFullScreen;
