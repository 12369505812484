import img01 from "./assets/images/01.jpeg";
import img02 from "./assets/images/02.jpeg";
import img03 from "./assets/images/03.jpeg";
import img04 from "./assets/images/04.jpeg";
import img05 from "./assets/images/05.jpeg";
import img06 from "./assets/images/06.jpeg";
import img07 from "./assets/images/07.jpeg";
import img08 from "./assets/images/08.jpeg";
import img09 from "./assets/images/09.jpeg";
import img10 from "./assets/images/10.jpeg";
import img11 from "./assets/images/11.jpeg";
import img12 from "./assets/images/12.jpeg";
import img13 from "./assets/images/13.jpeg";
import img14 from "./assets/images/14.jpeg";
import img15 from "./assets/images/15.jpeg";
import img16 from "./assets/images/16.jpeg";
import img17 from "./assets/images/17.jpeg";
import img18 from "./assets/images/18.jpeg";
import img19 from "./assets/images/19.jpeg";
import img20 from "./assets/images/20.jpeg";
import img21 from "./assets/images/21.jpeg";
import img22 from "./assets/images/22.jpeg";
import img23 from "./assets/images/23.jpeg";
import img24 from "./assets/images/24.jpeg";
import img25 from "./assets/images/25.jpeg";
import img26 from "./assets/images/26.jpeg";
import img27 from "./assets/images/27.jpeg";
import img28 from "./assets/images/28.jpeg";
import img29 from "./assets/images/29.jpeg";
import img30 from "./assets/images/30.jpeg";
import img31 from "./assets/images/31.jpeg";
import img32 from "./assets/images/32.jpeg";
import img33 from "./assets/images/33.jpeg";
import img34 from "./assets/images/34.jpeg";
import img35 from "./assets/images/35.jpeg";
import accoutics from "./assets/images/36.accoutics.jpeg";
import jumpArena from "./assets/images/37.jump.arena.jpg";
import goKart from "./assets/images/38.go.kart.jpg";
import gocoBay from "./assets/images/39.goco.bay.jpg";
import vanlaivien from "./assets/images/41.vanlaivien.png";

export const STATUS = {
  COMPLETED: "COMPLETED",
  WANT_TO_GO: "WANT_TO_GO",
  QUESTION: "???",
  NOPE: "NOPE",
};

export const data = [
  {
    width: 1600,
    height: 1200,
    status: STATUS.NOPE,
    url: img01,
    title: "Noel ở xóm đạo",
  },
  {
    width: 1600,
    height: 1200,
    status: STATUS.COMPLETED,
    url: img02,
    title: "Xem thú ở Thảo Cầm Viên",
  },
  {
    width: 1600,
    height: 1200,
    status: STATUS.NOPE,
    url: img03,
    title: "Đi đầm sen nước",
  },
  {
    width: 1600,
    height: 1200,
    status: STATUS.WANT_TO_GO,
    url: img04,
    title: "Đi bảo tàng chứng tích chiến tranh",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.WANT_TO_GO,
    url: img05,
    title: "Cà phê ngắm cá Koi",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.COMPLETED,
    url: img06,
    title: "Lượn lờ nhà sách",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.COMPLETED,
    url: img07,
    title: "Thử bắn súng",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.COMPLETED,
    url: img08,
    title: "Bắn cung",
  },
  {
    width: 1600,
    height: 1200,
    status: STATUS.WANT_TO_GO,
    url: img09,
    title: "Waterbus",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.WANT_TO_GO,
    url: img10,
    title: "Hóng gió công viên Landmark",
  },
  {
    width: 1600,
    height: 1200,
    status: STATUS.WANT_TO_GO,
    url: img11,
    title: "Trượt băng",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.WANT_TO_GO,
    url: img12,
    title: "Đi dạo ở Sala",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.COMPLETED,
    url: img13,
    title: "Đi dạo phố đi bộ",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.WANT_TO_GO,
    url: img14,
    title: "Cà phê bệt Nhà Thờ Đức Bà",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.COMPLETED,
    url: img15,
    title: "Ăn đêm ở chợ hồ thị kỷ",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.WANT_TO_GO,
    url: img16,
    title: "Đi hồ con rùa nhưng không ngắm rùa",
  },
  {
    width: 1200,
    height: 1600,
    status: STATUS.WANT_TO_GO,
    url: img17,
    title: "Đi phố lồng đèn rung Thu",
  },
  {
    width: 1600,
    height: 1200,
    status: STATUS.WANT_TO_GO,
    url: img18,
    title: "Ngắm cảnh hóng gió ở Cầu Mống",
  },
  {
    width: 1600,
    height: 1200,
    status: STATUS.COMPLETED,
    url: img19,
    title: "Tô tượng",
  },
  {
    width: 1600,
    height: 946,
    status: STATUS.COMPLETED,
    url: img20,
    title: "Đi bảo tàng nghệ thuật",
  },
  {
    width: 1350,
    height: 1350,
    status: STATUS.COMPLETED,
    url: img21,
    title: "Đi xem triển lãm nghệ thuật",
  },
  {
    width: 1350,
    height: 900,
    status: STATUS.COMPLETED,
    url: img22,
    title: "Đi công viên Bạch Đằng",
  },
  {
    width: 1080,
    height: 1920,
    status: STATUS.COMPLETED,
    url: img23,
    title: "Ăn vặt view Landmark 81",
  },
  {
    width: 1350,
    height: 755,
    status: STATUS.COMPLETED,
    url: img24,
    title: "Cà phê Rooftop",
  },
  {
    width: 1350,
    height: 755,
    status: STATUS.COMPLETED,
    url: img25,
    title: "Xe buýt 2 tầng",
  },
  {
    width: 1000,
    height: 750,
    status: STATUS.COMPLETED,
    url: img27,
    title: "Bưu Điện Thành Phố",
  },
  {
    width: 800,
    height: 800,
    status: STATUS.COMPLETED,
    url: img26,
    title: "Nhà hát thành phố",
  },
  {
    width: 800,
    height: 600,
    status: STATUS.COMPLETED,
    url: img28,
    title: "Đường sách Nguyễn Văn Bình",
  },
  {
    width: 750,
    height: 424,
    status: STATUS.COMPLETED,
    url: img29,
    title: "Escape Room",
  },
  {
    width: 1360,
    height: 1020,
    status: STATUS.COMPLETED,
    url: img30,
    title: "Tham quan dinh độc lập",
  },
  {
    width: 700,
    height: 393,
    status: STATUS.COMPLETED,
    url: img31,
    title: "Cà phê thú cưng",
  },
  {
    width: 700,
    height: 393,
    status: STATUS.WANT_TO_GO,
    url: img32,
    title: "Cầu tình yêu",
  },
  {
    width: 796,
    height: 1060,
    status: STATUS.WANT_TO_GO,
    url: img35,
    title: "Khu sinh thái Vườn Xanh",
  },
  {
    width: 2048,
    height: 1536,
    status: STATUS.WANT_TO_GO,
    url: img33,
    title: "Ngắm diều Cánh đồng diều",
  },
  {
    width: 700,
    height: 524,
    status: STATUS.WANT_TO_GO,
    url: img34,
    title: "Cào nghêu Tân Phú",
  },
  {
    width: 2189,
    height: 1459,
    status: STATUS.COMPLETED,
    url: accoutics,
    title: "Cà phê Accoutics",
  },
  {
    width: 1617,
    height: 1081,
    status: STATUS.WANT_TO_GO,
    url: jumpArena,
    title: "Công Viên Giải Trí Bạt Nhún Jump Arena",
  },
  {
    width: 1334,
    height: 890,
    status: STATUS.WANT_TO_GO,
    url: goKart,
    title: "Đua xe Go Kart",
  },
  {
    width: 2121,
    height: 2560,
    status: STATUS.COMPLETED,
    url: gocoBay,
    title: "Goco Bay",
  },

  {
    width: 789,
    height: 1181,
    status: STATUS.WANT_TO_GO,
    url: require("./assets/images/40.thuy.chau.jpg"),
    title: "Du lịch sinh thái Thủy Châu",
  },
  {
    width: 734,
    height: 414,
    status: STATUS.WANT_TO_GO,
    url: require("./assets/images/41.vanlaivien.png"),
    title: "Vân Lại Viên (Long an)",
  },
  {
    width: 640,
    height: 427,
    status: STATUS.NOPE,
    url: require("./assets/images/42.Bao.Tang.Ao.Dai.png"),
    title: "Bảo Tàng Áo Dài",
  },
  {
    width: 1920,
    height: 2560,
    status: STATUS.WANT_TO_GO,
    url: require("./assets/images/43.Lam.Nen.Thom.jpg"),
    title: "Làm nến thơm",
  },
  // {
  //   width: 1600,
  //   height: 946,
  //   status: STATUS.COMPLETED,
  //   url: img20,
  //   title: "",
  // },
];
