export const COLOR_CALENDAR = {
  0: "rgb(244, 81, 30)",
  1: "rgb(121, 134, 203)",
  2: "rgb(51, 182, 121)",
  3: "rgb(142, 36, 170)",
  4: "rgb(230, 124, 115)",
  5: "rgb(246, 191, 38)",
  6: "rgb(244, 81, 30)",
  7: "rgb(3, 155, 229)",
  8: "rgb(97, 97, 97)",
  9: "rgb(63, 81, 181)",
  10: "rgb(11, 128, 67)",
  11: "rgb(213, 0, 0)",
};

export const COLOR_CALENDAR_HEX = {
  0: "#F4511E",
  1: "#7986CB",
  2: "#33B679",
  3: "#8E24AA",
  4: "#E67C73",
  5: "#F6BF26",
  6: "#F4511E",
  7: "#039BE5",
  8: "#616161",
  9: "#3F51B5",
  10: "#0B8043",
  11: "#D50000",
};

export const CALENDAR_ID = `nguyenquanit17@gmail.com`;
export const GOOGLE_SCOPES = [
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/calendar",
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/calendar.events.readonly",
  "https://www.googleapis.com/auth/calendar.readonly",
  "https://www.googleapis.com/auth/tasks",
  "https://www.googleapis.com/auth/tasks.readonly",
  "https://www.googleapis.com/auth/spreadsheets",
];

export const GOOGLE_CLIENT_ID =
  "546586158159-q74hiuergsk7aigd8uc5l4hehs5ao2ts.apps.googleusercontent.com";
export const GOOGLE_CALENDAR_API_KEY =
  "AIzaSyDfdLDD1pcVo7Z4O3xpr7oqvoHkonAz66w";

// // KEY 2 - Test-001
// export const GOOGLE_CLIENT_ID =
//   "571920926650-m3u3516eac58t6b78g9g90v43jg2cdf3.apps.googleusercontent.com";
// export const GOOGLE_CALENDAR_API_KEY =
//   "AIzaSyB8SyEcBsI9j0WYLPJM_mC4CNLRVRZtPFE";

export const TO_DO_LIST_ID = "eV9lUnFYMDR4aWxTOFl0dg";
export const TRADE_RESEACH_ID = "el8wSV95MVB4ZnJ4NHk2Vw";

// keys
export const GOOGLE_USER_LIST = "GOOGLE_USER_LIST";
export const TASK = "TASK";
export const CALENDAR_EVENTS = "CALENDAR_EVENTS";
export const GOOGLE_TASKS = "GOOGLE_TASKS";
export const GOOGLE_TASKS_TRADE_RESEARCH_LIST =
  "GOOGLE_TASKS_TRADE_RESEARCH_LIST";
export const CACHE_TASK = "CACHE_TASK";
export const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/tasks/v1/rest",
];
export const QTEMPLATE_API_KEY_GOOGLE_SHEET =
  "AIzaSyDsKfkFRbXFzh51ecX47b5g-oV1Z_HpS_Y";
export const NQUAN_USER_ID = "101017621383734224765";
export const JIINGUYEN_USER_ID = "110031803293889361630";
export const TRANSACTION_LIST = "TRANSACTION_LIST";

export const ETHERSCAN_API_KEY = "P8IJND7U6PCEFJMU4Z1DYSBY2MC8X7VWT6";
