import { PAGES } from "./config";

export const filterPages = (allowSheets) => {
  const newAllPage = PAGES.reduce((allowPages, page) => {
    if(allowSheets.includes(page.id)){
      return [...allowPages, page];
    }
    return allowPages;
  }, [])
  return newAllPage.sort(customSortfunc);
}

export function customSortfunc(a,b){ 
  const lca = a?.id?.toLowerCase(), lcb = b?.id?.toLowerCase();
  return lca > lcb ? 1 : lca < lcb ? -1 : 0;
}