import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function WrapDialogMUI(props) {
  const { open, setOpen, children } = props;
  const theme = useTheme();
  //"xs" | "sm" | "md" | "lg" | "xl"
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className="flex items-center justify-center">
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
