import React from 'react';
import ReactDOM from 'react-dom';
import Square from './Square';
import { CARO_CONFIG } from './config';

export default class SquareRow extends React.Component {
  render() {
    let squareRow = this.props.row.map((square, idx) => {
      // rowIdx
      let k = "s" + idx;
      let win = false;
      let winner = this.props.winner;
      let rowIdx = this.props.rowIdx;
      if (winner) {
        win = !!winner.line.find(point =>  point.x === rowIdx && point.y === idx);
      }
      return (
        <Square win={win} value={square} onClick={() => this.props.onClick(this.props.rowIdx, idx)} key={k} />
      )
    })
    return (
      <div className="board-row">
        {squareRow}
      </div>
    )
  }
}