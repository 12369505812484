import { toast } from "react-toastify";

export const clickCopy = (url) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      console.log("Text copied to clipboard:", url);
      toast.info("Copy successful!");
      // Optionally, you can show a success message to the user
    })
    .catch((error) => {
      toast.info("Copy failed!");
      console.error("Unable to copy text to clipboard:", error);
      // Handle the error, such as displaying an error message to the user
    });
};
