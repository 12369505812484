import React from 'react';
import ReactDOM from 'react-dom';

export default function Square(props) {
  const color = props.value ==='X' ? 'blue' : 'red';
  return (props.win) ? (
    <button className="square square-highlight" onClick={props.onClick}>
      {props.value}
    </button>
  ) : (
    <button className="square" style={{ color }} onClick={props.onClick}>
      {props.value}
    </button>  
  )  ;
}