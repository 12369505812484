import { EVENT_SERVER_URL } from "../../configs/api";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllEvent = async (uid) => {
  try {
    const resCreate = await axios.post(
      `${EVENT_SERVER_URL}/crypto/event/getAll`,
      {
        uid,
      }
    );
    return resCreate?.data;
  } catch (e) {
    if (e.message === "Network Error") {
      toast.error("Can not get data");
    }
  }
};
