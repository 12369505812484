import React, { useState } from "react";
import Block from "./elements/Block";
import { ALLOCATION, MONEY_FLOW_TYPE, TAB } from "./constants";
import Title from "./elements/Title";
import { entries, flatten, groupBy, values } from "lodash";
import { Dropdown, Modal } from "flowbite-react";
import CommonButton from "../basic/CommonButton";

function MonthlyInvesment() {
  const [selectItem, setSelectItem] = useState(null);

  const renderTrade = (list, lKey) => {
    if (!list) return null;
    const title = list.name;
    const tableList = list.items;
    const totalAllocation = list.total;
    return (
      <div key={lKey} className="flex-1 bg-white p-4">
        <Title className="py-2">{`${title} THÁNG ${tableList[0].month}`}</Title>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Money Flow Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Month
                </th>
                <th scope="col" className="px-6 py-3">
                  Notes
                </th>

                <th scope="col" className="px-6 py-3">
                  PNL
                </th>
              </tr>
            </thead>
            <tbody>
              {tableList.map((item, iKey) => {
                const { type, moneyFlowType, name, value, notes, month } =
                  item || {};
                return (
                  <tr key={iKey} className="border-b  hover:bg-gray-50">
                    <td className="px-6 py-4">{name}</td>
                    <td className="px-6 py-4">{type}</td>
                    <td className="px-6 py-4 flex flex-1 items-center justify-center ">
                      <div
                        style={{
                          //#4b5563
                          // f9fafb
                          color: value >= 0 ? "#15803D" : "#b91c1c",
                          backgroundColor: value >= 0 ? "#F0FDF4" : "#fef2f2",
                          borderColor: value >= 0 ? "#15803D" : "#b91c1c",
                        }}
                        className="flex px-2 font-medium rounded-lg border-1 p-1 text-center border-green-400 text-xs"
                      >
                        {moneyFlowType}
                      </div>
                    </td>
                    <td className="px-6 py-4">{month}</td>
                    <td className="px-6 py-4">{notes}</td>
                    <td className="px-6 py-4">{value}$</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr className="font-semibold text-gray-900 dark:text-white bg-white">
                <th scope="row" className="px-6 py-3 text-base">
                  Total
                </th>
                <td className="px-6 py-3"></td>
                <td className="px-6 py-3"></td>
                <td className="px-6 py-3"></td>
                <td className="px-6 py-3"></td>
                <td
                  className={`px-6 py-3 ${
                    totalAllocation >= 0 ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {totalAllocation}$
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="flex flex-1 p-2 items-center justify-center">
          <CommonButton title="Close" onClick={() => setSelectItem(null)} />
        </div>
      </div>
    );
  };

  const renderResultTotal = (monthList, key) => {
    const grpList = groupBy(monthList, "usingType");
    const arrList = entries(grpList).map((item) => {
      const [name, array] = item || [];
      const total = array.reduce((tt, item) => tt + item?.value, 0);
      return {
        name,
        total,
        items: array,
      };
    });

    const monthName = monthList[0].month;

    return (
      <div key={key} className="flex-1  bg-white p-4 w-1/2">
        <Title className="py-2">TỔNG KẾT THÁNG {monthName}</Title>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  PNL
                </th>
                <th scope="col" className="px-6 py-3">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {arrList.map((item, key) => {
                return (
                  <tr key={key}>
                    <td className="px-6 py-4">{item?.name}</td>
                    <td
                      className={`px-6 py-4 ${
                        item?.total >= 0 ? "text-green-700" : "text-red-700"
                      }`}
                    >
                      {item?.total}$
                    </td>
                    <td className="px-6 py-4">
                      <div
                        className="underline cursor-pointer text-blue-500"
                        onClick={() => setSelectItem(item)}
                      >
                        Detail
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  const months = values(groupBy(ALLOCATION, "month"));
  // console.log("months: ", months);

  return (
    <div className="flex">
      <div className="flex flex-1 justify-center items-center flex-wrap">
        {months.map(renderResultTotal)}
      </div>
      <Modal
        size="5xl"
        dismissible
        show={!!selectItem}
        onClose={() => setSelectItem(null)}
      >
        {renderTrade(selectItem)}
      </Modal>
    </div>
  );
}

export default MonthlyInvesment;
