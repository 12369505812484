import rocketSvg from "./assets/img/rocket.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavigateMenu from "../../screens/NavigateMenu";
import { AspectRatio, useTheme, Image as NativeBaseImage } from "native-base";
import {
  AddCircle,
  EditRounded,
  PlayCircle,
  Headphones,
} from "@mui/icons-material";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import { useState } from "react";
import UploadWrap from "./UploadWrap";
import { SERVER_URL, SERVER_URL_LOAD_IMAGE } from "../../configs/api";
import useToast from "../../hooks/useToast";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import { useEffect, useRef } from "react";
import AudioPlayer from "./AudioPlayer";
import axios from "axios";
import { getListWord } from "./api";
import EditCircle from "../../icons/EditCircle";
import { Button, Checkbox, IconButton } from "@mui/material";
import {
  FILE_TYPE,
  checkIsImage,
  checkIsVideo,
  getFileTypeBaseOnMime,
  getUrlFromName,
} from "../../utils/file";
import { noWordImage } from "../../assets/images";
import { checkImageExists } from "../../utils/image";
import _ from "lodash";

const WORD_PLAY_STATE = {
  PLAY: 1,
  ADD: 2,
  LIST: 3,
  LISTEN: 4,
  EDIT: 5,
};

const WORD_PLAY_STATE_MAX =
  Object.values(WORD_PLAY_STATE)[Object.values(WORD_PLAY_STATE).length - 2];

const WordPlay = (props) => {
  const { pageTitleName, info, deadlineDate } = props;

  const [wordPlayState, setWordPlayState] = useState(WORD_PLAY_STATE.LIST);
  const [showList, setShowList] = useState(true);
  const { toastInfo } = useToast();
  const { user, setUser } = useLoginGoogle();
  // console.log("user: ", user);
  const [listWord, setListWord] = useState([]);
  const { colors } = useTheme();
  const [editItem, setEditItem] = useState();

  const notify = () =>
    toast.info(info, {
      closeButton: false,
      theme: "colored",
    });

  const clickChangeMode = () => {
    console.log("wordPlayState: ", wordPlayState);
    if (wordPlayState === WORD_PLAY_STATE.EDIT) {
      setWordPlayState(WORD_PLAY_STATE.LIST);
      return;
    }
    setWordPlayState(
      wordPlayState === WORD_PLAY_STATE_MAX
        ? WORD_PLAY_STATE.PLAY
        : wordPlayState + 1
    );
  };

  return (
    <>
      {/* <NavigateMenu /> */}
      <div className="pr-2 mt-2 flex justify-center items-center">
        <div
          className="bg-red-500 bottom-2 right-2 cursor-pointer"
          onClick={clickChangeMode}
        >
          <div className="fixed bg-yellow-800 rounded-full p-2 top-4 right-4">
            {wordPlayState === WORD_PLAY_STATE.PLAY ? (
              <PlayCircle fontSize="large" sx={{ color: "#fff" }} />
            ) : wordPlayState === WORD_PLAY_STATE.ADD ? (
              <AddCircle fontSize="large" sx={{ color: "#fff" }} />
            ) : wordPlayState === WORD_PLAY_STATE.LIST ? (
              <ListRoundedIcon fontSize="large" sx={{ color: "#fff" }} />
            ) : wordPlayState === WORD_PLAY_STATE.LISTEN ? (
              <Headphones fontSize="large" sx={{ color: "#fff" }} />
            ) : wordPlayState === WORD_PLAY_STATE.EDIT ? (
              <EditRounded fontSize="large" sx={{ color: "#fff" }} />
            ) : null}
          </div>
        </div>

        {wordPlayState === WORD_PLAY_STATE.PLAY && <RenderPlay />}
        {wordPlayState === WORD_PLAY_STATE.ADD && <RenderAdd />}
        {wordPlayState === WORD_PLAY_STATE.LIST && (
          <RenderList
            {...{ wordPlayState, setWordPlayState, editItem, setEditItem }}
          />
        )}
        {wordPlayState === WORD_PLAY_STATE.EDIT && (
          <RenderEdit {...{ editItem, setEditItem }} />
        )}
        {wordPlayState === WORD_PLAY_STATE.LISTEN && <RenderListen />}
      </div>
    </>
  );
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const RenderPlay = () => {
  const { user } = useLoginGoogle();
  const [status, setStatus] = useState("PREPARE");
  const [mixList, setMixList] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { toastInfo } = useToast();
  const inputRef = useRef(null);

  const resetLearn = () => {
    setCurrentIndex(-1);
    setMixList(null);
    setStatus("PREPARE");
  };

  const pressStart = async () => {
    console.log("pressStart: ");
    if (status === "PREPARE") {
      if (!user?.uid) {
        return;
      }
      const words = await getListWord(user?.uid);

      const newArr = shuffleArray(words);
      setMixList(newArr);
      setCurrentIndex(0);
      setStatus("PLAY");
    }
  };

  const curWord = mixList ? mixList[currentIndex] : null;

  const enterValue = (e) => {
    console.log("enterValue: ");
    if (e?.key === "Enter") {
      console.log("do validate", e?.target?.value);
      if (curWord) {
        if (curWord?.mean?.indexOf(",") > -1) {
          const arr = curWord?.mean?.split(",");
          const isSame = arr.some(
            (it) =>
              it.trim()?.toLowerCase() ===
              e?.target?.value?.trim()?.toLowerCase()
          );
          if (isSame) {
            if (currentIndex === mixList.length - 1) {
              toastInfo("Bạn đã học toàn bộ danh sách");
              resetLearn();
            } else {
              toastInfo("Đúng rồi");
              setCurrentIndex(currentIndex + 1);
            }
          }
        } else {
          console.log("curWord?.mean: ", curWord?.mean);
          if (
            curWord?.mean?.toLowerCase() ===
            e?.target?.value?.trim()?.toLowerCase()
          ) {
            toastInfo("Đúng rồi");
            setCurrentIndex(
              currentIndex === mixList.length ? 0 : currentIndex + 1
            );

            inputRef.current.value = "";
          } else {
            toastInfo("Sai rồi");
          }
        }
      }
    }
  };

  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{ maxWidth: "100vw", minWidth: 320 }}
    >
      <div
        className="bg-white text-2xl rounded-lg m-4 p-2 flex flex-row justify-center items-center font-bold"
        style={{}}
      >
        HỌC VIẾT TỪ
      </div>

      {status === "PREPARE" && (
        <Button className="m-2 p-2" variant="contained" onClick={pressStart}>
          Bắt đầu
        </Button>
      )}
      {status === "PLAY" && (
        <>
          <AudioPlayer
            src={`https://hsk.academy/static/audio/mp3/words/${curWord?.word}.mp3`}
          />
          {curWord?.image && (
            <div
              className="flex justify-center items-center rounded-xl p-2"
              style={{ maxWidth: 320 }}
            >
              <img
                className="bg-blue-100 rounded-xl"
                width={240}
                height={240}
                src={`${SERVER_URL_LOAD_IMAGE}/${curWord?.image}`}
                alt="logo-512"
              />
            </div>
          )}
          <div className="text-yellow-400 font-bold text-3xl p-2">
            {curWord?.word}
          </div>
          <input
            ref={inputRef}
            className="m-2 p-2 rounded-lg w-64 text-center text-2xl"
            placeholder="Nhập kết quả"
            onKeyDown={enterValue}
          />
        </>
      )}
    </div>
  );
};

const RenderAdd = () => {
  const { user, setUser } = useLoginGoogle();
  const { toastInfo } = useToast();
  const [file, setFile] = useState({});
  const [word, setWord] = useState();
  const [spell, setSpell] = useState();
  const [mean, setMean] = useState();
  const [example, setExample] = useState();

  const reset = () => {
    console.log("reset: ");
    setFile({});
    setWord("");
    setSpell("");
    setMean("");
    setExample("");
  };

  const pressAdd = async () => {
    if (!word) {
      toastInfo("Chưa nhập từ muốn học!");
      return;
    }

    if (!spell) {
      toastInfo("Chưa nhập phiên âm!");
      return;
    }

    if (!mean) {
      toastInfo("Chưa nhập nghĩa của từ!");
      return;
    }

    const fileType = getFileTypeBaseOnMime(file?.type);
    const newWord = {
      uid: user?.uid,
      image: fileType === FILE_TYPE.IMAGE ? file?.name : "",
      video: fileType === FILE_TYPE.VIDEO ? file?.name : "",
      audio: fileType === FILE_TYPE.AUDIO ? file?.name : "",
      word,
      spell,
      mean,
      example,
    };
    console.log("newWord: ", newWord);
    const resAdd = await axios.post(`${SERVER_URL}/word/add`, newWord);
    console.log("resAdd: ", resAdd?.data);
    const { success } = resAdd?.data || {};
    if (success) {
      toastInfo("Thêm từ thành công!");
      reset();
    } else {
      toastInfo("Thêm từ thất bại!");
    }
  };
  return (
    <>
      <div
        className=" flex flex-col justify-center items-center pt-4"
        style={{ minWidth: 320 }}
      >
        <div
          className="bg-white text-2xl rounded-lg m-4 p-2 flex flex-row justify-center items-center font-bold"
          style={{}}
        >
          THÊM TỪ
        </div>
        <div className=" w-64 flex flex-col justify-center items-center">
          <UploadWrap {...{ file, setFile }} />
          <input
            value={word}
            className="m-2 p-2 rounded-lg w-64 text-center text-sm"
            placeholder="Nhập từ muốn học"
            onChange={(e) => {
              setWord(e.target.value);
            }}
          />
          <input
            value={spell}
            className="m-2 p-2 rounded-lg w-64 text-center text-sm"
            placeholder="Phiên âm"
            onChange={(e) => {
              setSpell(e.target.value);
            }}
          />
          <input
            value={mean}
            className="m-2 p-2 rounded-lg w-64 text-center text-sm"
            placeholder="Nghĩa"
            onChange={(e) => {
              setMean(e.target.value);
            }}
          />
          <input
            value={example}
            className="m-2 p-2 rounded-lg w-64 text-center text-sm"
            placeholder="Câu ví dụ"
            onChange={(e) => {
              setExample(e.target.value);
            }}
          />
          <Button variant="contained" onClick={pressAdd}>
            Thêm
          </Button>
        </div>
      </div>
    </>
  );
};

const RenderListWord = (props) => {
  const { item = {}, wordPlayState, setWordPlayState, setEditItem } = props;
  // const [curImage, setCurImage] = useState();
  const {
    image,
    word = "すみません",
    spell = "Sumimasen",
    mean = "Xin lỗi",
    uid,
    user = {},
  } = item;
  const { user: userLogin, setUser } = useLoginGoogle();
  const { toastInfo } = useToast();

  // useEffect(() => {
  //   checkImageExists(image, (exists) => {
  //     if(exists){
  //       setCurImage(image)
  //     } else {
  //       setCurImage(noWordImage);
  //     }
  //   });
  // }, [image])

  const clickEdit = () => {
    console.log("clickEdit: ");

    if (userLogin.uid !== uid) {
      toastInfo("Chỉ người dùng tạo ra từ này mới được thay đổi");
    } else {
      setWordPlayState(WORD_PLAY_STATE.EDIT);
      setEditItem(item);
    }
  };

  return (
    <div
      className="bg-white rounded-lg m-2 p-2 flex flex-row justify-between items-center"
      style={{ minWidth: 320 }}
    >
      <div className="flex flex-row justify-start items-center">
        <div className="w-24 h-24 flex justify-center items-center">
          <img src={getUrlFromName(image) || noWordImage} alt="word" />
        </div>
        <div className="word-item pl-2">
          <div className="i-word font-bold p-0">{word}</div>
          <div className="i-word font-medium p-0">{`(${spell})`}</div>
          <div className="i-word font-medium p-0">{mean}</div>
        </div>
      </div>
      <div>
        <div className="m-1 w-6 h-6 self-start rounded-full">
          <img className="rounded-full" src={`${user?.avatar}`} alt="avatar" />
        </div>
        <div
          className="m-1 w-6 h-6 self-start rounded-full cursor-pointer"
          onClick={clickEdit}
        >
          <EditCircle width={24} height={24} fill={"#FF0000"} />
        </div>
      </div>
    </div>
  );
};

const RenderList = (props) => {
  const { wordPlayState, setWordPlayState, setEditItem, key } = props;
  const { user, setUser } = useLoginGoogle();
  // console.log("user: ", user);
  const [words, setWords] = useState(null);

  useEffect(() => {
    (async () => {
      if (!user?.uid) {
        console.log("No uid");
        return;
      }

      const words = await getListWord(user?.uid);
      // console.log("words:1 ", words);
      setWords(words);
    })();
  }, [user?.id]);

  return (
    <>
      <div key={key} className="List">
        <div
          className="bg-white text-2xl rounded-lg m-2 p-2 flex flex-row justify-center items-center font-bold"
          style={{}}
        >
          DANH SÁCH TỪ
        </div>
        {/* {!words && } */}
        {words?.map((item, key) => (
          <RenderListWord
            {...{ item, wordPlayState, setWordPlayState, setEditItem, key }}
          />
        ))}
      </div>
    </>
  );
};

const RenderEdit = (props) => {
  const { editItem } = props;
  const { user, setUser } = useLoginGoogle();
  const { toastInfo } = useToast();
  const [file, setFile] = useState({ name: editItem?.image });
  const [word, setWord] = useState(editItem?.word);
  const [spell, setSpell] = useState(editItem?.spell);
  const [mean, setMean] = useState(editItem?.mean);
  const [example, setExample] = useState(editItem?.example);
  const [isPrivate, setIsPrivate] = useState(editItem?.isPrivate || false);

  const reset = () => {
    console.log("reset: ");
    setFile({ name: "" });
    setWord("");
    setSpell("");
    setMean("");
    setExample("");
    setIsPrivate(true);
  };

  const clickUpdate = async () => {
    if (!word) {
      toastInfo("Chưa nhập từ muốn học!");
      return;
    }

    if (!spell) {
      toastInfo("Chưa nhập phiên âm!");
      return;
    }

    if (!mean) {
      toastInfo("Chưa nhập nghĩa của từ!");
      return;
    }

    const fileType = getFileTypeBaseOnMime(file?.type);

    const newWord = {
      uid: user?.uid,
      image: fileType === FILE_TYPE.IMAGE ? file?.name : "",
      video: fileType === FILE_TYPE.VIDEO ? file?.name : "",
      audio: fileType === FILE_TYPE.AUDIO ? file?.name : "",
      word,
      spell,
      mean,
      example,
      isPrivate,
    };
    console.log("newWord: ", newWord);
    const resAdd = await axios.post(`${SERVER_URL}/word/add`, newWord);
    console.log("resAdd: ", resAdd?.data);
    const { success } = resAdd?.data || {};
    if (success) {
      toastInfo("Cập nhật từ thành công!");
      reset();
    } else {
      toastInfo("Cập nhật từ thất bại!");
    }
  };

  const changePrivate = (e) => {
    console.log("changePrivate: ");
    setIsPrivate(e.target.checked);
  };

  return (
    <>
      <div
        className=" flex flex-col justify-center items-center pt-4"
        style={{ minWidth: 320 }}
      >
        <div
          className="bg-white text-2xl rounded-lg m-4 p-2 flex flex-row justify-center items-center font-bold"
          style={{}}
        >
          CẬP NHẬT TỪ
        </div>
        <div className=" w-64 flex flex-col justify-center items-center">
          <UploadWrap {...{ file, setFile }} />
          <input
            value={word}
            className="m-2 p-2 rounded-lg w-64 text-center text-sm"
            placeholder="Nhập từ muốn học"
            onChange={(e) => {
              setWord(e.target.value);
            }}
          />
          <input
            value={spell}
            className="m-2 p-2 rounded-lg w-64 text-center text-sm"
            placeholder="Phiên âm"
            onChange={(e) => {
              setSpell(e.target.value);
            }}
          />
          <input
            value={mean}
            className="m-2 p-2 rounded-lg w-64 text-center text-sm"
            placeholder="Nghĩa"
            onChange={(e) => {
              setMean(e.target.value);
            }}
          />
          <input
            value={example}
            className="m-2 p-2 rounded-lg w-64 text-center text-sm"
            placeholder="Câu ví dụ"
            onChange={(e) => {
              setExample(e.target.value);
            }}
          />

          <div className="p-2">
            <Checkbox
              // color="secondary"
              onChange={changePrivate}
              checked={isPrivate}
              // checkedicon={<CheckBox />}
              style={{
                visibility: "visible",
              }}
            />
            <span className="text-white mr-2">Riêng tư</span>
          </div>
          {/* <Checkbox label="Label" defaultChecked />
          <Checkbox label="Label" disabled checked /> */}
          <Button variant="contained" onClick={clickUpdate}>
            CẬP NHẬT
          </Button>
        </div>
      </div>
    </>
  );
};

const RenderListen = (props) => {
  // console.log("RenderListen: ");
  const { user } = useLoginGoogle();
  const [status, setStatus] = useState("PREPARE");
  const MAX_LOOP_TIME = 3;
  const [currentLoop, setCurrentLoop] = useState(0);
  // console.log("currentLoop: ", currentLoop);
  const [mixList, setMixList] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const videoRef = useRef(null);

  const pressStartListen = async () => {
    // console.log("pressStart: ");
    if (status === "PREPARE") {
      if (!user?.uid) {
        return;
      }
      const words = await getListWord(user?.uid);

      const arrWord = shuffleArray(words);
      const filterHaveVideo = arrWord.filter(
        (item) => item?.video || item?.audio
      );
      setMixList(filterHaveVideo);
      setCurrentVideoIndex(0);
      setStatus("PLAY");
    }
  };

  useEffect(() => {
    if (currentLoop === 0) {
      return;
    } else if (currentLoop >= MAX_LOOP_TIME) {
      const nextVideoIndex = (currentVideoIndex + 1) % mixList.length;
      setCurrentVideoIndex(nextVideoIndex);
      setCurrentLoop(0);
    } else {
      videoRef.current?.play();
    }
  }, [currentLoop]);

  useEffect(() => {
    if (!videoRef.current) {
      console.log(`Empty videoRef`, videoRef.current);
      return;
    }
    const handleVideoEnded = () => {
      // console.log("handleVideoEnded: ");
      setCurrentLoop(currentLoop + 1);
      // const nextVideoIndex = (currentVideoIndex + 1) % mixList.length;
      // setCurrentVideoIndex(nextVideoIndex);
    };

    if (videoRef && videoRef.current) {
      videoRef.current.addEventListener("ended", handleVideoEnded);
    }

    return () => {
      if (videoRef && videoRef.current) {
        videoRef.current?.removeEventListener("ended", handleVideoEnded);
      }
    };
  }, [currentLoop, currentVideoIndex, mixList]);

  useEffect(() => {
    if (mixList && _.isArray(mixList)) {
      setCurrentVideoIndex(0);
    }
  }, [mixList]);

  useEffect(() => {
    if (currentVideoIndex !== -1) {
      playVideo(
        mixList[currentVideoIndex].video || mixList[currentVideoIndex].audio
      );
    }
  }, [currentVideoIndex, mixList]);

  const playVideo = (videoSource) => {
    console.log("playVideo: ", videoSource);
    if (videoRef.current) {
      // Set the video source dynamically
      videoRef.current.src = getUrlFromName(videoSource);

      // Load and play the video
      videoRef.current.load();
      videoRef.current.play();
    }
  };

  // const curWord = mixList ? mixList[currentIndex] : null;

  const curWord =
    mixList && currentVideoIndex !== -1 ? mixList[currentVideoIndex] : null;
  console.log("curWord: ", curWord);

  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{ maxWidth: "100vw", minWidth: 320 }}
    >
      <div
        className="bg-white text-2xl rounded-lg m-4 p-2 flex flex-row justify-center items-center font-bold"
        style={{}}
      >
        HỌC NGHE TỪ
      </div>

      {status === "PREPARE" && (
        <Button
          className="m-2 p-2"
          variant="contained"
          onClick={pressStartListen}
        >
          Bắt đầu
        </Button>
      )}
      {status === "PLAY" && (
        <>
          {(curWord?.video || curWord?.audio) && (
            <video ref={videoRef} width="320" height="240" controls autoPlay>
              <source
                src={getUrlFromName(curWord?.video || curWord?.audio)}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
          {/* <AudioPlayer
            src={`https://hsk.academy/static/audio/mp3/words/${curWord?.word}.mp3`}
          />
          {curWord?.image && (
            <div
              className="flex justify-center items-center rounded-xl p-2"
              style={{ maxWidth: 320 }}
            >
              <img
                className="bg-blue-100 rounded-xl"
                width={240}
                height={240}
                src={`${SERVER_URL_LOAD_IMAGE}/${curWord?.image}`}
                alt="logo-512"
              />
            </div>
          )}
          <div className="text-yellow-400 font-bold text-3xl p-2">
            {curWord?.word}
          </div>
          <input
            ref={inputRef}
            className="m-2 p-2 rounded-lg w-64 text-center text-2xl"
            placeholder="Nhập kết quả"
            onKeyDown={enterValue}
          /> */}
        </>
      )}
    </div>
  );
};

export default WordPlay;
