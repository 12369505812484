import { useState, useEffect } from "react";
import GroupCard from "./GroupCard";
import { toast } from "react-toastify";
import { uidValidate } from "../../utils/user.validate";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import { useGetChallengeByUserIdQuery } from "../../store/services/challenge";
import { useCreateChallengeGroupMutation } from "../../store/services/challengeGroup";
import Plus from "./iconComponents/Plus";
import CardEmpty from "./CardEmpty";
import { Box, Button, TextField, Modal } from "@mui/material";
import { object, string, number, date, InferType } from "yup";
import { CARD_EMPTY_DEFAULT } from "./constants";
import { getAddCard } from "../../store/challangeSlice";
import { useSelector } from "react-redux";
import PlayerFullScreen from "../_modules/PlayerFullScreen";
import { CHALLENGE_IMAGE_SERVER_URL } from "../../configs/api";

const validationSchema = object().shape({
  groupName: string().required("Group name is required"),
});
// console.log("validationSchema: ", validationSchema);

const validateForm = async (formData) => {
  try {
    // Validate the form data using the schema
    const validatedData = await validationSchema.validate(formData, {
      abortEarly: false, // Collect all validation errors, not just the first one
    });
    return validatedData;
  } catch (validationErrors) {
    // Handle validation errors here
    throw validationErrors;
  }
};

const List = () => {
  // const dispatch = useDispatch();

  const { user } = useLoginGoogle();
  const { data = {} } = useGetChallengeByUserIdQuery(user?.uid);
  const challengeGroups = data?.data?.challengeGroups;
  const [createChallengeGroup] = useCreateChallengeGroupMutation();
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const [playVideo, setPlayVideo] = useState(null);
  console.log("playVideo:List ", playVideo);
  const { isOpen, card, isEdit } = useSelector(getAddCard) || {};
  // console.log("card: ", card);

  // console.log("card?.cid: ", card?.cid);

  const clickCreateChallengeGroup = async () => {
    // console.log("clickCreateChallengeGroup");
    const uidV = uidValidate(user);
    if (!uidV) {
      toast("Please login to create challenge list");
      return;
    }
    setShowCreate(true);
  };

  const clickCreate = async () => {
    console.log("clickCreate: ");

    validateForm({
      groupName,
    })
      .then((validatedData) => {
        console.log("Validation successful:", validatedData);
        setGroupNameError("");
      })
      .catch((validationErrors) => {
        // console.error("Validation failed:", validationErrors.errors);
        setGroupNameError(validationErrors.errors[0]);
      });

    if (!groupName) {
      toast.error("No group name");
      return;
    }

    const newChallengeGroup = {
      uid: user.uid,
      name: groupName,
      description: "",
      type: "normal",
      isPrivate: true,
    };
    const resCreateAxios = await createChallengeGroup(newChallengeGroup);
    console.log("resCreateAxios: ", resCreateAxios);
    if (resCreateAxios?.data?.success) {
      toast.success("Create challenge group successful!");
      setShowCreate(false);
      setGroupName("");
    } else {
      toast.error("Create challenge group failed!");
    }
  };

  return (
    <>
      {showCreate && (
        <Modal
          open={showCreate}
          onClose={() => setShowCreate(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // backgroundColor: "red",
              }}
            >
              <h2 style={{ paddingBottom: 12 }}> Create group </h2>

              <div
                style={{
                  width: 240,
                }}
              >
                <TextField
                  size=""
                  id="outlined-basic"
                  name="groupName"
                  label="Group Name"
                  variant="outlined"
                  style={{
                    width: 240,
                  }}
                  value={groupName}
                  onChange={(e) => {
                    console.log("text: ", e.target.value);
                    setGroupName(e.target.value);
                  }}
                />
                <label
                  style={{
                    color: "red",
                    fontSize: 12,
                    marginTop: 4,
                    alignSelf: "flex-start",
                  }}
                >
                  {groupNameError}
                </label>
              </div>

              <Button
                style={{
                  marginTop: 12,
                }}
                variant="contained"
                onClick={clickCreate}
              >
                Create
              </Button>
            </div>
          </Box>
        </Modal>
      )}
      {/* {showCreate && (
        <Modal
          isOpen={true}
          // onAfterOpen={afterOpenModal}
          // onRequestClose={closeModal}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
            },
            // backgroundColor: "red",
          }}
          // contentLabel="Example Modal"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // backgroundColor: "red",
            }}
          >
            <TextField
              size=""
              id="outlined-basic"
              label="Group Name"
              variant="outlined"
              style={{
                width: 240,
              }}
              value={groupName}
              onChange={(e) => {
                console.log("text: ", e.target.value);
                setGroupName(e.target.value);
              }}
            />
            <Button
              style={{
                marginTop: 12,
              }}
              variant="contained"
              onClick={clickCreate}
            >
              Create
            </Button>
          </div>
        </Modal>
      )} */}
      <div className="flex">
        <div className="flex items-center me-4">
          <label
            htmlFor="green-checkbox"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Green
          </label>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", marginBottom: 32 }}
        >
          {challengeGroups &&
            challengeGroups?.map((challengeGroup, index) => {
              return (
                <GroupCard
                  key={index}
                  {...challengeGroup}
                  {...{ playVideo, setPlayVideo }}
                />
              );
            })}
        </div>

        <div
          style={{
            position: "fixed",
            right: 0,
            top: 0,
            // backgroundColor: "green",
            padding: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
              // top: 0,
              width: 48,
              height: 48,
              borderRadius: 32,
            }}
            onClick={clickCreateChallengeGroup}
            top={2}
            right={2}
          >
            <Plus width={32} height={32} />
          </div>
        </div>
      </div>
      {isOpen && (
        <CardEmpty
          isEdit={isEdit}
          isOpen={isOpen}
          card={card?.cid ? card : { ...card, ...CARD_EMPTY_DEFAULT }}
        />
      )}
      {playVideo && (
        <PlayerFullScreen
          {...{
            openModal: !!playVideo,
            setOpenModal: setPlayVideo,
            videoUrl: `${CHALLENGE_IMAGE_SERVER_URL}/${playVideo}`,
            videoName: playVideo,
          }}
        />
      )}
    </>
  );
};

export default List;
