export const fix = (price, decimal = 10) =>
  parseFloat(parseFloat(price).toFixed(decimal));

const increase = (curPrice, percent) => fix((curPrice * percent) / 100);
const sellPrice = (curPrice, profitPercent, lossPercent) => ({
  increasePrice: fix(curPrice + increase(curPrice, profitPercent)),
  decreasePrice: fix(curPrice - fix((curPrice * lossPercent) / 100)),
});
const buyCoin = (startPrice, curPrice) => fix((startPrice * 1) / curPrice);
const sellCoin = (coin, increasePrice) => fix((coin * increasePrice) / 1);
const sellCoinWithPercent = (
  curPrice,
  profitPercent,
  startPrice,
  fee,
  lossPercent
) => {
  const usdWithoutFee = fix(startPrice - startPrice * fee);
  const COIN = buyCoin(usdWithoutFee, curPrice);
  const { increasePrice, decreasePrice } = sellPrice(
    curPrice,
    profitPercent,
    lossPercent
  );
  const PROFIT = sellCoin(COIN, increasePrice);
  const LAST_PROFIT = fix(PROFIT - startPrice, 5);
  return {
    LAST_PROFIT,
    increasePrice,
    decreasePrice,
  };
};
const quantityPurchase = (startPrice, curPrice) => fix(startPrice / curPrice);

const PERCENT_FEE = 0.001;
const START_PRICE = 9.968; //USD
const CURRENT_PRICE = 50.0; //USD
const PROFIT_PERCENT = 19.7; // percent
const FEE = fix(START_PRICE * PERCENT_FEE, 10); //USD = ~500vnd
const LOSS_PERCENT = fix(PROFIT_PERCENT / 1.5, 4); //percent
const { LAST_PROFIT, increasePrice, decreasePrice } = sellCoinWithPercent(
  CURRENT_PRICE,
  PROFIT_PERCENT,
  START_PRICE,
  FEE,
  LOSS_PERCENT
);
// console.log(`Số lượng coin mua: ${ fix(quantityPurchase(START_PRICE, CURRENT_PRICE), 0) } COIN`);
// console.log(`Lợi nhuận mong muốn: ${ PROFIT_PERCENT }%`);
// console.log(`Lỗ: ${ LOSS_PERCENT }%`);
// console.log(`-------------------------------------`);
// console.log(`Bắt đầu mua: ${ START_PRICE } USD`);
// console.log(`Phí mua: ${ FEE } USD`);
// console.log(`Giá mua: ${ CURRENT_PRICE } USD`);
// console.log(`Giá gồng lời: ${ increasePrice } USD`);
// console.log(`Giá dừng lỗ: ${ decreasePrice } USD`);
// console.log(`-> Lợi nhuận cuối cùng ${LAST_PROFIT} USD`);
//-> LAST_PROFIT 10.989

export const getPercentBetween2Price = (startPrice, endPrice) => {
  return ((endPrice - startPrice) * 100) / startPrice;
};

export const getPriceDecreaseAtNow = (nowPrice, percent) => {
  const pricePercent = nowPrice - (nowPrice * percent) / 100;
  return pricePercent;
};

export const getPriceIncreaseAtNow = (nowPrice, percent) => {
  const pricePercent = nowPrice + (nowPrice * percent) / 100;
  return pricePercent;
};

const currentNear = 11.938;
const near1 = 9.968;
const near2 = 10.657; //100
const near3 = 11.772;
const near4 = 11.857;

// const FEE = 0.1;

// const nearStage = [
//   { price: 9.968, invest: 50 },
//   { price: 10.657, invest: 100 },
//   { price: 11.772, invest: 50 },
//   { price: 11.857, invest: 50 },
// ]

// console.log(`🚀 ~ getPercentBetween2Price`, getPercentBetween2Price(4.9, 6.1))
// console.log(`🚀 ~ getPercentBetween2Price`, getPercentBetween2Price(1.9, 1.3))

// console.log(100*(currentNear/near2))

export function calculateAverageBuyingPrice(transactions) {
  let totalBTC = 0; // Total amount of Bitcoin purchased
  let totalCost = 0; // Total cost of the purchases

  // Loop through each transaction to sum up the total BTC and cost
  transactions.forEach((transaction) => {
    const quantity = Math.abs(transaction.quantity);
    totalBTC += quantity;
    totalCost += quantity * transaction.price;
  });

  // Calculate the average buying price = Total cost divided by total Bitcoin
  const averagePrice = totalCost / totalBTC;
  return averagePrice;
}
