import { useEffect, useState } from "react";
import { calculateTimeLeft } from "../utils";

import "./styles.scss";

export const Counter = (props) => {
  const { deadlineDate } = props;
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(deadlineDate));
  console.log("timeLeft: ", timeLeft);

  useEffect(() => {
    setTimeout(() => setTimeLeft(calculateTimeLeft(deadlineDate)), 1000);
  }, [timeLeft]);

  return (
    <div className="counter">
      <div className="counter-item">
        <span className="value">{String(timeLeft.days).padStart(2, "0")}</span>
        <span className="label">Ngày</span>
      </div>

      <div className="counter-item">
        <span className="value">{String(timeLeft.hours).padStart(2, "0")}</span>
        <span className="label">Giờ</span>
      </div>

      <div className="counter-item">
        <span className="value">
          {String(timeLeft.minutes).padStart(2, "0")}
        </span>
        <span className="label">Phút</span>
      </div>

      <div className="counter-item">
        <span className="value">
          {String(timeLeft.seconds).padStart(2, "0")}
        </span>
        <span className="label">Giây</span>
      </div>
    </div>
  );
};
