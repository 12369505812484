import React, { useState } from "react";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
  elderRay,
  ema,
  discontinuousTimeScaleProviderBuilder,
  Chart,
  ChartCanvas,
  CurrentCoordinate,
  BarSeries,
  CandlestickSeries,
  ElderRaySeries,
  LineSeries,
  MovingAverageTooltip,
  OHLCTooltip,
  SingleValueTooltip,
  lastVisibleItemBasedZoomAnchor,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
  ZoomButtons,
  withDeviceRatio,
  withSize,
  TooltipContainer,
  StraightLine,
  ToolTipText,
  ToolTipTSpanLabel,
  SingleTooltip,
  HoverTooltip,
  Annotate,
  LabelAnnotation,
  SvgPathAnnotation,
  CandlestickChartWithClickEvent,
  RSISeries,
} from "react-financial-charts";
// import { RSIIndicator } from "react-financial-charts";
import useMa from "./useMa";

const createRsi = (data, periods) => {
  // Khai báo biến lưu trữ Average Gain và Average Loss
  let avgGain = 0;
  let avgLoss = 0;

  // Số chu kỳ giao dịch
  // const periods = 6;

  // Tính toán Average Gain và Average Loss
  for (let i = 1; i < data.length; i++) {
    const priceChange = data[i].close - data[i - 1].close;

    if (priceChange > 0) {
      avgGain += priceChange;
    } else if (priceChange < 0) {
      avgLoss += Math.abs(priceChange);
    }
  }

  avgGain /= periods;
  avgLoss /= periods;

  // console.log("Average Gain:", avgGain);
  // console.log("Average Loss:", avgLoss);

  const RS = avgGain / avgLoss;
  const RSI = 100 - 100 / (1 + RS);

  return RSI;
};

const FinancialChart = ({ initData }) => {
  // console.log("initData: ", initData);
  // const rsiLenght = 6;

  //-----------------------------------------
  // const last6 = initData.slice(-6);
  // const rsi6 = createRsi(last6, 6);
  //-----------------------------------------

  const ScaleProvider =
    discontinuousTimeScaleProviderBuilder().inputDateAccessor(
      (d) => new Date(d.date)
    );
  const height = 700;
  const width = 900;
  const margin = { left: 0, right: 48, top: 0, bottom: 24 };

  const { objMa, render, objTooltip, objRender } = useMa([
    { length: 13, type: "ma", name: "ema13", color: "#FFB200" },
    { length: 34, type: "ma", name: "ema34", color: "#FC0FC0" },
    { length: 50, type: "ma", name: "ema50", color: "#00755E" },
    { length: 100, type: "ma", name: "ema100", color: "#8F00FF" },
    { length: 200, type: "ma", name: "ema200", color: "#7C0A02" },
  ]);

  // const ema13 = ema()
  //   .id(1)
  //   .options({ windowSize: 13, color: "yellow" })
  //   .merge((d, c) => {
  //     d.ema13 = c;
  //   })
  //   .accessor((d) => d.ema13);

  // const ema34 = ema()
  //   .id(2)
  //   .options({ windowSize: 34 })
  //   .merge((d, c) => {
  //     d.ema34 = c;
  //   })
  //   .accessor((d) => d.ema34);

  const [hoverItem, setHoverItem] = useState(null);

  const elder = elderRay();

  const calculatedData = elder(
    objMa.ema200(objMa.ema100(objMa.ema50(objMa.ema34(objMa.ema13(initData)))))
  );
  const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(initData);
  const pricesDisplayFormat = format(".2f");
  const max = xAccessor(data[data.length - 1]);
  const min = xAccessor(data[Math.max(0, data.length - 100)]);
  const xExtents = [min, max + 5];

  const gridHeight = height - margin.top - margin.bottom;

  const elderRayHeight = 100;
  const elderRayOrigin = (_, h) => [0, h - elderRayHeight];
  const barChartHeight = gridHeight / 4;
  const barChartOrigin = (_, h) => [0, h - barChartHeight - elderRayHeight];
  const chartHeight = gridHeight - elderRayHeight;
  const yExtents = (data) => {
    return [data.high, data.low];
  };
  const dateTimeFormat = "%d %b";
  const timeDisplayFormat = timeFormat(dateTimeFormat);

  const barChartExtents = (data) => {
    return data.volume;
  };

  const candleChartExtents = (data) => {
    return [data.high, data.low];
  };

  const yEdgeIndicator = (data) => {
    return data.close;
  };

  const volumeColor = (data) => {
    return data.close > data.open
      ? "rgba(38, 166, 154, 0.3)"
      : "rgba(239, 83, 80, 0.3)";
  };

  const volumeSeries = (data) => {
    return data.volume;
  };

  const openCloseColor = (data) => {
    return data.close > data.open ? "#26a69a" : "#ef5350";
  };

  const [xPosition, setXPosition] = useState(null);
  const handleMouseMove = (
    x,
    y,
    xAccessor,
    displayXAccessor,
    currentItem,
    event
  ) => {
    console.log("currentItem: ", currentItem);
    setHoverItem(currentItem);
    setXPosition(x); // Update x-position of the text note on mouse move
  };

  const textX = new Date("2022-01-01T00:00:00Z").getTime(); // Adjust as needed
  const textY = 50; // Adjust as needed

  // const xAccessor = (d) => d.date;
  const yAccessor = (d) => [d.open, d.high, d.low, d.close];

  const tooltipFormat = format(".2f");
  const timeFormatTooltip = timeFormat("%Y-%m-%d %H:%M:%S");
  const dateFormat = timeFormat("%Y-%m-%d");
  const numberFormat = format(".2f");

  const handleAnnotateClick = ({ type, xScale, plotData, xAccessor }) => {
    console.log("{ type, xScale, plotData, xAccessor }: ", {
      type,
      xScale,
      plotData,
      xAccessor,
    });
    // const xValue = xScale.invert(plotData.x);
    // const yValue = plotData.y;

    // // Create annotation
    // const newAnnotation = {
    //   type,
    //   xValue,
    //   yValue,
    //   stroke: '#FF5733',
    //   strokeWidth: 2,
    //   tooltip: 'Your annotation tooltip here',
    // };

    // // Add annotation to state
    // setAnnotations([...annotations, newAnnotation]);
  };

  const svgAnnotation = {
    onClick: handleAnnotateClick,
    fill: "#2196f3",
    path: () =>
      "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
    pathWidth: 12,
    pathHeight: 22,
    tooltip: "Svg Annotation",
    y: ({ yScale, datum }) => {
      return yScale(datum.high + 3);
    },
    style: { cursor: "pointer" },
  };

  const labelAnnotation = {
    text: "No Monday",
    tooltip: "Go short",
    y: ({ yScale, datum }) => yScale(datum.high),
  };

  const clickChart = (event) => {
    console.log("Candle clicked!", event);
  };

  const onCandleClick = (data) => {
    console.log("data: ", data.target);
  };

  const handleChartClick = (x, y, event) => {
    console.log("x, y, event: ", x, y, event);
    // setClickPosition({ x, y });
  };

  return (
    <ChartCanvas
      height={height}
      ratio={3}
      width={width}
      margin={margin}
      data={data}
      displayXAccessor={displayXAccessor}
      seriesName="Data"
      xScale={xScale}
      xAccessor={xAccessor}
      xExtents={xExtents}
      zoomAnchor={lastVisibleItemBasedZoomAnchor}
      onMouseMove={handleMouseMove} // Handle mouse move event
      onClick={(x, y, event) => onCandleClick(x, y, event)}
      // handleMouseDown={handleChartClick}
    >
      {/* <Chart
        id={2}
        height={barChartHeight}
        origin={barChartOrigin}
        yExtents={barChartExtents}
      >
        <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
      </Chart> */}

      <Chart id={3} height={chartHeight} yExtents={candleChartExtents}>
        <XAxis showGridLines showTickLabel={false} />
        <YAxis showGridLines tickFormat={pricesDisplayFormat} />
        <CandlestickSeries />
        {render(objMa.ema13)}
        {render(objMa.ema34)}
        {render(objMa.ema50)}
        {render(objMa.ema100)}
        {render(objMa.ema200)}

        <MouseCoordinateY
          rectWidth={margin.right}
          displayFormat={pricesDisplayFormat}
        />
        <EdgeIndicator
          itemType="last"
          rectWidth={margin.right}
          fill={openCloseColor}
          lineStroke={openCloseColor}
          displayFormat={pricesDisplayFormat}
          yAccessor={yEdgeIndicator}
        />
        <MovingAverageTooltip
          origin={[8, 24]}
          options={[
            objTooltip.ema13Tooltip,
            objTooltip.ema34Tooltip,
            objTooltip.ema50Tooltip,
            objTooltip.ema100Tooltip,
            objTooltip.ema200Tooltip,
            // {
            //   yAccessor: ema50.accessor(),
            //   type: "EMA",
            //   stroke: ema50.stroke(),
            //   windowSize: ema50.options().windowSize,
            // },
          ]}
        />
        <ZoomButtons />
        {/* <OHLCTooltip origin={[8, 16]} /> */}
        <StraightLine
          lineWidth={2}
          type="horizontal"
          yValue={140}
          stroke="green" // Adjust line color
          strokeDasharray="5,5" // Adjust line style (optional)
          opacity={0.5} // Adjust line opacity (optional)
        />
        <StraightLine
          lineWidth={2}
          type="vertical"
          xValue={6}
          stroke="red" // Adjust line color
          strokeDasharray="5,5" // Adjust line style (optional)
          opacity={0.5} // Adjust line opacity (optional)
        />
        {/* Tooltip */}
        {/* <TooltipContainer>
          <ToolTipText
            origin={[0, 0]}
            yAccessor={yAccessor}
            tooltipContent={({ currentItem }) => (
              <div>
                <p>Date: {xAccessor(currentItem)}</p>
                <p>Open: {currentItem.open}</p>
                <p>High: {currentItem.high}</p>
                <p>Low: {currentItem.low}</p>
                <p>Close: {currentItem.close}</p>
              </div>
            )}
          />
        </TooltipContainer> */}
        {/* Tooltip left */}
        {/* <ToolTipText x={8} y={100} fontSize="20" fontWeight="bold">
          <ToolTipTSpanLabel fill="#000">{"yLabel"}: </ToolTipTSpanLabel>
          <tspan fill={"green"}>{"yValue"}</tspan>
        </ToolTipText> */}
        {/* Hover Tooltip */}
        <OHLCTooltip
          origin={[8, 16]}
          textFill={(d) => (d.close > d.open ? "#26a69a" : "#ef5350")}
        />
        <HoverTooltip
          yAccessor={objMa.ema34.accessor()}
          tooltip={{
            content: ({ currentItem, xAccessor }) => ({
              x: dateFormat(xAccessor(currentItem)),
              y: [
                {
                  label: "open",
                  value: currentItem.open && numberFormat(currentItem.open),
                },
                {
                  label: "high",
                  value: currentItem.high && numberFormat(currentItem.high),
                },
                {
                  label: "low",
                  value: currentItem.low && numberFormat(currentItem.low),
                },
                {
                  label: "close",
                  value: currentItem.close && numberFormat(currentItem.close),
                },
              ],
            }),
          }}
        />
        {/* {labelAnnotation && (
          <Annotate
            with={LabelAnnotation}
            usingProps={labelAnnotation}
            when={(data) => new Date(data?.date)?.getDay() !== 1}
          />
        )} */}
        {svgAnnotation && (
          <Annotate
            // className="cursor-pointer"
            // svgPathAnnotationProps={{
            //   onClick: handleAnnotateClick,
            //   strokeWidth: 2,
            //   stroke: "#FF5733",
            // }}
            with={SvgPathAnnotation}
            usingProps={svgAnnotation}
            when={(data) => new Date(data?.date)?.getDay() === 1}
          />
        )}
        {/* <OHLCTooltip
          origin={[20, 20]}
          ohlcFormat={(d) => {
            console.log("d: ", d);
            return `O: ${d.open}, H: ${d.high}, L: ${d.low}, C: ${d.close}`;
          }}
          displayFormat={(d) => `Date: ${d.date}`}
        /> */}
        {/* <SingleTooltip
          yLabel={(value) => 50}
          yAccessor={(d) => 40}
          tooltipContent={(tooltipData) => {
            console.log("tooltipData: ", tooltipData);
            return {
              x: initData[7].date,
              y: [
                {
                  label: "Close",
                  value: 12,
                },
              ],
            };
            // return {
            //   x: timeFormatTooltip(xAccessor(tooltipData)),
            //   y: [
            //     {
            //       label: "Close",
            //       value: tooltipFormat(yAccessor(tooltipData)),
            //     },
            //   ],
            // };
          }}
        /> */}
      </Chart>

      <Chart
        id={4}
        height={elderRayHeight}
        yExtents={[0, elder.accessor()]}
        origin={elderRayOrigin}
        padding={{ top: 8, bottom: 8 }}
      >
        <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" />
        <YAxis ticks={4} tickFormat={pricesDisplayFormat} />

        <MouseCoordinateX displayFormat={timeDisplayFormat} />
        <MouseCoordinateY
          rectWidth={margin.right}
          displayFormat={pricesDisplayFormat}
        />

        <ElderRaySeries yAccessor={elder.accessor()} />

        <SingleValueTooltip
          yAccessor={elder.accessor()}
          yLabel="Elder Ray"
          yDisplayFormat={(d) =>
            `${pricesDisplayFormat(d.bullPower)}, ${pricesDisplayFormat(
              d.bearPower
            )}`
          }
          origin={[8, 16]}
        />
      </Chart>

      <Chart
        id={5}
        yExtents={(data) => {
          return [0, 100];
        }}
      >
        <RSISeries yAccessor={(d) => d.rsi} />
      </Chart>

      <CrossHairCursor />
    </ChartCanvas>
  );
};

export default FinancialChart;
