import React from "react";
import { useLoginGoogle } from "../Provider/GoogleAuthContext";
import Avatar from "react-avatar";
import { FaHome, FaRegBell, FaRegCommentAlt, FaPowerOff } from "react-icons/fa";
import { GiChessPawn, GiChessBishop } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import useMedia from "../hooks/useMedia";

function NavigateMenu() {
  const { user, setUser } = useLoginGoogle();
  const navigate = useNavigate();
  const styles = createStyle();
  const showText = useMedia(
    ["(min-width: 600px)", "(min-width: 0px)"],
    [true, false],
    true
  );
  const showIcon = useMedia(
    [
      "(min-width: 600px)",
      "(min-width: 460px)",
      "(min-width: 400px)",
      "(min-width: 0px)",
    ],
    [true, true, true, false],
    true
  );
  if (!user) return null;

  const logout = () => {
    setUser(null);
  };

  const clickPlaygame = () => {
    console.log("clickPlaygame: ");
    navigate("/play-caro", { replace: true });
  };

  const clickHome = () => {
    console.log("clickHome: ");
    navigate("/", { replace: true });
  };

  const clickFarmGame = () => {
    window.location.href = "https://dairy-farms.codeq.dev/";
  };

  return (
    <div
      style={{
        width: "100",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderWidth: "2px",
        borderStyle: "solid",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        borderColor: "#C8E5CA",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          maxWidth: "128px",
          margin: "10px",
        }}
      >
        <Avatar
          style={{ marginRight: "8px" }}
          name={user.name}
          size="34"
          round="50%"
          src={user.avatar}
        />
        <div style={{ fontWeight: "bold", color: "light.50" }}>{user.name}</div>
      </div>
      {(showText || showIcon) && (
        <div style={{ justifyItems: "center", display: "flex" }}>
          <button
            onClick={clickHome}
            style={{
              borderColor: "#D971F4",
              borderWidth: "2px",
              borderRadius: "5px",
              paddingHorizontal: "6px",
              paddingVertical: "4px",
              margin: "2px",
            }}
          >
            <div style={{ margin: "4px" }}>
              {showIcon && (
                <FaHome
                  name="home"
                  size={16}
                  color={"#9A10BF"}
                  style={{ margin: "4px" }}
                />
              )}
              {showText && (
                <span
                  style={{
                    fontSize: "15px",
                    color: "#9A10BF",
                    fontWeight: "500",
                    margin: "4px",
                  }}
                >
                  Home
                </span>
              )}
            </div>
          </button>

          <button
            onClick={clickPlaygame}
            style={{
              borderColor: "#8DE591",
              borderWidth: "2px",
              borderRadius: "5px",
              paddingHorizontal: "6px",
              paddingVertical: "4px",
              margin: "2px",
            }}
          >
            <div style={{ margin: "4px" }}>
              {showIcon && (
                <GiChessPawn
                  name="Play Caro"
                  size={16}
                  color={"#155047"}
                  style={{ margin: "4px" }}
                />
              )}
              {showText && <span style={styles.menuTitle}>Play Caro</span>}
            </div>
          </button>

          <button
            onClick={clickFarmGame}
            style={{
              borderColor: "#8DE591",
              borderWidth: "2px",
              borderRadius: "5px",
              paddingHorizontal: "6px",
              paddingVertical: "4px",
              margin: "2px",
            }}
          >
            <div style={{ margin: "4px" }}>
              {showIcon && (
                <GiChessBishop
                  name="Dairy Farms"
                  size={16}
                  color={"#155047"}
                  style={{ margin: "4px" }}
                />
              )}
              {showText && <span style={styles.menuTitle}>Dairy Farms</span>}
            </div>
          </button>
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            backgroundColor: "#E2F8E3",
            padding: "6px",
            borderRadius: "50%",
            margin: "5px",
          }}
        >
          <FaRegCommentAlt
            name="home"
            size={16}
            color={"#155047"}
            style={{ margin: "4px" }}
          />
        </div>
        <div
          style={{
            backgroundColor: "#E2F8E3",
            padding: "6px",
            borderRadius: "50%",
            margin: "5px",
          }}
        >
          <FaRegBell
            name="home"
            size={16}
            color={"#155047"}
            style={{ margin: "4px" }}
          />
        </div>
        <div
          onClick={logout}
          style={{
            backgroundColor: "#E2F8E3",
            padding: "6px",
            borderRadius: "50%",
            margin: "5px",
          }}
        >
          <FaPowerOff
            name="home"
            size={16}
            color={"#155047"}
            style={{ margin: "4px" }}
          />
        </div>
        {/* <FaRegBell name="home" size={20} color={'#155047'} style={{ margin: '4px' }} /> */}
      </div>
    </div>
  );
}

export default NavigateMenu;

const createStyle = (theme) => ({
  menuTitle: {
    fontSize: "15px",
    color: "#155047",
    fontWeight: "500",
    margin: "4px",
  },
});
