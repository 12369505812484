export const YUGI_THE_DESTINY = [
  "Ancient Elf",
  "Ansatsu",
  "Anti Raigeki",
  "Aqua Madoor",
  "Armaill",
  "Armed Ninja",
  "Armored Starfish",
  "Baron of the Fiend Sword",
  "Basic Insect",
  "Beast Fangs",
  "Beaver Warrior",
  "Blue-Eyes White Dragon",
  "Book of Secret Arts",
  "Card Destruction",
  "Castle Walls",
  "Celtic Guardian",
  "Change of Heart",
  "Charubin the Fire Knight",
  "Claw Reacher",
  "Curse of Dragon",
  "Dark Energy",
  "Dark Gray",
  "Dark Hole",
  "Dark King of the Abyss",
  "Dark Magician",
  "Darkfire Dragon",
  "Darkworld Thorns",
  "De-Spell",
  "Dian Keto the Cure Master",
  "Dissolverock",
  "Doma The Angel of Silence",
  "Dragon Capture Jar",
  "Dragon Treasure",
  "Dragon Zombie",
  "Dragoness the Wicked Knight",
  "Drooling Lizard",
  "Electro-Whip",
  "Enchanting Mermaid",
  "Exodia the Forbidden One",
  "Feral Imp",
  "Fiend Reflection #2",
  "Final Flame",
  "Firegrass",
  "Fireyarou",
  "Fissure",
  "Flame Ghost",
  "Flame Manipulator",
  "Flower Wolf",
  "Follow Wind",
  "Forest",
  "Frenzied Panda",
  "Fusionist",
  "Gaia the Dragon Champion",
  "Gaia The Fierce Knight",
  "Giant Soldier of Stone",
  "Goblin's Secret Remedy",
  "Graceful Charity",
  "Great White",
  "Green Phantom King",
  "Hane-Hane",
  "Hard Armor",
  "Hinotama",
  "Hinotama Soul",
  "Hitotsu-Me Giant",
  "Kagemusha of the Blue Flame",
  "Karbonala Warrior",
  "King Fog",
  "Kumootoko",
  "Kurama",
  "Kuriboh",
  "Larvas",
  "Laser Cannon Armor",
  "Left Arm of the Forbidden One",
  "Left Leg of the Forbidden One",
  "Legendary Sword",
  "Lesser Dragon",
  "Machine Conversion Factory",
  "Magical Ghost",
  "Mammoth Graveyard",
  "Man Eater",
  "Man-Eater Bug",
  "Man-Eating Treasure Chest",
  "Masaki the Legendary Swordsman",
  "Meda Bat",
  "Metal Dragon",
  "Mirror Force",
  "Misairuzame",
  "Monster Egg",
  "Monster Reborn",
  "Mountain",
  "M-Warrior #1",
  "M-Warrior #2",
  "Mystic Clown",
  "Mystical Elf",
  "Mystical Moon",
  "Mystical Sheep #2",
  "Nemuriko",
  "Neo the Magic Swordsman",
  "One-Eyed Shield Dragon",
  "Petit Angel",

  "Petit Dragon",
  "Polymerization",
  "Pot of Greed",
  "Power of Kaishin",
  "Raigeki",
  "Raise Body Heat",
  "Ray & Temperature",
  "Reaper of the Cards",
  "Red Medicine",
  "Reinforcements",
  "Remove Trap",
  "Reverse Trap",
  "Right Arm of the Forbidden One",
  "Right Leg of the Forbidden One",
  "Root Water",
  "Sand Stone",
  "Silver Bow and Arrow",
  "Silver Fang",
  "Skull Red Bird",
  "Skull Servant",
  "Sogen",
  "Sorcerer of the Doomed",
  "Sparks",
  "Spellbinding Circle",
  "Spike Seadra",
  "Spirit of the Harp",
  "Steel Ogre Grotto #1",
  "Stop Defense",
  "Succubus Knight",
  "Summoned Skull",

  "Sword of Dark Destruction",
  "Swords of Revealing Light",
  "Terra the Terrible",
  "The 13th Grave",
  "The Furious Sea King",
  "The Stern Mystic",
  "Trap Hole",
  "Trap Master",
  "Trial of Nightmare",
  "Tri-Horned Dragon",
  "Tripwire Beast",
  "Turtle Tiger",
  "Two-Mouth Darkruler",
  "Two-Pronged Attack",
  "Tyhone",
  "Umi",
  "Uraby",
  "Vile Germs",
  "Violet Crystal",
  "Waboku",
  "Wall of Illusion",
  "Wasteland",
  "Winged Dragon, Guardian of the Fortress #1",
  "Witty Phantom",
  "Yami",
];
