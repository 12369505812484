import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  AspectRatio,
  Stack,
  Center,
  Heading,
  Text,
  HStack,
  Input,
  Flex,
  Row,
  Column,
  Pressable,
  Circle,
} from "native-base";

const ColorSelection = ({ setCardColor, setOpenColorPicker, colorBoard }) => {
  return (
    <Box
      position="absolute"
      top="36px"
      right="8px"
      w={60}
      h={166}
      bg={"info.50"}
      borderRadius={10}
    >
      {colorBoard.map((col, iCol) => {
        return (
          <Column
            key={iCol}
            align="center"
            justify="space-around"
            direction="row"
            w="40px"
          >
            {col.map((color, iRow) => {
              return (
                <Pressable
                  w="14px"
                  h="14px"
                  bg={color}
                  mt="4px"
                  ml="4px"
                  borderRadius="50%"
                  // _hover={{
                  //   w: "13px",
                  //   h: "13px",
                  //   borderWidth: "1px",
                  //   borderColor: "#000",
                  // }}
                  // borderColor="#000"
                  onPress={() => {
                    setCardColor(color);
                    setOpenColorPicker(null);
                  }}
                />
              );
            })}
          </Column>
        );
      })}
    </Box>
  );
};

export default ColorSelection;
