import React, { useState, useRef } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

function SoundIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      width={props.width}
      height={props.height}
      fill={props.color}
    >
      <path d="M8 9.86v4.28c0 .97-.86 1.75-1.92 1.75C5.86 16.89 5 16.11 5 15.14v-4.28c0-.97.86-1.75 1.92-1.75 1.06 0 1.92.78 1.92 1.75zm8.18-1.69L15 9.57v4.87l1.18 1.14c.38.37.38.97 0 1.34-.19.19-.44.29-.69.29s-.5-.1-.7-.29L13 14.44V9.57l1.18-1.14c.38-.37.38-.97 0-1.34-.19-.19-.44-.29-.69-.29s-.5.1-.7.29L10 9.57v4.87l-1.18 1.14c-.38.37-.38.97 0 1.34.19.19.44.29.69.29s.5-.1.7-.29L11 14.44v-4.87l-1.18-1.14c-.38-.37-.38-.97 0-1.34.19-.19.44-.29.69-.29s.5.1.7.29l1.18 1.14v4.87l-1.18 1.14c-.38.37-.38.97 0 1.34.19.19.44.29.69.29s.5-.1.7-.29L15 14.44v-4.87z" />
    </svg>
  );
}

function AudioPlayer(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      resetAudio();
      audioRef.current.play();
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    audioRef.current.pause();
  };

  const resetAudio = () => {
    audioRef.current.currentTime = 0;
  };

  return (
    <div>
      <audio ref={audioRef} src={props.src} onEnded={resetAudio} autoPlay />
      <div className="" onClick={handlePlay}>
        <VolumeUpIcon
          style={{
            color: "#fff", // change to the color you want
            fontSize: 40, // change to the size you want
          }}
        />
      </div>
      {/* {isPlaying ? (
        <button onClick={handlePause}>Pause</button>
      ) : (
        <button onClick={handlePlay}>Play</button>
      )} */}
    </div>
  );
}

export default AudioPlayer;
