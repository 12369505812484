import "bootstrap/dist/css/bootstrap.min.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  BasicStorage,
  ChatMessage,
  ChatProvider,
  Conversation,
  ConversationId,
  ConversationRole,
  IStorage,
  MessageContentType,
  Participant,
  Presence,
  TypingUsersList,
  UpdateState,
  User,
  UserStatus,
} from "@chatscope/use-chat";
import { ExampleChatService } from "@chatscope/use-chat/dist/examples";
import { Chat } from "./components/Chat";
import { nanoid } from "nanoid";
import { AutoDraft } from "@chatscope/use-chat/dist/enums/AutoDraft";
import {
  publicAvatar,
  oldManAvatar,
  womenAvatar,
  arabiAvatar,
  akaneAvatar,
} from "./assets/images";
import {
  createUserModel,
  createUserChat,
  createUserStorage,
  createUser,
} from "./components/Chat/chatscope.utils";

// Create serviceFactory
const serviceFactory = (storage, updateState) => {
  return new ExampleChatService(storage, updateState);
};

const createGroupChat = (conversationGroupId, userChat, members) => {
  const c = userChat;
  const users = members;

  for (const user of users) {
    const { model: u, chat: uChat } = user;
    if (c.name === u.name) return;
    c.storage.addUser(createUser(u.name, u.avatar));

    // Tìm tat ca conversation của user trong phòng chat
    const myConversation = c.storage.getState().conversations.find((cv) => {
      const fCvs = cv.participants.find((p) => p.id === u.name);
      return typeof fCvs !== "undefined";
    });
    if (myConversation) {
      return;
    }

    // Không có conversation của user trong phòng chat thì thêm vào
    // Tạo conversation mới
    const cvs = new Conversation({
      id: conversationGroupId,
      participants: [
        ...users.map((user) => {
          return new Participant({
            id: user.model.name,
            role: new ConversationRole([]),
          });
        }),
      ],
      unreadCounter: 0,
      typingUsers: new TypingUsersList({ items: [] }),
      draft: "",
    });
    c.storage.addConversation(cvs);
  }
};

const messageIdGenerator = () => nanoid();
const groupIdGenerator = () => nanoid();

const username = "Akane";
const akaneStorage = createUserStorage(groupIdGenerator, messageIdGenerator);
const akaneChat = createUserChat(username, akaneStorage);
const akane = createUser(username, akaneAvatar);
// create group chat
const conversationGroupId = nanoid();
const me = akaneChat;
const members = [
  {
    model: createUserModel("Women", womenAvatar),
    chat: createUserChat(
      "Women",
      createUserStorage(groupIdGenerator, messageIdGenerator)
    ),
  },
  {
    model: createUserModel("Oldman", oldManAvatar),
    chat: createUserChat(
      "Oldman",
      createUserStorage(groupIdGenerator, messageIdGenerator)
    ),
  },
  {
    model: createUserModel("Arabi"),
    chat: createUserChat(
      "Arabi",
      createUserStorage(groupIdGenerator, messageIdGenerator)
    ),
  },
];
createGroupChat(conversationGroupId, me, members);

function App() {
  return (
    <div
      className="d-flex flex-column overflow-hidden"
      style={{ height: "480px", width: "720px", padding: "5px" }}
    >
      <ChatProvider
        serviceFactory={serviceFactory}
        storage={akaneStorage}
        config={{
          typingThrottleTime: 250,
          typingDebounceTime: 900,
          debounceTyping: true,
          autoDraft: AutoDraft.Save | AutoDraft.Restore,
        }}
      >
        <Chat user={akane} />
      </ChatProvider>
    </div>
  );
}

export default App;
