// validate color
//https://stackoverflow.com/questions/32673760/how-can-i-know-if-a-given-string-is-hex-rgb-rgba-or-hsl-color-using-javascript

export const validateRBGColor = (text) => {
  const pt =
    /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/gm;
  const result = text.match(pt);
  return !!result[0];
};

export const changeAlphaColor = (rgbaColor, alpha) => {
  const isRgba = validateRBGColor(rgbaColor);
  if (!isRgba) {
    return "";
  }

  const pattern = /rgba\((\d+),(\d+),(\d+),(\d+)\)/i;
  const result = rgbaColor.match(pattern);
  const [origin, r, g, b, a] = result;
  const newColor = `rgba(${r},${g},${b},${alpha})`;
  return newColor;
};

export const hexToRgbA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
};

// let rgbaColor = "rgba(249,187,101,1)";
// const rs = changeAlphaColor(rgbaColor, 0.7);
// console.log(rs);

function randomColor() {
  // Generate random values for red, green, and blue components
  const r = Math.floor(Math.random() * 256); // 0 to 255
  const g = Math.floor(Math.random() * 256); // 0 to 255
  const b = Math.floor(Math.random() * 256); // 0 to 255

  // Convert RGB values to hexadecimal format
  const hexR = r.toString(16).padStart(2, "0"); // Ensure at least 2 digits
  const hexG = g.toString(16).padStart(2, "0");
  const hexB = b.toString(16).padStart(2, "0");

  // Return the random color in hexadecimal format (#RRGGBB)
  return `#${hexR}${hexG}${hexB}`;
}

export function getRandomLightHexColor() {
  // Generate random values for each RGB component
  const r = Math.floor(Math.random() * 128) + 128; // Red component between 128 and 255
  const g = Math.floor(Math.random() * 128) + 128; // Green component between 128 and 255
  const b = Math.floor(Math.random() * 128) + 128; // Blue component between 128 and 255

  // Convert RGB to hex
  const hex =
    "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  return hex;
}

export function getRandomDarkHexColor() {
  // Generate random values for each RGB component
  const r = Math.floor(Math.random() * 128); // Red component between 0 and 127
  const g = Math.floor(Math.random() * 128); // Green component between 0 and 127
  const b = Math.floor(Math.random() * 128); // Blue component between 0 and 127

  // Convert RGB to hex
  const hex =
    "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  return hex;
}
