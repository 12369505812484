export const TIME_CALL_NUMBER = 500;
export const MAX_SELECTED_PAGE = 16;
export const MAX_GROUP_SELECTED_PAGE = 7;
export const MAX_ROW_PAGE = 5;

export const GAME_STATE = {
  PREPARE: "PREPARE", // not join room and can select another page
  WAIT: "WAIT", // already selected page and accept join room
  PLAYING: "PLAYING", // call number utils Bingo
  END: "END", // display result game;
}

export const USER_STATE = {
  PREPARE: "PREPARE",
  WAIT: "WAIT",
  PLAYING: "PLAYING",
  END: "END",
}

export const JOIN_STATUS = {
  "NOT_JOIN": "NOT_JOIN",
  "JOINING": "JOINING",
  "JOINED": "JOINED",
}

export const PAGES = [
  {
    id: "yellow#1",
    color: "#DDC040",
    number: 1,
    matrix: [
      [0, 19, 0, 35, 49, 0, 0, 71, 85],
      [8, 14, 0, 0, 47, 54, 0, 74, 0],
      [6, 0,  25, 36, 0, 0, 62, 0, 84],
      [0, 15, 22, 0, 0, 58, 0, 70, 89],
      [0, 12, 0, 31, 43, 0, 68, 0, 90],
      [1, 0,  0, 0, 42, 0, 65, 72, 87],
      [5, 0,  21, 38, 0, 52, 0, 76, 0],
      [0, 13, 0, 33, 0, 57, 67, 0, 82],
      [0, 11, 26, 0, 44, 0, 69, 79, 0],
    ],
    textColor: "#000",
  },
  {
    id: "yellow#2",
    color: "#DDC040",
    number: 2,
    matrix: [
      [7, 16, 0, 32, 0, 0, 66, 73, 0],
      [0, 18, 29, 0, 46, 55, 0, 0, 88],
      [2, 0, 23, 34, 0, 50, 0, 75, 0],
      [4, 0, 0, 30, 40, 0, 61, 78, 0],
      [0, 10, 27, 0, 41, 56, 0, 0, 86],
      [0, 0, 20, 39, 0, 59, 60, 0, 83],
      [9, 0, 24, 0, 0, 51, 64, 0, 81],
      [3, 0, 28, 0, 48, 53, 0, 0, 80],
      [0, 17, 0, 37, 45, 0, 63, 77, 0]
    ],
    textColor: "#000",
  },
  {
    id: "orange#1",
    color: "#F27940",
    number: 1,
    matrix: [
      [3, 15,  0, 32,  0,  0, 60, 71,  0],
      [0, 10, 20,  0, 43, 54,  0,  0, 85],
      [2,  0, 26, 35,  0, 59,  0, 76,  0],
      [6,  0,  0, 39, 49,  0, 68, 73,  0],
      [0, 13, 29,  0, 48, 50,  0,  0, 88],
      [0,  0, 22, 30,  0, 53, 65,  0, 82],
      [1,  0, 25,  0,  0, 58, 69,  0, 90],
      [7,  0, 21,  0, 41, 56,  0,  0, 87],
      [0, 11,  0, 37, 44,  0, 61, 70,  0],
    ],
    textColor: "#000",
  },
  {
    id: "orange#2",
    color: "#F27940",
    number: 2,
    matrix: [
      [0, 12, 0, 34, 40, 0, 0, 75, 89],
      [8, 16, 0, 0, 42, 55, 0, 77, 0],
      [5, 0, 24, 33, 0, 0, 67, 0, 83],
      [0, 14, 27, 0, 0, 51, 0, 78, 84],
      [0, 18, 0, 38, 46, 0, 63, 0, 81],
      [9, 0, 0, 0, 47, 0, 66, 79, 86],
      [4, 0, 28, 31, 0, 57, 0, 72, 0],
      [0, 17, 0, 36, 0, 52, 64, 0, 80],
      [0, 19, 23, 0, 45, 0, 62, 74, 0],
    ],
    textColor: "#000",
  },
  {
    id: "purple#1",
    color: "#8B48A9",
    number: 1,
    matrix: [
      [0, 14, 28, 0, 0, 50, 0, 75, 90],
      [0, 19, 0, 31, 49, 0, 68, 0, 81],
      [5, 0, 20, 0, 47, 0, 0, 77, 84],
      [0, 12, 0, 38, 0, 55, 69, 0, 89],
      [1, 0, 0, 36, 41, 0, 66, 71, 0],
      [0, 18, 26, 0, 0, 57, 0, 70, 88],
      [8, 0, 25, 33, 0, 52, 62, 0, 0],
      [9, 0, 0, 35, 46, 0, 60, 73, 0],
      [0, 10, 27, 0, 48, 59, 0, 0, 86],
    ],
    textColor: "#fff",
  },
  {
    id: "purple#2",
    color: "#8B48A9",
    number: 2,
    matrix: [
      [0, 15, 24, 0, 44, 0, 64, 79, 0],
      [4, 0, 29, 30, 0, 51, 0, 76, 0],
      [0, 17, 0, 32, 0, 53, 63, 0, 80],
      [7, 0, 23, 0, 0, 56, 61, 0, 85],
      [0, 11, 0, 34, 42, 0, 0, 72, 87],
      [3, 13, 0, 0, 45, 54, 0, 74, 0],
      [0, 16, 21, 0, 43, 58, 0, 78, 0],
      [6, 0, 0, 37, 40, 0, 65, 0, 82],
      [2, 0, 22, 39, 0, 0, 67, 0, 83],
    ],
    textColor: "#fff",
  },
  {
    id: "red#1",
    color: "#FA665D",
    number: 1,
    matrix: [
      [5, 0, 29, 30, 0, 56, 0, 0, 80],
      [0, 10, 0, 35, 0, 54, 63, 0, 81],
      [4, 0, 26, 0, 45, 0, 61, 79, 0],
      [3, 14, 0, 0, 43, 50, 0, 71, 0],
      [7, 0, 23, 31, 0, 52, 0, 73, 0],
      [0, 11, 28, 0, 49, 0, 69, 0, 89],
      [0, 0, 24, 34, 0, 53, 67, 0, 85],
      [0, 0, 27, 0, 40, 57, 0, 76, 87],
      [1, 16, 0, 33, 0, 0, 65, 78, 0],
    ],
    textColor: "#fff",
  },
  {
    id: "red#2",
    color: "#FA665D",
    number: 2,
    matrix: [
      [0, 19, 0, 32, 0, 58, 64, 0, 84],
      [0, 13, 20, 0, 48, 55, 0, 77, 0],
      [2, 0, 21, 0, 46, 0, 0, 75, 82],
      [6, 18, 0, 39, 0, 0, 62, 70, 0],
      [0, 0, 25, 0, 41, 59, 0, 74, 83],
      [0, 17, 0, 38, 44, 0, 60, 0, 86],
      [8, 0, 22, 0, 47, 0, 66, 72, 0],
      [9, 12, 0, 37, 42, 0, 0, 0, 88],
      [0, 15, 0, 36, 0, 51, 68, 0, 90],
    ],
    textColor: "#fff",
  },
  {
    id: "lightgreen#1",
    color: "#B0C551",
    number: 1,
    matrix: [
      [0, 11, 0, 35, 0, 59, 68, 0, 80],
      [0, 17, 24, 0, 42, 57, 0, 76, 0],
      [1, 0, 27, 0, 48, 0, 0, 79, 81],
      [7, 16, 0, 31, 0, 0, 65, 77, 0],
      [0, 0, 23, 0, 44, 50, 0, 71, 85],
      [0, 14, 0, 37, 49, 0, 63, 0, 88],
      [3, 0, 20, 0, 46, 0, 67, 73, 0],
      [8, 12, 0, 34, 45, 0, 0, 0, 87],
      [0, 19, 0, 39, 0, 55, 60, 0, 89],
    ],
    textColor: "#fff",
  },
  {
    id: "lightgreen#2",
    color: "#B0C551",
    number: 2,
    matrix: [
      [9, 0, 25, 38, 0, 53, 0, 0, 86],
      [0, 15, 0, 36, 0, 51, 64, 0, 90],
      [2, 0, 28, 0, 47, 0, 66, 78, 0],
      [5, 10, 0, 0, 41, 56, 0, 72, 0],
      [4, 0, 22, 33, 0, 54, 0, 74, 0],
      [0, 13, 26, 0, 40, 0, 61, 0, 82],
      [0, 0, 29, 30, 0, 58, 62, 0, 83],
      [0, 0, 21, 0, 43, 52, 0, 75, 84],
      [6, 18, 0, 32, 0, 0, 69, 70, 0],
    ],
    textColor: "#fff",
  },
  {
    id: "blue#1",
    color: "#297AC2",
    number: 1,
    matrix: [
      [0, 13, 22, 0, 41, 0, 61, 0, 86],
      [3, 0, 24, 34, 0, 52, 0, 71, 0],
      [1, 0, 0, 35, 0, 56, 64, 0, 83],
      [7, 0, 23, 36, 0, 53, 0, 75, 0],
      [5, 0, 0, 48, 59, 0, 0, 72, 84],
      [0, 14, 28, 0, 42, 0, 60, 0, 87],
      [0, 0, 26, 0, 47, 50, 0, 79, 89],
      [4, 10, 0, 30, 48, 0, 66, 0, 0],
      [0, 15, 25, 0, 0, 51, 0, 76, 81],
    ],
    textColor: "#fff",
  },
  {
    id: "blue#2",
    color: "#297AC2",
    number: 2,
    matrix: [
      [9, 16, 0, 0, 46, 0, 65, 0, 80],
      [0, 11, 0, 32, 45, 0, 68, 78, 0],
      [8, 0, 21, 33, 0, 57, 0, 73, 0],
      [6, 0, 20, 0, 43, 0, 63, 77, 0],
      [0, 12, 0, 31, 0, 54, 62, 0, 85],
      [0, 19, 0, 39, 40, 0, 0, 70, 82],
      [0, 18, 29, 0, 0, 58, 0, 74, 90],
      [0, 17, 0, 38, 44, 0, 69, 0, 88],
      [2, 0, 27, 37, 0, 55, 67, 0, 0],
    ],
    textColor: "#fff",
  },
  {
    id: "green#1",
    color: "#71AE68",
    number: 1,
    matrix: [
      [0, 16, 28, 0, 45, 0, 68, 0, 87],
      [4, 0, 29, 35, 0, 55, 0, 73, 0],
      [9, 0, 0, 30, 0, 54, 62, 0, 88],
      [1, 0, 21, 33, 0, 52, 0, 76, 0],
      [8, 0, 0, 0, 40, 50, 0, 79, 81],
      [0, 11, 20, 0, 46, 0, 63, 0, 83],
      [0, 0, 27, 0, 49, 59, 0, 72, 80],
      [2, 19, 0, 32, 48, 0, 67, 0, 0],
      [0, 14, 22, 0, 0, 57, 0, 78, 90],      
    ],
    textColor: "#fff",
  },
  {
    id: "green#2",
    color: "#71AE68",
    number: 2,
    matrix: [
      [6, 18, 0, 0, 47, 0, 69, 0, 86],
      [0, 13, 0, 31, 44, 0, 61, 70, 0],
      [7, 0, 24, 34, 0, 56, 0, 71, 0],
      [5, 0, 23, 0, 41, 0, 65, 74, 0],
      [0, 10, 0, 37, 0, 53, 60, 0, 89],
      [0, 17, 0, 38, 42, 0, 0, 75, 84],
      [0, 15, 25, 0, 0, 51, 0, 77, 85],
      [0, 12, 0, 36, 43, 0, 64, 0, 82],
      [3, 0, 26, 39, 0, 58, 66, 0, 0],      
    ],
    textColor: "#fff",
  },
  {
    id: "pink#1",
    color: "#F66F95",
    number: 1,
    matrix: [
      [0, 18, 22, 0, 0, 55, 0, 76, 87],
      [0, 12, 0, 38, 40, 0, 66, 0, 82],
      [1, 0, 27, 0, 42, 0, 0, 73, 85],
      [0, 10, 0, 34, 0, 56, 63, 0, 80],
      [6, 0, 0, 35, 43, 0, 64, 71, 0],
      [0, 13, 21, 0, 0, 54, 0, 74, 90],
      [7, 0, 24, 32, 0, 53, 67, 0, 0],
      [2, 0, 0, 36, 47, 0, 65, 72, 0],
      [0, 11, 23, 0, 45, 51, 0, 0, 81],
    ],
    textColor: "#fff",
  },
  {
    id: "pink#2",
    color: "#F66F95",
    number: 2,
    matrix: [
      [0, 19, 28, 0, 46, 0, 68, 75, 0],
      [5, 0, 26, 39, 0, 58, 0, 78, 0],
      [0, 14, 0, 37, 0, 50, 69, 0, 84],
      [3, 0, 25, 0, 0, 57, 60, 0, 86],
      [0, 16, 0, 31, 49, 0, 0, 77, 89],
      [8, 17, 0, 0, 48, 59, 0, 79, 0],
      [0, 15, 20, 0, 44, 52, 0, 70, 0],
      [4, 0, 0, 33, 41, 0, 61, 0, 83],
      [9, 0, 29, 30, 0, 0, 62, 0, 88],      
    ],
    textColor: "#fff",
  },
]