import {
  SERVER_URL_LOAD_AUDIO,
  SERVER_URL_LOAD_IMAGE,
  SERVER_URL_LOAD_VIDEO,
} from "../configs/api";

export const checkIsVideo = (type) => type && type.startsWith("video/");
export const checkIsImage = (type) => type && type.startsWith("image/");
export const checkIsAudio = (type) => type && type.startsWith("audio/");
export const checkIsImageByName = (fileName) => {
  const type = getMimeTypeByFileName(fileName);
  return checkIsImage(type);
};
export const checkIsAudioByName = (fileName) => {
  const type = getMimeTypeByFileName(fileName);
  return checkIsAudio(type);
};
export const checkIsVideoByName = (fileName) => {
  const type = getMimeTypeByFileName(fileName);
  return checkIsVideo(type);
};

export function getMimeTypeByFileName(fileName) {
  try {
    if (!fileName) {
      return "";
    }
    // Create a mapping of file extensions to MIME types for images and videos
    const fileTypes = {
      // Image MIME types
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      bmp: "image/bmp",
      webp: "image/webp",

      // Video MIME types
      mp4: "video/mp4",
      webm: "video/webm",
      avi: "video/x-msvideo",
      mov: "video/quicktime",
      wmv: "video/x-ms-wmv",

      // Other file types (add more as needed)
      txt: "text/plain",
      pdf: "application/pdf",
      doc: "application/msword",
      xls: "application/vnd.ms-excel",
      // Add more file types as needed

      mp3: "audio/mpeg",
      ogg: "audio/ogg",
      m4u: "audio/mp4",
    };

    // Get the file extension from the file name
    const fileExtension = fileName?.split(".").pop().toLowerCase();

    // Check if the extension exists in the mapping
    if (fileTypes.hasOwnProperty(fileExtension)) {
      return fileTypes[fileExtension];
    } else {
      // Return a default MIME type or handle the unknown extension as needed
      return "application/octet-stream"; // Default binary MIME type
    }
  } catch (error) {
    console.log("getMimeTypeByFileName:error: ", error);
  }
}

export const getUrlFromName = (filename) => {
  console.log("getUrlFromName: ", filename);
  try {
    const mime = getFileTypeBaseOnFileName(filename);

    const objServerUrl = {
      [FILE_TYPE.IMAGE]: `${SERVER_URL_LOAD_IMAGE}/${filename}`,
      [FILE_TYPE.AUDIO]: `${SERVER_URL_LOAD_AUDIO}/${filename}`,
      [FILE_TYPE.VIDEO]: `${SERVER_URL_LOAD_VIDEO}/${filename}`,
    };

    if (!objServerUrl[mime]) {
      console.log(`Can't find out url: `, objServerUrl[mime]);
      return;
    }
    return objServerUrl[mime];
  } catch (error) {
    console.log("getUrlFromName:error: ", error);
  }
};

export const FILE_TYPE = {
  AUDIO: "audio/*",
  VIDEO: "video/*",
  IMAGE: "image/*",
};

export const getFileTypeBaseOnMime = (mimeFile) => {
  try {
    if (checkIsAudio(mimeFile)) {
      return FILE_TYPE.AUDIO;
    }
    if (checkIsVideo(mimeFile)) {
      return FILE_TYPE.VIDEO;
    }
    if (checkIsImage(mimeFile)) {
      return FILE_TYPE.IMAGE;
    }
    return;
  } catch (error) {
    console.log("error: ", error);
    return;
  }
};

export const getFileTypeBaseOnFileName = (fileName) => {
  const mimeFile = getMimeTypeByFileName(fileName);
  if (mimeFile === "application/octet-stream") {
    return;
  }
  return getFileTypeBaseOnMime(mimeFile);
};

export function getFileNameFromUrl(url) {
  try {
    // Create a new URL object from the provided URL
    const urlObj = new URL(url);

    // Get the pathname from the URL object
    const pathname = urlObj.pathname;

    // Split the pathname by '/' to get the parts
    const parts = pathname.split("/");

    // Get the last part which should be the file name
    const fileName = parts[parts.length - 1];

    // Return the file name
    return fileName;
  } catch (error) {
    // Handle any errors that occur during URL parsing
    console.error("Error parsing URL:", error);
    return null; // Return null in case of an error
  }
}
