import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  AspectRatio,
  Stack,
  Center,
  Heading,
  Text,
  HStack,
  Input,
  TextArea,
} from "native-base";
import _ from "lodash";

const EDIT_DESCRIPTION = "EDIT_DESCRIPTION";
const Description = ({
  newDescription,
  setNewDescription,
  editElement,
  setEditElement,
}) => {
  const textAreaRef = useRef(null);

  const handleClick = _.debounce((e) => {
    if (e.detail !== 2) return;
    setEditElement(EDIT_DESCRIPTION);
  }, 100);

  const onClickTextArea = _.debounce((e) => {
    if (e.detail !== 2) return;
    setEditElement(null);
  }, 200);

  // focus and select all text
  const handleFocus = () => {
    if (textAreaRef.current) {
      textAreaRef.current.setNativeProps({
        selection: {
          start: 0,
          end: textAreaRef.current.value.length,
        },
      });
    }
  };

  return (
    <>
      {editElement === EDIT_DESCRIPTION ? (
        <TextArea
          ref={textAreaRef}
          h={104}
          placeholder="Text Area Placeholder"
          w="100%"
          maxW="300"
          defaultValue={newDescription}
          onChange={(e) => setNewDescription(e.currentTarget.value)}
          onClick={onClickTextArea}
          fontWeight="400"
          isFocused={true}
          onFocus={handleFocus}
          fontSize="14px"
          padding={0}
        />
      ) : (
        <div
          style={{ minHeight: "104px", height: "104px", overflow: "hidden" }}
          onClick={handleClick}
        >
          <Text style={{ color: "#fff" }} fontWeight="400">
            {newDescription}
          </Text>
        </div>
      )}
    </>
  );
};

export default Description;
