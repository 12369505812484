export function isHttpUrl(url) {
  // Regular expression to match URLs with optional HTTP/HTTPS protocol
  const urlPattern = /^(https?|http):\/\/.*$/;

  // Test if the provided URL matches the pattern
  return urlPattern.test(url);
}

export function getDomainFromUrl(url) {
  try {
    // Create a new URL object
    const parsedUrl = new URL(url);
    // Return the hostname (domain) from the URL object
    return parsedUrl.hostname;
  } catch (e) {
    // Handle invalid URLs
    console.error("Invalid URL:", e);
    return null;
  }
}
