import axios from "axios";

export const wrapAxios = async (route, data) => {
  try {
    const res = await axios.post(`${route}`, data);
    return res?.data;
  } catch (error) {
    console.log("wrapAxios:error: ", error);
    return { error };
  }
};
