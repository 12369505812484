import React from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Paper } from "@mui/material";

function CarouselMUI(props) {
  const { data = [], className } = props || {};
  return (
    <Carousel autoPlay={true} className={`${className}`}>
      {data?.map((url, i) => {
        return (
          <Paper
            key={i}
            className="flex items-center justify-center shadow-none"
          >
            <img
              className="rounded-md max-h-96"
              src={url}
              alt={"Carousel item"}
            />
            {/* <Button className="CheckButton">Check it out!</Button> */}
          </Paper>
        );
      })}
    </Carousel>
  );
}

export default CarouselMUI;
