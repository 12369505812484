import { Counter } from "./Counter";
import rocketSvg from "./assets/img/rocket.svg";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./index-custom.scss";
import "./App.scss";
import NavigateMenu from "../../screens/NavigateMenu";

const Countdown = (props) => {
  const { pageTitleName = "DEFAULT TITLE", info, deadlineDate } = props;

  const notify = () =>
    toast.info(info, {
      closeButton: false,
      theme: "colored",
    });

  return (
    <>
      <NavigateMenu />
      <main>
        <div className="container">
          <h1 style={{ textAlign: "center" }}>
            <div
              style={{ color: "#ff6862", fontWeight: "bold", fontSize: "3rem" }}
            >{`${pageTitleName}`}</div>
            <span>DEADLINE COUNTDOWN</span>
          </h1>
          <Counter deadlineDate={deadlineDate} />
          <p className="message">
            {`Tham gia và dự đoán kết quả cùng với chúng tôi`}
          </p>
          <button onClick={notify}>Tham gia</button>
        </div>
        <img src={rocketSvg} alt="" width={500} />
      </main>
    </>
  );
};

export default Countdown;
