import React, { useState, useEffect, useMemo } from "react";
import { Text, HStack, Input } from "native-base";
import _ from "lodash";
import moment from "moment";

const COMPLETED_TIME = "COMPLETED_TIME";
const CompletedTimeCard = ({
  newCompletedTime,
  setNewCompletedTime,
  editElement,
  setEditElement,
}) => {
  // console.log("CompletedTimeCard: ", newCompletedTime, setNewCompletedTime);
  // console.log(
  //   "newCompletedTime: ",
  //   newCompletedTime,
  //   moment(newCompletedTime).format("DD-MM-YYYY")
  // );
  const formatCompletedTime = moment(newCompletedTime || 0).format(
    "DD-MM-YYYY"
  );

  const handleClick = _.debounce((e) => {
    if (e.detail !== 2) return;
    setEditElement(COMPLETED_TIME);
  }, 100);

  const onClickHeadingInput = _.debounce((e) => {
    if (e.detail !== 2) return;
    setEditElement(null);
  }, 200);

  return (
    <>
      <HStack alignItems="center">
        {editElement === COMPLETED_TIME ? (
          <Input
            height={18}
            size="sm"
            placeholder="Heading"
            w="100%"
            maxWidth="112px"
            textAlign="center"
            defaultValue={formatCompletedTime}
            onClick={onClickHeadingInput}
            fontWeight="500"
            isFocused={true}
            color={"gray.700"}
            pr={0}
            pl={0}
            onChangeText={(text) => {
              console.log("text:1 ", text);
              const isValid = moment(text, "DD-MM-YYYY").isValid();
              if (!isValid) return;
              setNewCompletedTime(moment(text, "DD-MM-YYYY").toDate());
            }}
            onKeyPress={({ nativeEvent: { key: keyValue } }) => {
              if (keyValue === "Enter") {
                setEditElement(null);
              }
            }}
            // _light={{
            //   color: "violet.500",
            // }}
          />
        ) : (
          <Text
            color="#fff"
            // _dark={{
            //   color: "warmGray.200",
            // }}
            fontSize="12px"
            fontWeight="400"
            onPress={handleClick}
          >
            {`${formatCompletedTime}`}
          </Text>
        )}
      </HStack>
    </>
  );
};

export default CompletedTimeCard;
