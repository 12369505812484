import React, { useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useLoginGoogle } from "../Provider/GoogleAuthContext";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { getUserPermission } from "../apis/user";
import { getUserAuth } from "../apis/auth";
import { delay } from "../utils/delay";

function GoogleLoginComponent() {
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  // console.log('from: ', from);

  const { user, setUser } = useLoginGoogle();

  useEffect(() => {
    // if(user)
    const strUser = localStorage.getItem("user");
    if (strUser) {
      setUser(JSON.parse(strUser));
    }
  }, []);

  const _onSuccess = async (credentialResponse) => {
    console.log("_onSuccess: ", credentialResponse);

    const userData = await getUserAuth(credentialResponse.credential);
    if (!userData || !userData?.success) {
      console.log("Auth user failed!");
      return;
    }
    const user = userData?.user;

    const permissionData = await getUserPermission(user?.email);
    console.log("permissionData: ", permissionData);
    if (!permissionData?.hasPermission) {
      navigate("/access-denied", { replace: true });
      return;
    }
    setUser({
      ...(user || {}),
      permissions: permissionData?.hasPermission ? ["project"] : [],
    });
    await delay(1000);
    navigate(from, { replace: true });
  };

  return (
    <>
      {!user ? (
        <GoogleLogin
          onSuccess={_onSuccess}
          onError={() => {
            console.log("Login Failed");
          }}
          useOneTap
          // auto_select
        />
      ) : (
        <Navigate to={from} replace />
      )}
    </>
  );
}

export default GoogleLoginComponent;
