import moment from "moment";
import * as Lucide from "lucide-react";

export const SPACE = "\xa0";
export const TAB = SPACE?.repeat(4);

export const TRANSACTION_TYPE = {
  BUY: "BUY",
  SELL: "SELL",
  TRANSFER: "TRANSFER",
};

export const PORTFOLIO_TYPE = {
  ALL: "ALL",
  SAVING: "SAVING",
  BINANCE: "BINANCE",
  TARGET: "TARGET",
  DEX: "DEX",
};

export const MARKET = {
  ADJUSTMENT: "ADJUSTMENT",
  UPTREND: "UPTREND",
  DOWNTREND: "DOWNTREND",
};

export const GAS_FEE_MILES = {
  [MARKET.DOWNTREND]: 15,
  [MARKET.ADJUSTMENT]: 20,
  [MARKET.UPTREND]: 30,
};

export const CRYPTOCURRENCY_TRENDS = MARKET.ADJUSTMENT;
export const GAS_FEE_MILE = GAS_FEE_MILES[CRYPTOCURRENCY_TRENDS];

export const DRAW_MINDS = [
  {
    title: "Một số cách kiếm tiền trong uptrend",
    nodes: [
      {
        title: "Trade Binance",
        nodes: [],
      },
      {
        title: "Trade POW xeggex - lowcap",
        nodes: [],
      },
      {
        title: "DCA ETH, BTC",
        nodes: [],
      },
    ],
  },
];

const X = {
  KhongTu: {
    name: "Khổng Tử ",
    userX: "khongtu_dr",
  },
  Kan: {
    name: "Kan",
    userX: "Kan_0xGemi",
  },
  Vincent: {
    name: "Vincent",
    userX: "@capital_vincent",
  },
};

export const MARKETS = [
  {
    marketName: "Xeggex",
    marketUrl: "https://xeggex.com/account/balances",
  },
  {
    marketName: "OKX",
    marketUrl: "https://www.okx.com/",
  },
];

export const CALL_COIN = [
  {
    ...X.Kan,
    coin: "LAMBY",
    url: "https://twitter.com/Kan_0xGemi/status/1730059789986922791",
    market: "Xeggex",
  },
  {
    ...X.KhongTu,
    coin: "FSC",
    url: "https://twitter.com/khongtu_dr/status/1728584176570687510",
    market: "Xeggex",
  },
  {
    ...X.KhongTu,
    coin: "BIT",
    url: "https://twitter.com/khongtu_dr/status/1729687287821447359",
    market: "Xeggex",
  },
  {
    ...X.KhongTu,
    coin: "DOMO",
    url: "https://twitter.com/khongtu_dr/status/1730774118914732449",
    market: "OKX",
  },
  {
    ...X.Kan,
    coin: "ACG",
    url: "https://twitter.com/Kan_0xGemi/status/1730913409401847846",
    market: "Xeggex",
  },
  {
    ...X.Vincent,
    coin: "RVB",
    url: "https://twitter.com/capital_vincent/status/1731077560564642141",
    market: "Xeggex",
    status: "TO_THE_MOON",
  },
  {
    ...X.KhongTu,
    coin: "DIAC",
    url: "https://twitter.com/khongtu_dr/status/1732963117678874684",
    market: "Xeggex",
    coinMarketUrl: "https://xeggex.com/market/DIAC_USDT",
    status: "TO_THE_MOON",
    callPrice: 8.68,
    callDatetime: moment("2023-12-08").toDate(),
  },
];

const FINANCE_TYPE = {
  OUTCOME: "OUTCOME",
  INCOME: "INCOME",
  // DEPOSIT_CRYPTO: "DEPOSIT_CRYPTO"
};

const FINANCE_MANAGE = [
  {
    name: "Tiện ích",
    type: FINANCE_TYPE.OUTCOME,
    value: 880000,
    notes: "",
    datetime: moment("2023-12-07"),
  },
  {
    name: "Mua cà phê",
    type: FINANCE_TYPE.OUTCOME,
    value: 60000,
    notes: "",
    datetime: moment("2023-12-04"),
  },
  {
    name: "Đóng La-Phông",
    type: FINANCE_TYPE.OUTCOME,
    value: 1800000,
    notes: "",
    datetime: moment("2023-12-04"),
  },
];

const ACTION_TYPE = {
  BUY: "BUY",
  SELL: "SELL",
};

// 341 DOGE / $33
// 351 DOGE / $34
// 485 DOGE / 47$

export const MISS_AIRDROPS = [
  {
    label: "Jito token (JTO) - Solana",
    parts: [
      {
        label: "Điều kiện airdrop:",
        value: "???",
      },
      {
        label: "Tại sao miss?",
        value: "Không có thông tin retroactive của dự án này",
      },
      {
        label: "Giải pháp:",
        value:
          "Tìm những người chia sẻ retroactive của dự án này trước ngày airdrop",
      },
    ],
    airdropDate: moment("2023-12-07").toDate(),
  },
  {
    label: "Pyth Network (LINK của Solana) (PYTH) - Solana",
    parts: [
      {
        label: "Điều kiện airdrop:",
        value: "Stake 400$ SOL trên hệ thống",
      },
      {
        label: "Tại sao miss?",
        value: "Không có thông tin retroactive của dự án này",
      },
      {
        label: "Giải pháp:",
        value:
          "Tìm những người chia sẻ retroactive của dự án này trước ngày airdrop",
      },
    ],
    airdropDate: moment("2023-12-07").toDate(),
  },
  {
    label: "Jupiter (JUP) - Solana",
    airdropDate: moment("2023-02-01").toDate(),
    parts: [
      {
        label: "Điều kiện airdrop:",
        value: "Stake 400$ SOL trên hệ thống",
      },
      {
        label: "Tại sao miss?",
        value: "Không retro bên Solana",
      },
      {
        label: "Giải pháp:",
        value:
          "Tìm những người chia sẻ retroactive của dự án này trước ngày airdrop",
      },
    ],
  },
  {
    label: "Mantle (MNT) - Mantle",
    parts: [
      {
        label: "Điều kiện airdrop:",
        value: "???",
      },
      {
        label: "Tại sao miss?",
        value: [
          "Dự án retro trong thời gian phí gas tăng cao",
          "Chiến lược ưu tiên các dự án mainnet (giảm bớt người tham gia, ít bot, airdrop được nhiều)",
          "Chỉ tham gia một số testnet theo trend, đội ngũ chất lượng",
          "Mainnet trong thời kì gas tăng rất cao > 40 wei",
        ],
      },
      {
        label: "Giải pháp:",
        value: [
          "Không cần giải pháp",
          "--> Không lên binance nên đây airdrop không lớn --> Bỏ qua giảm rủi ro phí gas",
        ],
      },
    ],
    airdropDate: moment("2023-01-25").toDate(),
  },
  {
    label: "PIXEL",
    airdropDate: moment("2023-02-19").toDate(),
    parts: [
      {
        label: "Điều kiện airdrop:",
        value: ["Người chơi game PIXEL", "Stack Ronin"],
      },
      {
        label: "Tại sao miss?",
        value: [
          "Chiến lược: Tập trung vào nghiên cứu MEME, chưa có thời gian nghiên cứu game",
          "Chưa biết đánh giá đầu tư GameFi vì không chuyên cũng như không biết phân tích GameFi",
          "Không đủ tiền mua Land để chơi",
          "Chưa có góc nhìn đầu tư vào đội ngũ game",
        ],
      },
      {
        label: "Giải pháp để không bỏ lỡ sau này:",
        value: [
          "Tìm hiểu game của một số nhà phát triển game Web3 tiềm năng như Sky Mavis (Axie, Pixel)",
          "Tìm hiểu game của các nhà đầu tư lớn -> Có tiền -> Retroactive",
        ],
      },
      {
        label: "Lưu ý:",
        value: [
          "Tập trung retroactive các dự án tiềm năng thay vì chơi game",
          "Hiện tại không có nhiều thời gian nghiên cứu game -> Không tham gia GameFi",
        ],
      },
    ],
  },
  // {
  //   label: "Jito token (JTO) - Solana",
  //   parts: [
  //     {
  //       label: "Điều kiện airdrop:",
  //       value: "15-02 --> 18-02",
  //     },
  //     {
  //       label: "Tại sao miss?",
  //       value:
  //         "Nghe tin Open AI ra ai mới (Sora AI - Có thể tạo video theo prompts) -> AI pump",
  //     },
  //     {
  //       label: "Giải pháp:",
  //       value: "Trong portfolio không có coin theo trend AI",
  //     },
  //   ],
  //   airdropDate: moment("2023-12-07").toDate(),
  // },
  // {
  //   label: "Jito token (JTO) - Solana",
  //   parts: [
  //     {
  //       label: "Điều kiện airdrop:",
  //       value: "15-02 --> 18-02",
  //     },
  //     {
  //       label: "Tại sao miss?",
  //       value:
  //         "Nghe tin Open AI ra ai mới (Sora AI - Có thể tạo video theo prompts) -> AI pump",
  //     },
  //     {
  //       label: "Giải pháp:",
  //       value: "Trong portfolio không có coin theo trend AI",
  //     },
  //   ],
  //   airdropDate: moment("2023-12-07").toDate(),
  // },
  // {
  //   label: "Jito token (JTO) - Solana",
  //   parts: [
  //     {
  //       label: "Điều kiện airdrop:",
  //       value: "15-02 --> 18-02",
  //     },
  //     {
  //       label: "Tại sao miss?",
  //       value:
  //         "Nghe tin Open AI ra ai mới (Sora AI - Có thể tạo video theo prompts) -> AI pump",
  //     },
  //     {
  //       label: "Giải pháp:",
  //       value: "Trong portfolio không có coin theo trend AI",
  //     },
  //   ],
  //   airdropDate: moment("2023-12-07").toDate(),
  // },
];

export const MISS_WAVE = [
  {
    label: "Lỡ mất sóng AI - ARKM 30%, WLD 40%",
    parts: [
      {
        label: "Thời gian:",
        value: "15-02 --> 18-02",
      },
      {
        label: "Lý do tăng:",
        value:
          "Nghe tin Open AI ra ai mới (Sora AI - Có thể tạo video theo prompts) -> AI pump",
      },
      {
        label: "Tại sao miss:",
        value: "Trong portfolio không có coin theo trend AI",
      },
      {
        label: "Giải pháp:",
        value: "Thêm mỗi trend một con vào portfolio để không để miss trend",
      },
      {
        label: "Vấn đề khi thực hiện giải pháp:",
        value: ["Chôn vốn coin khá lâu", "Nhiều khi trend không lặp lại"],
      },
    ],
    airdropDate: moment("2023-12-07").toDate(),
  },
  // {
  //   symbol: "PYTH",
  //   name: "Jito token",
  //   chain: "Solana",
  //   conditions: ["Stake 400$ SOL trên hệ thống"],
  //   whyMiss: "Không có thông tin retroactive của dự án này",
  //   resolve:
  //     "Tìm những người chia sẻ retroactive của dự án này trước ngày airdrop",
  //   airdropDate: moment("2023-12-07").toDate(),
  // },
];

export const MARKETCAP_TYPE = {
  BTC_TYPE: {
    name: "BTC_TYPE",
    min: 500000000000,
    max: 1000000000000,
  },
  ETH_CAP: {
    name: "ETH_CAP",
    min: 10000000000,
    max: 500000000000,
  },
  VERY_TOP_CAP: {
    name: "VERY_TOP_CAP",
    min: 10000000000,
    max: 100000000000,
  },
  TOP_CAP: {
    name: "TOP_CAP",
    min: 1000000000,
    max: 10000000000,
  },
  MID_CAP: {
    name: "MID_CAP",
    min: 100000000,
    max: 1000000000,
  },
  LOW_CAP: {
    name: "LOW_CAP",
    min: 10000000,
    max: 100000000,
  },
  MICRO_CAP: {
    name: "MICRO_CAP",
    min: 5000000,
    max: 10000000,
  },
  NANO_CAP: {
    name: "NANO_CAP",
    min: 5000000,
    max: 10000000,
  },
  LOTTERY: {
    name: "LOTTERY",
    min: 100000,
    max: 3000000,
  },
};

export const MONEY_FLOW_LOG = [
  {
    market: "UPTREND",
    btcPrice: 44000,
    datetime: "2023-12-08",
    title: "Altcoin top tăng mạnh",
    gains: [
      {
        symbol: "AKRO",
        marketCap: "36M",
        fullMarket: "41M",
        increasePercent: "33%",
        price: 0.0084,
      },
      {
        symbol: "WRX",
        marketCap: "76M",
        fullMarket: "191M",
        increasePercent: "37%",
        price: 0.1927,
      },
      {
        symbol: "IMX",
        marketCap: "2.45B",
        fullMarket: "3.8B",
        increasePercent: "20%",
        price: 1.93,
      },
      {
        symbol: "VITE",
        marketCap: "20M",
        fullMarket: "21M",
        increasePercent: "17%",
        price: 0.0191,
      },
      {
        symbol: "DREP",
        marketCap: "15M",
        fullMarket: "30M",
        increasePercent: "14%",
        price: 0.3146,
      },
    ],
  },
  {
    market: "UPTREND",
    datetime: "2023-12-09",
    title: "Altcoin top tăng",
    gains: [
      {
        symbol: "EGLD",
        marketCap: "",
        fullMarket: "",
        increasePercent: "26%",
        price: 66.5,
      },
      {
        symbol: "ARKO",
        marketCap: "",
        fullMarket: "",
        increasePercent: "23%",
        price: 0.00803,
      },
      {
        symbol: "OG",
        marketCap: "",
        fullMarket: "",
        increasePercent: "22%",
        price: 6.1,
      },
    ],
  },
  {
    market: "UPTREND",
    btcPrice: 43000,
    datetime: "2023-12-10",
    title: "BTC ở đỉnh sideway",
    gains: [],
  },
  {
    market: "UPTREND",
    btcPrice: 41000,
    datetime: "2023-12-11",
    title: "BTC giảm sâu 5% lần đầu sau đợt tăng 2 tháng",
    gains: [],
  },
  {
    market: "UPTREND",
    btcPrice: 42000,
    datetime: "2023-12-12",
    title: "",
    gains: [
      {
        symbol: "VTHO",
        marketCap: "175M",
        fullMarket: "175M",
        increasePercent: "42%",
        price: 0.002455,
      },
      {
        symbol: "NTRN",
        marketCap: "205M",
        fullMarket: "764M",
        increasePercent: "29%",
        price: 0.77,
      },
      {
        symbol: "QI",
        marketCap: "148M",
        fullMarket: "253M",
        increasePercent: "51%",
        price: 0.034,
      },
      {
        symbol: "TIA",
        marketCap: "1.8B",
        fullMarket: "12.13B",
        increasePercent: "23%",
        price: 12.3133,
      },
    ],
  },
  {
    market: "SIDEWAY",
    btcPrice: 41000,
    datetime: "2023-12-12",
    title: "BTC sideway",
    gains: [],
  },
];

export const FANTOM_MEME = [
  {
    address: "0x8050bc51D00a2Ad1d42f018Ae1A27c8C5b433810",
    quantity: "155260.34662",
  },
  {
    address: "0x3bc34d8Ace32D768a3F76e17AAEF2B1D8f261e1D",
    quantity: "10973531759.42078",
  },
  {
    address: "0xb715F8DcE2F0E9b894c753711bd55eE3C04dcA4E",
    quantity: "15940649123.85473",
  },
  {
    address: "0x2E07646564a91913516B7488b3CaDAD8aE517553",
    quantity: "261124100.16997",
  },
  { address: "0x382BC87ee1D4E2eC91975B1DAf720B33aFe55d7e", quantity: "0.1755" },
  {
    address: "0xe47d957F83F8887063150AaF7187411351643392",
    quantity: "37359806132.18913",
  },
  {
    address: "0x4BdA1e9A4FF842F8153Ad865218932262E9bde90",
    quantity: "34307023845.42968",
  },
  {
    address: "0x3Ba4274fC96fBc269ebD7f7806A15ec0890F34Ea",
    quantity: "4203.97884",
  },
];

export const POLYGON_MEME = [
  {
    address: "0x162539172b53E9a93b7d98Fb6c41682De558a320",
    quantity: "196535.0143",
  },
];

export const AVAX_MEME = [
  {
    address: "0x420FcA0121DC28039145009570975747295f2329",
    quantity: "12274732.5153",
    reasons: [
      "Phân tích kỹ thuật khung 4h đang hồi phục",
      "Có volumn mua mạnh",
    ],
  },
];

export const API_TYPE = {
  DEXSCREEN: "DEXSCREEN",
  COINGECKO: "COINGECKO",
  COINMARKETCAP: "COINMARKETCAP",
  XEGGEX: "XEGGEX",
};

export const API_MARKET = {
  [API_TYPE.DEXSCREEN]: {
    url: `https://dexscreener.com/`,
    urlDetailCoin: `https://dexscreener.com/`,
  },
};

export const MIX_PORTFOLIO = [
  {
    apiType: API_TYPE.XEGGEX,
    symbol: "BIT",
    quantity: 0,
    pricePerCoin: "",
    transactions: [
      {
        pricePerCoin: "0.000753285",
        quantity: "50",
        datetime: "2023-11-29",
      },
      {
        pricePerCoin: "0.00099",
        quantity: "53628.8414",
        datetime: "2023-11-29",
      },
      {
        pricePerCoin: "0.002487955",
        quantity: "1044.945",
        datetime: "2023-12-02",
      },
      {
        pricePerCoin: "0.002511754",
        quantity: "19469.3411",
        datetime: "2023-12-02",
      },
      {
        pricePerCoin: "0.00337",
        quantity: "14823",
        datetime: "2023-12-06",
      },
      {
        pricePerCoin: "0.002339696",
        quantity: "15878.5458",
        datetime: "2023-12-11",
      },
      {
        pricePerCoin: "0.00233",
        quantity: "5453.2822",
        datetime: "2023-12-11",
      },
    ],
  },
  {
    apiType: API_TYPE.XEGGEX,
    symbol: "FSC",
    quantity: "76950.0066",
    pricePerCoin: "",
    transactions: [
      {
        quantity: "76950",
        pricePerCoin: "0.00064942",
        datetime: "2023-11-27",
      },
    ],
  },
  {
    apiType: API_TYPE.XEGGEX,
    symbol: "TAFT",
    quantity: "62728.13361109",
    pricePerCoin: "",
    transactions: [
      {
        quantity: "62728.131",
        pricePerCoin: "0.00076997",
        datetime: "2023-12-03",
      },
    ],
  },
  {
    chain: "MELIN Layer 2",
    apiType: API_TYPE.DEXSCREEN,
    symbol: "VOYA",
    address: "0x480E158395cC5b41e5584347c495584cA2cAf78d",
    quantity: "24.5222",
    // pricePerCoin: "12,261.1",
    // totalPay: "145",
    transactions: [
      {
        quantity: "24.5222",
        pricePerCoin: "5.8233",
        datetime: moment("2023-03-11T21:37:30"),
      },
    ],
    // sell: {
    //   chain: "FTM",
    //   symbol: "FTM",
    //   quantity: "10.5198",
    //   pricePerCoin: "0.4249",
    // },
  },
  {
    chain: "MELIN Layer 2",
    apiType: API_TYPE.DEXSCREEN,
    symbol: "HUHU",
    address: "0x7a677e59dC2C8a42d6aF3a62748c5595034A008b",
    quantity: "24.5222",
    // pricePerCoin: "12,261.1",
    // totalPay: "145",
    transactions: [
      {
        quantity: "15826.79",
        pricePerCoin: "0.009548",
        datetime: moment("2023-03-12T08:30:00"),
      },
    ],
    // sell: {
    //   chain: "FTM",
    //   symbol: "FTM",
    //   quantity: "10.5198",
    //   pricePerCoin: "0.4249",
    // },
  },
];

export const TEST_PORTFOLIO = [
  {
    chain: "BASE",
    apiType: API_TYPE.DEXSCREEN,
    symbol: "GrassSolanaL2",
    address: "0xfb13c2387d80533339b3547c2024e38FF49AfA5c",
    quantity: 0,
    pricePerCoin: "",
    transactions: [
      {
        pricePerCoin: "0.062296",
        quantity: "802.6197508668",
        datetime: moment("2024-03-15T16:00:00"),
      },
    ],
  },
  // {
  //   chain: "BASE",
  //   apiType: API_TYPE.DEXSCREEN,
  //   symbol: "GrassSolanaL2",
  //   address: "0xfb13c2387d80533339b3547c2024e38FF49AfA5c",
  //   quantity: 0,
  //   pricePerCoin: "",
  //   transactions: [
  //     {
  //       pricePerCoin: "0.062296",
  //       quantity: "802.6197508668",
  //       datetime: moment("2024-03-15T16:00:00"),
  //     },
  //   ],
  // },
];

export const EXPERIENCE_LONGTERM = [
  {
    symbol: "SOL",
    chain: "SOL",
    notes: [
      "Từng đứng vững sau cú sập FTX -> Có khả năng phát triển trong tương lai",
    ],
    datetime: moment("2023-01-08").toDate(),
  },
  {
    symbol: "RON",
    chain: "RON",
    notes: [
      "Sống sau khi bị hack 600M -> Có khả năng phát triển trong tương lai",
    ],
    datetime: moment("2023-01-08").toDate(),
  },
];

export const EXPERIENCE_MEME = [
  {
    symbol: "fCOQ",
    chain: "FTM",
    notes: [
      "Chọn meme coin tên mới, không phải ăn theo những con nổi ở chain khác",
      "fCOQ ăn theo COQ của AVAX",
    ],
    datetime: moment("2023-01-08").toDate(),
  },
  {
    symbol: "GONE",
    chain: "MATIC",
    notes: ["Chọn meme coin không có issue về contract trên dexscreen"],
    datetime: moment("2023-01-08").toDate(),
  },
];

export const EXPERIENCE_NEWS = [
  {
    title: "Bitcoin ETF được thông qua đường giá có thể như thế nào?",
    notes: [
      "Mọi người nghĩ sẽ có Sell The News khi tin tức ra, những nhà đầu tư ngắn hạn bán coin",
      "Gần tới ngày đường giá không tăng nhiều",
      "Sát ngày giá tăng nhẹ 2000 giá",
      "Có tin bị hack x.com, Bitcoin ETF được thông qua",
      "Giá giảm nhẹ rồi tăng lại",
      "=> Sau khi được thông qua -> BTC im lặng ETH Tăng 10%",
      "--> Người ta hy vọng ETH ETF sẽ được thông qua và mua ETH đón sóng cập nhật mới trên ETH",
      "---> Cần Suy nghĩ trước Sau khi Bitcoin ETF được thông qua chuyện gì sẽ xảy ra",
    ],
  },
  {
    title: "Tin tốt: Uniswap share lợi nhuận cho người hold UNI",
    notes: [
      "Khi thị trường sôi động, 1 nền tảng lớn có tin tốt Uniswap",
      "--> kéo theo tất cả dự án trong cùng mảng FOMO tăng theo (JOE, QUICK, COTI, 1INCH, SUSHI, DYDX, JOE, COMPOUND,...)",
    ],
  },
];

export const EXPERIENCES = [
  {
    title: "Không nên trả giá với market",
    notes: [
      `Không biết giá nó sẽ đi như thế nào nên cứ mua nếu thấy Token có tiềm năng dài hạn`,
    ],
  },
  {
    title: "Trước khi mua một đồng coin",
    notes: [
      `Nên tự hỏi câu hỏi "Tỉ lệ (Risk/Reward) thị trường tăng cao hơn hay giảm cao hơn"`,
    ],
  },
  {
    title:
      "Khi một đồng coin được pump 20% sau khi BTC tăng rất mạnh 10% (ARKM, PEPE,...)",
    notes: [
      `Nhận biết: Kích hoạt một trend hiện tại, sẽ có ít nhất 2 lần pump 20% nữa`,
      `Hành động: mua vào đồng coin đó -> vì tất cả đều không dám mua vì rủi ro cao.`,
    ],
  },
];

export const MONEY_FLOW_TYPE = {
  NONE: "NONE",
  INCOME: "INCOME",
  DEPOSIT: "DEPOSIT",
  OUTCOME: "OUTCOME",
  STATIC: "STATIC",
  INVEST: "INVEST",
  RETROACTIVE: {
    GAS: "GAS",
    STACK: "STACK",
    LOCK: "LOCK",
    WAIT_TO_USE: "WAIT_TO_USE",
  },
};

export const MONEY_FLOW_CATEGORY = {
  RECEIVE_AIRDROP: "RECEIVE_AIRDROP",
  GAS_FEE: "GAS_FEE",
  DEPOSIT: "DEPOSIT",
  SAVING: "SAVING",
  "2UPTREND": "2UPTREND",
  BINANCE_SPOT: "BINANCE_SPOT",
  BINANCE_FUTURE: "BINANCE_FUTURE",
  //
  RETROACTIVE_NGUYENQUANIT: "RETROACTIVE_NGUYENQUANIT",
  RETROACTIVE_ZNGUYEN: "RETROACTIVE_ZNGUYEN",
  RETROACTIVE_SYKNGUYEN: "RETROACTIVE_SYKNGUYEN",
  RETROACTIVE_BINANCE_WEB3: "RETROACTIVE_BINANCE_WEB3",
  //
  RETROACTIVE: "RETROACTIVE",
};

export const NETWORK = {
  ZKSYNC: "ZKSYNC",
  CELESTIA: "CELESTIA",
  ARBITRUM: "ARBITRUM",
  LINEA: "LINEA",
};

export const RETROACTIVE_TYPE = {
  PROVIDE_LIQUIDITY: "PROVIDE_LIQUIDITY",
  STACK: "STACK",
  LENDING_BORROW: "LENDING_BORROW",
  PAY_GAS_FEE: "PAY_GAS_FEE",
};

export const WALLET = {
  NGUYENQUANIT17: "NGUYENQUANIT17",
  BINANCEW3: "BINANCEW3",
  JIINGUYEN: "JIINGUYEN",
};

export const MONEY_FLOW = [
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.STACK,
    wallet: WALLET.NGUYENQUANIT17,
    network: NETWORK.CELESTIA,
    symbol: "TIA",
    quantity: 40,
    usdValue: 400,
    notes: "",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    retroactiveType: RETROACTIVE_TYPE.STACK,
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "milkyway",
          url: "https://app.milkyway.zone/withdraw",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE_BINANCE_WEB3,
    moneyFlowType: MONEY_FLOW_TYPE.INVEST,
    wallet: WALLET.NGUYENQUANIT17,
    network: NETWORK.ZKSYNC,
    symbol: "ETH",
    quantity: 0.02423,
    usdSpend: 75,
    usdValue: 75,
    notes: "Retroactive ZKS Binance",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    retroactiveType: RETROACTIVE_TYPE.PAY_GAS_FEE,
    isWithdraw: true,
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.STACK,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "USDC",
    quantity: 25,
    usdSpend: 25,
    usdValue: 25,
    notes: "",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    retroactiveType: RETROACTIVE_TYPE.STACK,
    unlocks: {
      datetime: moment("2024-06-27T12:00:00").toDate(),
      urls: [
        {
          name: "Unlock",
          url: "https://app.pendle.finance/trade/dashboard/overview?timeframe=allTime",
        },
        {
          name: "Unwrap",
          url: "https://app.aave.com/markets/",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.STACK,
    wallet: WALLET.NGUYENQUANIT17,
    network: NETWORK.LINEA,
    symbol: "ETH",
    quantity: 0.1545,
    usdSpend: 500,
    usdValue: 500,
    notes: "Stack ở renzo",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "Renzo",
          url: "https://app.balancer.fi/#/ethereum/pool/0x596192bb6e41802428ac943d2f1476c1af25cc0e000000000000000000000659",
          note: "Sell ezETH ở thị trường thứ cấp",
        },
        {
          name: "Zerolend",
          url: "https://app.zerolend.xyz/dashboard/",
          note: "",
        },
      ],
    },
    isWithdraw: true,
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.STACK,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "BTC",
    quantity: 0.00035,
    usdValue: 25,
    notes: "Stack BTC",
    datetime: moment("2024-03-11T07:00:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "Bouncebit",
          url: "https://bouncebit.io/points-paradise",
          note: "",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.STACK,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "BTC",
    quantity: 0.01,
    usdValue: 773,
    notes: "Stack để nhận Merlin Points",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    isWithdraw: true,
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "Merlin",
          url: "https://merlinchain.io/new",
          note: "",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.STACK,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "TIA",
    quantity: 18,
    usdValue: 323,
    notes: "",
    datetime: moment("2024-01-18T07:00:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "Milkyway",
          url: "https://app.milkyway.zone/withdraw",
          note: "",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.STACK,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "OSMO",
    quantity: 30,
    usdValue: 53,
    notes: "",
    datetime: moment("2024-01-17T07:00:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "Keplr",
          url: "https://wallet.keplr.app/chains/osmosis?modal=staking",
          note: "",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.STACK,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "RONIN",
    quantity: 168,
    usdValue: 475,
    notes: "",
    datetime: moment("2024-04-19T19:10:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "Ronnin",
          url: "https://app.roninchain.com/staking?t=staking",
          note: "",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.WAIT_TO_USE,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "METIS",
    quantity: 0.909,
    usdValue: 100,
    notes: "",
    datetime: moment("2024-04-16T07:00:00").toDate(),
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.INVEST,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "USDT",
    quantity: 150,
    usdValue: 150,
    notes: "Thêm tiền retro",
    datetime: moment("2024-04-01T07:00:00").toDate(),
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RECEIVE_AIRDROP,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.WAIT_TO_USE,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "BTC",
    quantity: 0.0023,
    usdValue: 164,
    notes: "BTC Layer 2 in BEVM",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "",
          url: "",
          note: "",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.GAS_FEE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.GAS,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "ETH",
    quantity: 0.03343,
    usdValue: 100,
    notes: "",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    unlocks: null,
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.GAS,
    wallet: WALLET.BINANCEW3,
    symbol: "ETH",
    quantity: 0.02427,
    usdValue: 75,
    notes: "",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    unlocks: null,
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.DEPOSIT,
    moneyFlowType: MONEY_FLOW_TYPE.DEPOSIT,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "USDT",
    quantity: 380,
    usdValue: 380,
    notes: "Deposit 10000000 VND",
    datetime: moment("2024-04-18T15:50:00").toDate(),
    unlocks: null,
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.GAS,
    wallet: WALLET.BINANCEW3,
    symbol: "ETH",
    quantity: 0.0153,
    usdValue: 50,
    notes: "",
    datetime: moment("2024-04-22T13:00:00").toDate(),
    unlocks: null,
  },
  {
    month: 4,
    moneyFlowCategory: MONEY_FLOW_CATEGORY.RETROACTIVE,
    moneyFlowType: MONEY_FLOW_TYPE.RETROACTIVE.GAS,
    wallet: WALLET.BINANCEW3,
    symbol: "BTC",
    quantity: 0.002,
    usdValue: 133,
    notes: "BBTC mạng BNB",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "Bouncebit",
          url: "https://bouncebit.io/deposit",
          note: "",
        },
      ],
    },
    isWithdraw: true,
  },
  {
    month: 4,
    moneyFlowCategory: "",
    moneyFlowType: MONEY_FLOW_TYPE.NONE,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "",
    quantity: 0,
    usdValue: 0,
    notes: "",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "",
          url: "",
          note: "",
        },
      ],
    },
  },
  {
    month: 4,
    moneyFlowCategory: "",
    moneyFlowType: MONEY_FLOW_TYPE.NONE,
    wallet: WALLET.NGUYENQUANIT17,
    symbol: "",
    quantity: 0,
    usdValue: 0,
    notes: "",
    datetime: moment("2024-04-16T07:00:00").toDate(),
    unlocks: {
      datetime: null,
      urls: [
        {
          name: "",
          url: "",
          note: "",
        },
      ],
    },
  },
];

const obj = {
  month: 2,
  moneyFlowCategory: "",
  moneyFlowType: MONEY_FLOW_TYPE.INCOME,
  usingType: "FLOW",
  symbol: "",
  totalUsd: "",
  usdValue: 291,
  notes: "Bán 100 STRK giá 2.91 (291$) -> Trade coin Binance",
};

export const TOTAL_DEPOSIT_BEFORE_01_2024 = 26910;

export const ALLOCATION = [
  {
    month: 1,
    type: "RECEIVE AIRDROP",
    moneyFlowType: "Income",
    usingType: "FLOW",
    name: "ZKFAIR",
    value: 27,
    notes: "Đầu tư ngược lại retroactive",
  },
  {
    month: 1,
    type: "RECIEVE AIRDROP",
    moneyFlowType: "Income",
    usingType: "FLOW",
    name: "MANTA",
    value: 0,
    notes: "",
  },
  {
    month: 1,
    type: "SELL NFT (RETROACTIVE)",
    moneyFlowType: "Income",
    usingType: "FLOW",
    name: "NFT MANTA",
    value: 65,
    notes: "Đầu tư ngược lại retroactive",
  },
  {
    month: 1,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "[TOTAL]",
    value: 100,
    notes: "Thêm gas fee cho tài khoản JiiNguyen",
  },
  {
    month: 1,
    type: "SAVING",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "ETH",
    value: 300,
  },
  {
    month: 1,
    type: "SAVING",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "BTC",
    value: 200,
  },
  {
    month: 1,
    type: "2UPTREND",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "TON",
    value: 100,
  },
  {
    month: 1,
    type: "2UPTREND",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "RON",
    value: 100,
  },
  {
    month: 1,
    type: "BINANCE FUTURE",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: 63,
  },
  {
    month: 1,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -40,
  },
  {
    month: 1,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "HIGH",
    value: -113,
    notes: "Không trade những coin lowcap không theo trend",
  },
  {
    month: 1,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "ID",
    value: -17,
  },
  {
    month: 1,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "ARKM",
    value: -14,
  },
  {
    month: 1,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "EDU",
    value: -17,
    notes: "Không trade 1 đồng coin vì nó chưa pump",
  },
  {
    month: 1,
    moneyFlowType: "DEPOSIT",
    type: "DEPOSIT",
    usingType: "BIG_FLOW",
    value: 0,
  },
  {
    month: 1,
    moneyFlowType: "WITHDRAW",
    type: "WITHDRAW",
    usingType: "BIG_FLOW",
    value: 0,
  },
  {
    month: 1,
    moneyFlowType: "SCAM",
    type: "SCAM",
    usingType: "BIG_FLOW",
    value: 0,
  },
  {
    month: 2,
    type: "RECEIVE AIRDROP",
    moneyFlowType: "Income",
    usingType: "FLOW",
    name: "STRK",
    value: 291,
    notes: "Bán 100 STRK giá 2.91 (291$) -> Trade coin Binance",
  },
  {
    month: 2,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "[TOTAL]",
    value: 25,
    notes: "Thêm gas fee cho BNB Chain",
  },
  {
    month: 2,
    type: "SAVING",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "ETH",
    value: 50,
  },
  {
    month: 2,
    type: "SAVING",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "BTC",
    value: 0,
  },
  {
    month: 2,
    type: "2UPTREND",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "TON",
    value: 100,
  },
  {
    month: 2,
    type: "2UPTREND",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "RON",
    value: 50,
  },
  {
    month: 2,
    type: "BINANCE FUTURE",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: 553,
  },
  {
    month: 2,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -3,
  },
  {
    month: 2,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "FTM",
    value: -12,
    notes: "Không chọn hệ sinh thái cũ kém active để trade",
  },
  {
    month: 2,
    moneyFlowType: "DEPOSIT",
    type: "DEPOSIT",
    usingType: "BIG_FLOW",
    value: 200,
  },
  {
    month: 2,
    moneyFlowType: "WITHDRAW",
    type: "WITHDRAW",
    usingType: "BIG_FLOW",
    value: 0,
  },
  {
    month: 2,
    moneyFlowType: "SCAM",
    type: "SCAM",
    usingType: "BIG_FLOW",
    value: 0,
  },
  {
    month: 3,
    type: "RECEIVE AIRDROP",
    moneyFlowType: "Income",
    usingType: "FLOW",
    name: "ZK",
    value: 20,
    notes: "Bán 29 ZK được 20$ -> Chuyển thành Phí retroactive",
  },
  {
    month: 3,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "Solana",
    value: 200,
    notes: "Retroactive bên Solana",
  },
  {
    month: 3,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "Blast",
    value: 175,
    notes: "Retroactive bên Blast Chain",
  },
  {
    month: 3,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "Boundbit",
    value: 25,
    notes: "Retroactive BTC Layer 2 trên BNB Chain",
  },
  {
    month: 3,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "Merlin Metamask",
    value: 315,
    notes: "Retroactive BTC Layer 2 - Merlin Chain (0.045 BTC)",
  },
  {
    month: 3,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "Merlin (Unisat)",
    value: 1025,
    notes: "Retroactive BTC Layer 2 - Merlin Chain (0.0155 BTC)",
  },
  {
    month: 3,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "Binance retroactive (BinanceW3)",
    value: 50,
    notes: "Retroactive trên ví web 3 Binance (Khắc phục miss kèo ZK)",
  },
  {
    month: 3,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "RETROACTIVE",
    name: "[JiiNguyen] Retroactive",
    value: 100,
    notes: "JiiNear (50$ - Hot miner), JiiTon (25$), JiiMetamask (25$)",
  },
  {
    month: 3,
    type: "2UPTREND",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "TON",
    value: 150,
  },
  {
    month: 3,
    type: "2UPTREND",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "RON",
    value: 100,
  },
  {
    month: 3,
    type: "2UPTREND",
    moneyFlowType: "Longterm Invest",
    usingType: "INVEST",
    name: "FTM",
    value: 100,
  },
  {
    month: 3,
    type: "BINANCE FUTURE",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -3609,
    notes: "Cắt lỗ 2 lần, lần 1 3200$, lần 2: 1200$",
  },
  {
    month: 3,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: 600,
    notes: "Chốt lời x2 APT giá 14$ (gốc lẫn lãi 900$), bán 1/3 ARB lúc 2$",
  },
  {
    month: 3,
    type: "SELL ASSET",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "JOE",
    value: 455,
  },
  {
    month: 3,
    type: "SELL ASSET",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "BTC",
    value: 2110,
  },
  {
    month: 3,
    moneyFlowType: "DEPOSIT",
    type: "DEPOSIT",
    usingType: "BIG_FLOW",
    value: 0,
  },
  {
    month: 3,
    moneyFlowType: "WITHDRAW",
    type: "WITHDRAW",
    usingType: "BIG_FLOW",
    value: 195,
  },
  {
    month: 3,
    moneyFlowType: "SCAM",
    type: "SCAM",
    usingType: "BIG_FLOW",
    value: -5600,
  },

  {
    month: 4,
    moneyFlowType: "DEPOSIT",
    type: "DEPOSIT",
    usingType: "BIG_FLOW",
    value: 380,
  },
  {
    month: 4,
    moneyFlowType: "WITHDRAW",
    type: "WITHDRAW",
    usingType: "BIG_FLOW",
    value: 0,
  },
  {
    month: 4,
    moneyFlowType: "SCAM",
    type: "SCAM",
    usingType: "BIG_FLOW",
    value: 0,
  },
  {
    month: 4,
    type: "BINANCE FUTURE",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -434,
    notes: "Lỗ 500$ ngay đầu tháng, cuối tháng kiểm soát future trade",
  },
  {
    month: 4,
    type: "BINANCE SPOT",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -34,
    notes: "ACE -47, DOGE +2, SEI +8$, SOL + 3$",
  },
  {
    month: 4,
    type: "DEX TRADE",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -23,
    notes: "MEOW -28$, BENTO +5$",
  },
  {
    month: 4,
    type: "GAS FEE",
    moneyFlowType: "Longterm Invest",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -120,
    notes: "Trade Holdstation -80$, Bountbit - -40$",
  },
  {
    month: 5,
    moneyFlowType: "DEPOSIT",
    type: "DEPOSIT",
    usingType: "BIG_FLOW",
    value: 425,
  },
  {
    month: 5,
    type: "BINANCE FUTURE",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -136,
    notes: "",
  },
  {
    month: 6,
    moneyFlowType: "DEPOSIT",
    type: "DEPOSIT",
    usingType: "BIG_FLOW",
    value: 770,
  },
  {
    month: 6,
    type: "TRY NEW PLATFORM",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -76,
    notes: "",
  },
  {
    month: 6,
    type: "RECEIVE AIRDROP",
    moneyFlowType: "Income",
    usingType: "FLOW",
    name: "ZK",
    value: 201,
    notes: "SELL Token ZK +209$ (Donate Layer Zero - -8$)",
  },
  {
    month: 6,
    type: "BINANCE FUTURE",
    moneyFlowType: "Trade",
    usingType: "FLOW",
    name: "[TOTAL]",
    value: -37,
    notes: "",
  },
];

const WIN_COLOR = "#1CB05B";
const LOSE_COLOR = "#DE1738";
const BLACK_COLOR = "#000";
const CLOSE_COLOR = "#4169e1";
const WIN_TRADE = {
  color: WIN_COLOR,
  bgColor: "#fff",
  bColor: WIN_COLOR,
};
const LOSE_TRADE = {
  color: LOSE_COLOR,
  bgColor: "#fff",
  bColor: LOSE_COLOR,
};
const CLOSE_TRADE = {
  color: CLOSE_COLOR,
  bgColor: "#fff",
  bColor: CLOSE_COLOR,
};

export const addColorEvent = () => {
  return {
    color: "#4169e1",
    bgColor: "#fff",
    bColor: "#4169e1",
    // icon: <Lucide.CandlestickChart size={24} />,
  };
};

export const PROCESS_TRADE = [
  {
    title: "Future -46$ LONG STRK",
    subtitle: "Không nên cắt lỗ LONG ngay khi chạm hỗ trợ",
    datetime: moment("2024-02-22T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
    image: [
      require("./assets/process-trade/capy.png"),
      require("./assets/ikigai-vn.png"),
    ],
  },
  {
    title: "Future -31$ SHORT STRK ",
    subtitle: "Không nên SHORT khi chạm hỗ trợ",
    datetime: moment("2024-02-22T09:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Future +110$ LONG STRK",
    subtitle:
      "Tin tức tốt: Dev STRK giảm release token cho investor -> giá tăng",
    datetime: moment("2024-02-23T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...WIN_TRADE,
  },
  {
    title: "Future +11$ SHORT STRK",
    subtitle: "Bitcoin có xu hướng giảm -> STRK giảm theo nhịp giảm của BTC",
    datetime: moment("2024-02-23T21:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...WIN_TRADE,
  },
  {
    title: "Future +20$ LONG STRK",
    subtitle: "Bitcoin có xu hướng hồi -> Đánh theo xu hướng Altcoin + Bitcoin",
    datetime: moment("2024-02-23T22:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...WIN_TRADE,
  },
  {
    title: "Future -21$ LONG JOE",
    subtitle: "Cắt lỗ ngay khi hồi về, giá tăng quá mạnh -> Không nên LONG",
    datetime: moment("2024-02-24T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Future +25$ SHORT JOE",
    subtitle:
      "Lực bán mạnh + phá hỗ trợ -> SHORT, bán khi đến vùng hỗ trợ (sẽ tăng)",
    datetime: moment("2024-02-24T09:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...WIN_TRADE,
  },
  {
    title: "Future 0$ SHORT JOE (Hoà)",
    subtitle:
      "Nến 5p chạm hỗ trợ 200, nến 15p dưới hỗ trợ 50 -> Ngược xu hướng tắt lệnh (theo dõi)",
    datetime: moment("2024-02-24T22:30:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...CLOSE_TRADE,
  },
  {
    title: "+0$ LONG COTI ",
    subtitle:
      "BTC theo xu hướng giảm ngắn hạn, COTI chạm KC MA 34 15p, BTC theo xu hướng giảm ngắn hạn, COTI chạm KC MA 34 15p, dễ có khả năng giảm -> Cắt hoà đi ngủ dễ hơn",
    datetime: moment("2024-02-24T22:30:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...CLOSE_TRADE,
  },
];

export const INVEST_REVIEW_TYPE = {
  RUN_NODE: "RUN_NODE",
  SWING_TRADE: "SWING_TRADE",
  MISS: "MISS",
  SPOT: "SPOT",
  NEWS: "NEWS",
  SELL: "SELL",
};

export const COIN_INVEST_REVIEW = [
  {
    title: "Không theo kèo chạy node OXOA",
    subtitle: "Team việt, bắt chước mô hình của XAI, key không giới hạn.",
    type: INVEST_REVIEW_TYPE.RUN_NODE,
    datetime: moment("2024-02-29T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Chậm nhịp bắt meme coin (BONK, WEN)",
    subtitle: [
      "Bị phân tâm chọn PEPE hoặc DOGE, Inscription hay ERC-404.",
      "Giải pháp: List tất cả meme ra và khi thị trường sập check chart nào chưa bay thì mua con đó",
    ],
    type: INVEST_REVIEW_TYPE.RUN_NODE,
    datetime: moment("2024-02-29T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Tối ưu retroactive",
    subtitle: ["Lọc dự án testnet làm khi gas fee cao."],
    type: INVEST_REVIEW_TYPE.RUN_NODE,
    datetime: moment("2024-02-29T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Tinh chỉnh portfolio",
    subtitle: [
      "Giảm layer 2 chuyển sang Meme có cộng đồng lớn: DOGE, PEPE, BONK, WEN, MEME,...",
    ],
    type: INVEST_REVIEW_TYPE.RUN_NODE,
    datetime: moment("2024-02-29T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Trade",
    subtitle: ["Nên chốt lệnh ngang đỉnh có trước đó"],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-02T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Miss sóng Meme",
    subtitle: ["PEPE pump 2 sóng 40%"],
    type: INVEST_REVIEW_TYPE.MISS,
    datetime: moment("2024-03-02T14:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Trade",
    subtitle: [
      "Khi một lệnh kéo dài quá lâu (LONG ACE), nên cắt lỗ hoặc chốt 15-20$ đi lệnh khác",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-03T13:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Trade",
    subtitle: ["Khi phá kháng cự cần cắt lệnh ngay bất kể lỗ nhiều"],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-03T13:30:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Trade 2 con",
    subtitle: ["Khi đánh 2 con cùng hệ: tránh trade ngược hướng (ACE, SLP)"],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-03T14:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Trade Ăn may",
    subtitle: [
      "BTC có cú quét LONG -> may mắn ăn được 270$",
      "Kinh nghiệm: Khi có cú quét đặt lệnh ngược lại để ăn hồi",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-03T15:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Giữ lợi nhuận",
    subtitle: [
      "Khi ăn được một khoản tiền kha khá, tâm lý sẽ sinh ra chủ quan",
      "--> Nên dừng trade sau đó",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-03T15:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Tâm lý chủ quan",
    subtitle: [
      `Sau khi ăn kèo to, tâm lý sẽ "Lỡ có thua cũng thua phần lời"`,
      "--> Phát hiện tâm lý này nên dừng trade",
      "Chọn lệnh không tốt --> Thua lỗ",
      "Giải pháp: Ngừng dùng điện thoại chuyển sang dùng laptop để tránh ra lệnh thiếu kiểm soát",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-03T20:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Trade thua vì chủ quan sau khi ăn kèo nhờ may mắn",
    subtitle: ["Thua lỗ liên tiếp 2 lệnh (LONG PEPE -64$, SHORT PEPE 86$)"],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-03T20:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Tâm lý muốn gỡ kèo",
    subtitle: [
      "Cay cú và tiếp tục thua 2 lệnh tiếp theo (LONG PEPE -27$, SHORT PEPE 33$)",
      "Giải pháp: Khi cảm nhận bản thân đang cố gắng gỡ lại những kèo đã thua. Ngừng dùng điện thoại chuyển sang dùng laptop để tránh ra lệnh thiếu kiểm soát",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-03T20:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Trade trong thời gian không tỉnh táo (trước khi đi ngủ)",
    subtitle: [
      "Thua lỗ liên tiếp 2 lệnh (SHORT GLM -31$, LONG PEPE 15$, SHORT AUDIO 10$)",
      "Giải pháp: Tắt máy đi ngủ",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-04T02:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Thử nghiệm đánh meme coin",
    subtitle: [
      "Bối cảnh: Bitcoin tăng lên mức cao 44000$, đang trong quá trình topcap",
      "Hành động: Deposit 150$ vào ví Doge Labs, mua shitcoin",
      "Mục đích: Đón trend shitcoin, khi BTC sideway và bắt đầu giảm",
      "Chốt nếu: shitcoin x2 rút vốn - thả lãi, x5 bán hết",
      "Cách làm: Chia 4 phần mua 50% 2 con cap cao nhất, 50% xổ số 2 con cap cực thấp",
      "Giao dịch: - dogi - 20 - 1.88 - 47.6 USDT - 386 DOGE",
      "Giao dịch: - fiwb - 10000 - 0.0033 - 33 USDT - 339 DOGE",
      "Giao dịch: - musk - 200000 - 0.00017 - 34 USDT - 349 DOGE",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2023-12-07T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Không tuân thủ quy tắc trade",
    subtitle: ["Cố chấp không cắt lệnh khi đi ngược xu hướng BTC -329$"],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-04T15:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Dũng cảm cắt lệnh",
    subtitle: ["Dám cắt lệnh ở 329$, tránh cháy Tài khoản (Tổng mất mát 250$)"],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2024-03-04T15:15:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "DCA FTM",
    subtitle: [
      "Mua dần từ 18-12 đến 15-02 cắt lỗ ftm vì không biết tin fantom update fantom sonic, ngày 4-3 tăng 63%.",
    ],
    type: INVEST_REVIEW_TYPE.SPOT,
    datetime: moment("2023-12-18T08:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Binance list meme coin WIF",
    subtitle: [""],
    type: INVEST_REVIEW_TYPE.NEWS,
    datetime: moment("2023-03-05T18:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Gồng lãi > 250$ (max350)",
    subtitle: [
      "May mắn gặp BTC flash dump",
      "Chạm kháng cự 1h bật lên lại, cuối cùng chốt +25$",
      "Giải pháp: Chốt lời trước chạm kháng cự 1h, 15p",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2023-03-05T11:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Tiếc kèo gồng lãi hụt -> tâm lý nóng nảy",
    subtitle: [
      "Muốn gỡ kèo nóng vội -> mất tiếp 100$",
      "Giải pháp: Bỏ mất kèo này còn kèo khá, còn tiền còn gỡ được",
    ],
    type: INVEST_REVIEW_TYPE.SWING_TRADE,
    datetime: moment("2023-03-05T11:30:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Chốt DOG 10$",
    subtitle: [
      "Muốn gỡ kèo nóng vội -> mất tiếp 100$",
      "Giải pháp: Bỏ mất kèo này còn kèo khá, còn tiền còn gỡ được",
    ],

    type: INVEST_REVIEW_TYPE.SELL,
    sellItem: {
      symbol: "DOG",
      quantity: 1487.85,
      contractAddress: "0xafb89a09d82fbde58f18ac6437b3fc81724e4df6",
      totalUsd: 11.96,
    },
    datetime: moment("2023-03-05T17:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
  {
    title: "Chốt gốc COQ 25$",
    subtitle: [
      "Muốn gỡ kèo nóng vội -> mất tiếp 100$",
      "Giải pháp: Bỏ mất kèo này còn kèo khá, còn tiền còn gỡ được",
    ],

    type: INVEST_REVIEW_TYPE.SELL,
    sellItem: {
      symbol: "DOG",
      quantity: 6784578.4798,
      contractAddress: "0xafb89a09d82fbde58f18ac6437b3fc81724e4df6",
      sellToSymbol: "AVAX",
      sellQuatity: 0.6389,
      sellPrice: 40.03,
    },
    datetime: moment("2023-03-05T17:00:00"),
    icon: <Lucide.CandlestickChart size={24} />,
    ...LOSE_TRADE,
  },
];

// title: "Bán DOGI (12.35DOGI - 61.2$)",

// {
//   title: "Future -46$ LONG STRK",
//   subtitle: "Không nên cắt lỗ LONG ngay khi chạm hỗ trợ",
//   type: INVEST_REVIEW_TYPE.RUN_NODE,
//   datetime: moment("2024-02-29T08:00:00"),
//   icon: <Lucide.CandlestickChart size={24} />,
//   ...LOSE_TRADE,
//   image: [
//     require("./assets/process-trade/capy.png"),
//     require("./assets/ikigai-vn.png"),
//   ],

export const IMAGE_DEFAULT =
  "https://files.codeq.dev/uploads/file-1711521120805-469288693.jpg";

export const UPLOAD_TYPE = {
  UNKNOWN: "UNKNOWN",
  EDIT_RESOURCE_GREENSCREEN_VIDEO: "EDIT_RESOURCE_GREENSCREEN_VIDEO",
  EDIT_RESOURCE_MEME_VIDEO: "EDIT_RESOURCE_MEME_VIDEO",
  EDIT_RESOURCE_SOUND: "EDIT_RESOURCE_SOUND",
  MAIN: "MAIN",
  EVENT: "EVENT",
  MP3_PLAYLIST: "MP3_PLAYLIST",
  SOUND: "SOUND",
  VIDEO: "VIDEO",
  SAVING: "SAVING",
};
export const UPLOAD_TYPE_DEFAUT = UPLOAD_TYPE.UNKNOWN;

// export const DCA_COINS = [
//   {
//     symbol: "ONDO",
//     coinTypes: ["RWA"],
//     contractAddress: "",
//     startAveragePrice: 0.5327,
//     startQuantity: 469.3,
//     startUsd: 250,
//     currentEverage: 0.63,
//     startPrice: 0.9,
//     lowestPrice: 0.65,
//     coingeckoCoinId: "ondo-finance",
//     coinmarketcapSymbol: "ONDO",
//     takeprofits: [
//       {
//         quantity: 238,
//         price: 1.01,
//       },
//       {
//         quantity: 119,
//         price: 1.2,
//       },
//     ],
//   },
// ];

export const TIMES_DEFAULT = 16;
export const PERCENT_GET_DEFAULT = 20;
export const TAKE_PROFIT_DEFAULT = PERCENT_GET_DEFAULT / 100;

export const SITE_TYPES = {
  NONE: "NONE",
  BLOCK: "BLOCK",
  SAVE: "SAVE",
  WEB3_USEFUL: "WEB3_USEFUL",
  RETROACTIVE: "RETROACTIVE",
  SAVE_RETROACTIVE: "SAVE_RETROACTIVE",
  BLOCK_RETROACTIVE: "BLOCK_RETROACTIVE",
  RESEARCH: "RESEARCH",
  EDIT_VIDEO: "EDIT_VIDEO",
  CODE: "CODE",
  DESIGN: "DESIGN",
  AI: "AI",
  COFEE_PLACE: "COFEE_PLACE",
};

export const WEB3_USEFULS = {
  NONE: {
    key: "NONE",
    description: "",
  },
  SWAP: {
    key: "SWAP",
    description: "Swap ngon, phí rẻ,...",
  },
  BRIDGE: {
    key: "BRIDGE",
    description: "Bridge ngon, phí rẻ,...",
  },
};

export const RETROACTIVE_TYPES = {
  NONE: {
    key: "NONE",
    description: "",
  },
  TESTNET_WITH_LIMIT: {
    key: "TESTNET_WITH_LIMIT",
    description:
      "Testnet với một số giới hạn nhất định -> không dùng bot cày nhiều được.",
  },
  SWAP: {
    key: "SWAP",
    description: "Swap to retroactive...",
  },
  BRIDGE: {
    key: "BRIDGE",
    description: "Bridge to retroactive...",
  },
  MIX_SWAP_BRIDGE: {
    key: "MIX_SWAP_BRIDGE",
    description: "Swap kết hợp bridge,...",
  },
  GAME_WITH_STRONG_COMMUNITY: {
    key: "GAME_WITH_STRONG_COMMUNITY",
    description: "Game có cộng đồng mạnh, Hamster, Hot, The Backwoods,...",
  },
};

export const BLOCK_RETROACTIVE_TYPE = {
  NONE: {
    key: "NONE",
    description: "",
  },
  SOCIAL_TASK: {
    key: "SOCIAL_TASK",
    description:
      "Social task là những task yêu cầu like, repost tương tác mạng xã hội nhiều (+5 like, re-post), có thể gây hại tới social account",
  },
  TRADE_VOLUME: {
    key: "TRADE_VOLUME",
    description:
      "Cần có vốn khá to để trade hiệu quả. Ít nhất 1000$, Gas fee: 100$",
  },
  REF_TO_RETROACTIVE: {
    key: "REF_TO_RETROACTIVE",
    description:
      "Cần network lớn, xây dựng social network (Mất nhiều thời gian để build).",
  },
  PLAY_GAME_TO_RETROACTIVE: {
    key: "PLAY_GAME_TO_RETROACTIVE",
    description: "Cần nhiều thời gian nghiên cứu game, 1 ít vốn để mua NFT.",
  },
  HOLD_NFT: {
    key: "HOLD_NFT",
    description:
      "Cần số vốn khá lớn > 500$ để mua 1 NFT. Cần network rộng để biết sớm thông tin về NFT ngon.",
  },
  STACK_TO_RETROACTIVE: {
    key: "STACK_TO_RETROACTIVE",
    description:
      "Cần số vốn tương đối lớn > 1000$. Stack giữ trong khoảng thời gian dài. Không phù hợp cho tài sản không dùng để HOLD.",
  },
  POINT_VOLUME: {
    key: "POINT_VOLUME",
    description:
      "Cần số vốn tương đối lớn > 500$ để có thứ hạng tốt trong thứ hạng cày point",
  },
  DAILY_POINT: {
    key: "DAILY_POINT",
    description:
      "Không cần tài nguyên nhiều nhưng dễ cài bot farm dẫn đến không cạnh tranh được với bot.",
  },
  GAME_TELEGRAM_APP: {
    key: "GAME_TELEGRAM_APP",
    description: "Không cạnh tranh được với bot farm.",
  },
  ANONYMOUS_INVESTMENT_FUND: {
    key: "ANONYMOUS_INVESTMENT_FUND",
    description:
      "Quỹ đầu tư vô danh hoặc không nổi tiếng. Không có nhiều tiền để airdrop cho người dùng.",
  },
  OTHER: {
    key: "OTHER",
    description: "Một số lý do khác.",
  },
};
