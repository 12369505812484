import _ from "lodash";

export const checkPageStatus = (page, callNumberHistory) => {
  try {
    let waits = [];
    // let end = false;
    for(let row = 0; row < page.length; row++){
      let left = 0;
      let waitNumber = 0;
      let array = [];
      for(let col = 0; col < page[row].length; col++){
        if(page[row][col]){
          const isInHistory = callNumberHistory.includes(page[row][col]);
          left += !isInHistory ? 1 : 0;
          if(!isInHistory){
            waitNumber = page[row][col];
            array.push(page[row][col])
          }
        }
      }
      // console.log(`--> row ${row} còn ${left} mới kinh ${array}`);
      if(left === 0){
        return { status: 'KINH', page, bingoRow: page[row] };
      }
      
      if(left === 1){
        waits.push(waitNumber);
      }
    }
    if(waits.length > 0){
      return { status: `WAIT`, waits, page }; 
    }
    
    return { status: false, page }
  } catch (error) {
    console.log('error: ', error);
    
  }
}