export const DEV_SERVER = "http://localhost:3333";
export const SERVER_URL_DEV = "http://localhost:3333";
// export const SERVER_URL_LOAD_IMAGE = "http://localhost:3333";
// export const SERVER_URL_LOAD_VIDEO = "http://localhost:3333";
// export const SERVER_URL_LOAD_AUDIO = "http://localhost:3333";
// export const CARO_SOCKET_SERVER = "http://localhost:3333";
// export const LOTO_SOCKET_SERVER = "http://localhost:3333";
// export const CHALLENGE_IMAGE_SERVER_URL = "http://localhost:3333";
// export const CHALLENGE_SERVER_URL = "http://localhost:3333";
export const UPLOAD_FILE_SERVER_URL_DEV = "http://localhost:3010";
export const TRANSACTION_SERVER_URL_DEV = "http://localhost:3333";
export const CHART_SERVER_URL_DEV = "http://localhost:3333";
export const EVENT_SERVER_URL_DEV = "http://localhost:3333";

// production
export const PRODUCTION_SERVER = "https://template.codeq.dev";
export const SERVER_URL = "https://template.codeq.dev";
export const SERVER_URL_LOAD_IMAGE = "https://template.codeq.dev";
export const SERVER_URL_LOAD_VIDEO = "https://template.codeq.dev";
export const SERVER_URL_LOAD_AUDIO = "https://template.codeq.dev";
export const LOGIN_SERVER_URL = "https://template.codeq.dev";
export const SOCKET_SERVER_URL = "https://template.codeq.dev";
// export const LOGIN_SERVER_URL = 'http://192.168.1.199:3333';
// export const IMAGE_SERVER_URL = 'https://template.codeq.dev';
export const IMAGE_SERVER_URL = "http://localhost:3333";
// export const CHALLENGE_SERVER_URL = "http://localhost:3333";
export const CARO_SOCKET_SERVER = "https://template.codeq.dev";
export const LOTO_SOCKET_SERVER = "https://template.codeq.dev";
export const CHALLENGE_IMAGE_SERVER_URL = "https://template.codeq.dev";
export const CHALLENGE_SERVER_URL = "https://template.codeq.dev";
export const UPLOAD_FILE_SERVER_URL_PRODUCTION = "https://files.codeq.dev";
export const TEMPLATE_SERVER_URL = "https://template.codeq.dev";

//** */
export const SERVER =
  process.env.NODE_ENV === "development" ? DEV_SERVER : PRODUCTION_SERVER;
export const TRANSACTION_SERVER_URL =
  process.env.NODE_ENV === "development"
    ? TRANSACTION_SERVER_URL_DEV
    : TEMPLATE_SERVER_URL;
export const CHART_SERVER_URL =
  process.env.NODE_ENV === "development"
    ? CHART_SERVER_URL_DEV
    : TEMPLATE_SERVER_URL;

export const EVENT_SERVER_URL =
  process.env.NODE_ENV === "development"
    ? EVENT_SERVER_URL_DEV
    : TEMPLATE_SERVER_URL;

export const UPLOAD_FILE_SERVER = UPLOAD_FILE_SERVER_URL_PRODUCTION;
// export const UPLOAD_FILE_SERVER =
//   process.env.NODE_ENV === "development"
//     ? UPLOAD_FILE_SERVER_URL_DEV
//     : UPLOAD_FILE_SERVER_URL_PRODUCTION;

export const SONG_SERVER =
  process.env.NODE_ENV === "development"
    ? DEV_SERVER
    : "https://template.codeq.dev";
