import { deleteItem, getItem, setItem } from "../utils/localstorage.browser";

export const DEFAULT_CACHE_TTL = 60 * 1000; // 60 seconds
export const NO_EXPIRE_DATA_TTL = 0; // no expire

export async function setCacheItem(cacheKey, value, ttl = NO_EXPIRE_DATA_TTL) {
  try {
    const expirationTime = ttl === 0 ? 0 : Date.now() + (ttl || 0);
    const dataToStore = {
      value,
      expirationTime,
    };
    const result = await setItem(cacheKey, dataToStore);
    return result;
  } catch (error) {
    console.log("setCacheItem:error: ", error?.message);
    return false;
  }
}

export async function getCacheItem(cacheKey) {
  try {
    const cachedData = await getItem(cacheKey);

    if (cachedData) {
      if (
        cachedData.expirationTime === 0 ||
        cachedData.expirationTime > Date.now()
      ) {
        // If the cached data is still valid or never expires, return it
        return cachedData.value;
      }
    }

    // Cached data has expired or is not available
    return null;
  } catch (error) {
    console.log("getCacheItem:Error getting cached data:", error);
    return null;
  }
}

export function cacheFunction(
  apiCallFunction,
  cacheKey,
  ttl,
  forceGetNew = false // get new data
) {
  console.log("  --1-> CACHE", apiCallFunction?.name, cacheKey, ttl);
  return async function (...reqData) {
    if (!forceGetNew) {
      const cachedDataValue = await getCacheItem(cacheKey);
      if (cachedDataValue) {
        console.log("  --2-> USE CACHE", cacheKey);
        return cachedDataValue;
      }
    }

    try {
      // Call the API to fetch new data
      const newData = await apiCallFunction(...reqData);
      console.log("  --3-> USE NEW DATA", cacheKey);

      // Cache the API response with TTL, or 0 if ttl is set to 0
      await setCacheItem(cacheKey, newData, ttl);

      return newData;
    } catch (error) {
      // console.log("Error fetchivng data from API:", error?.message);
      // return null; // Or you can handle the error as per your requirement
      throw error;
    }
  };
}

export async function deleteCacheItem(key) {
  await deleteItem(key);
}
