import React from "react";
import { Center, Button, AlertDialog } from "native-base";

const AlertDialogCustom = ({ isOpen, setIsOpen, message }) => {
  // const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => setIsOpen(false);

  const cancelRef = React.useRef(null);
  return (
    <Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>
            <Center>Thông tin</Center>
          </AlertDialog.Header>
          <AlertDialog.Body>{message}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Center>
                <Button
                  variant="info"
                  colorScheme="coolGray"
                  onPress={onClose}
                  ref={cancelRef}
                >
                  Đóng
                </Button>
              </Center>
              {/* <Button colorScheme="danger" onPress={onClose}>
                Delete
              </Button> */}
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
};

export default AlertDialogCustom;
