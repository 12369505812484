import { CHART_SERVER_URL } from "../../../configs/api";
import axios from "axios";

export const getOHLCVBinance = async (data) => {
  try {
    const response = await axios.post(
      `${CHART_SERVER_URL}/chart/getOHLCV`,
      data
    );
    return response?.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
