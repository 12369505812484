import React from "react";

function Block({ children, style = {}, className }) {
  return (
    <div
      className={`m-0 ${className}`}
      style={{
        backgroundColor: "#fff",
        margin: 24,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default Block;
