import React, { useState, useEffect } from "react";
import Game from "./Game";
import {
  Stack,
  VStack,
  Heading,
  Center,
  Divider,
  Button,
  HStack,
  Avatar,
  Modal,
  FormControl,
  Input,
  Radio,
  Text,
} from "native-base";
import NavigateMenu from "../../screens/NavigateMenu";
import { FaPlusCircle } from "react-icons/fa";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import { SOCKET_EMIT, useSocketLoto } from "../../Provider/SocketLotoProvider";
import useToast from "../../hooks/useToast";
import { GAME_STATE, JOIN_STATUS, PAGES } from "./config";
import { filterPages } from "./page";
import { convertName } from "./functions";

const StackJoin = ({
  selectedPage,
  setSelectedPage,
  joinStatus,
  setJoinStatus,
  status,
  setStatus,
  allPage,
  setAllPage,
  lockAction,
  setLockAction,
  rooms,
  setRooms,
  joinRoomRid,
  setJoinRoomRid,
}) => {
  const { user } = useLoginGoogle();
  const { toastInfo } = useToast();

  const {
    socket,
    startGame,
    joinRoom,
    createRoom,
    leaveRoom,
    deleteRoom,
    loadRoom,
  } = useSocketLoto();

  useEffect(() => {
    if (!user) return;
    console.log(user);
  }, [user]);

  const pressJoin = (roomRid) => {
    if (lockAction) {
      toastInfo("Bạn chỉ có thể thao tác sau khi hoàn thành 1 ván");
      return;
    }

    console.log("pressJoin: ", roomRid);
    if (!selectedPage?.length) {
      toastInfo(
        "Chọn ít nhất 1 tờ để tham gia phòng (Không thể đổi tờ sau khi tham gia)"
      );
      return;
    }
    setJoinStatus(JOIN_STATUS.JOINING);
    setStatus(GAME_STATE.WAIT);
    const sheetIds = selectedPage.map((slPage) => slPage.id);
    joinRoom({
      user: {
        ...user,
        sheets: sheetIds,
      },
      roomRid,
    });
  };

  const checkIsCurrentUser = (uid) => {
    return user?.uid && uid === user?.uid;
  };

  const checkIsHostRoom = (checkUid) => {
    const room = rooms.find((room) => room.hostUid === checkUid);
    return !!room;
  };

  const pressDelete = (room) => {
    if (lockAction) {
      toastInfo("Bạn chỉ có thể thao tác sau khi hoàn thành 1 ván");
      return;
    }

    deleteRoom({
      roomRid: room?.rid,
    });
  };

  useEffect(() => {
    if (socket && user) {
      loadRoom({ user });
    }
  }, [socket, user]);

  const pressCreateRoom = () => {
    if (lockAction) {
      toastInfo("Bạn chỉ có thể thao tác sau khi hoàn thành 1 ván");
      return;
    }

    if (rooms?.length > 0) {
      toastInfo("Đã có người tạo phòng.");
      return;
    }

    if (!selectedPage?.length) {
      toastInfo(
        "Chọn ít nhất 1 tờ để tạo phòng (Không thể đổi tờ sau khi tạo)"
      );
      return;
    }

    const sheetIds = selectedPage.map((slPage) => slPage.id);
    createRoom({
      user: {
        ...user,
        sheets: sheetIds,
      },
    });
  };

  useEffect(() => {
    socket.on(
      SOCKET_EMIT.CREATE_ROOM,
      ({ allRoomDetails, allowSheets, room }) => {
        // console.log('roomData:CREATE_ROOM ', allRoomDetails, allowSheets, room);
        setRooms(allRoomDetails);
        const allowSheetDetails = filterPages(allowSheets);
        console.log("allowSheetDetails: ", allowSheetDetails);
        setAllPage(allowSheetDetails);

        const isHostRoom = checkIsHostRoom(room?.hostUid);
        if (isHostRoom) {
          setStatus(GAME_STATE.WAIT);
        }
        setJoinRoomRid(room?.rid);
      }
    );

    /*
    socket.on(SOCKET_EMIT.LOAD_ROOM, roomData => {
      console.log('roomData:LOAD_ROOM ', roomData);
      setRooms(roomData)
    })
    */

    socket.on(SOCKET_EMIT.JOIN_ROOM, (socketData) => {
      // console.log('SOCKET_EMIT.JOIN_ROOM: ', socketData);
      const { success, data, error } = socketData;

      if (!success) {
        if (error?.code === "JOIN_ROOM_001") {
          toastInfo(error.message);
          setAllPage(filterPages(data.allowSheets));
        } else {
          console.log("success: ", success);
          toastInfo(`Tham gia room thất bại.`);
          setJoinStatus(JOIN_STATUS.NOT_JOIN);
        }
        return;
      }

      const {
        user: joinUser,
        allRoomDetails,
        room: joinRoom,
        allowSheets,
      } = data;
      setRooms(allRoomDetails);

      if (joinUser.uid === user.uid) {
        toastInfo(`Tham gia room ${joinRoom?.name} thành công.`);
      } else if (joinUser.uid === joinRoom.id) {
        toastInfo(`${joinUser?.name} đã tham gia room của bạn.`);
      } else {
        toastInfo(`${joinUser?.name} đã vào room.`);
      }
      setJoinRoomRid(joinRoom?.rid);
      setJoinStatus(JOIN_STATUS.JOINED);
      setAllPage(filterPages(allowSheets));
    });

    socket.on(SOCKET_EMIT.RESET_GAME, (socketData) => {
      console.log("RESET_GAME: ", socketData);
      const {
        success,
        data: { room, user, isHost, lockAction },
      } = socketData;

      if (success) {
        if (!isHost) {
          toastInfo(`${user?.name} đã sẵn sàng.`);
          setLockAction(lockAction);
        } else {
          toastInfo(`${user?.name} đã sẵn sàng.`);
          // console.log("user:6767676", user)
          setSelectedPage(filterPages(user.sheets));
        }
        setStatus(GAME_STATE.WAIT);
      }
    });

    socket.on(SOCKET_EMIT.LEAVE_ROOM, (socketData) => {
      console.log("LEAVE_ROOM ", socketData);

      const {
        success,
        data: { allRoomDetails, leaveRoom, leaveUser, allowSheets },
      } = socketData;

      if (!success) {
        toastInfo("Rời phòng thất bại");
        return;
      }

      if (allowSheets) {
        setAllPage(filterPages(allowSheets));
      }

      const isCurUser = checkIsCurrentUser(leaveUser?.uid);
      console.log("isCurUser: ", isCurUser);
      const isLeaveUserIsHost = checkIsHostRoom(leaveUser?.uid);
      console.log("isLeaveUserIsHost: ", isLeaveUserIsHost);

      // myself leave room -> press button leave room
      // người rời khỏi phòng không phải là host
      if (!isLeaveUserIsHost) {
        toastInfo(`Bạn đã rời khỏi phòng ${leaveRoom?.name}`);
        setJoinStatus(JOIN_STATUS.NOT_JOIN);
        setRooms(allRoomDetails);
      } else {
        toastInfo(`${leaveUser?.name} đã rời khỏi phòng.`);
        setRooms(allRoomDetails);
      }
      setStatus(GAME_STATE.PREPARE);
      setSelectedPage([]);
    });

    socket.on(
      SOCKET_EMIT.DELETE_ROOM,
      ({ success, data: { deleteRoomRid, allowSheets } }) => {
        console.log("deleteRoomRid: ", deleteRoomRid);
        const newRooms = rooms.filter((r) => r.rid !== deleteRoomRid);
        console.log("newRooms: ", newRooms);
        setRooms(newRooms);
        // host delete room
        setJoinStatus(JOIN_STATUS.NOT_JOIN);
        setAllPage(filterPages(allowSheets));
        setSelectedPage([]);
      }
    );

    socket.on(
      SOCKET_EMIT.LOAD_GAME,
      ({ PAGES = [], allRoomDetails, allowSheets }) => {
        setRooms(allRoomDetails);
        setAllPage(filterPages(allowSheets));
      }
    );

    socket.on(SOCKET_EMIT.DISCONNECT_GAME, (socketData) => {
      console.log("DISCONNECT_GAME ", socketData);
      const {
        success,
        data: { allRoomDetails, leaveRoom, leaveUser },
      } = socketData;
      console.log("leaveUser:1111 ", leaveUser);

      if (!success) {
        toastInfo("Rời phòng thất bại");
        return;
      }

      const isCurUser = checkIsCurrentUser(leaveUser?.uid);
      if (!leaveUser?.name) return;
      toastInfo(`${leaveUser?.name} đã bị ngắt kết nối.`);
      setRooms(allRoomDetails);
    });

    socket.on(SOCKET_EMIT.MESSAGE, (socketData) => {
      console.log("MESSAGE: ", socketData);
      const { message } = socketData;
      if (message) {
        toastInfo(message);
      }
    });
  }, [socket]);

  const pressStart = (room) => {
    if (lockAction) {
      toastInfo("Bạn chỉ có thể thao tác sau khi hoàn thành 1 ván");
      return;
    }

    startGame({
      roomRid: room?.rid,
    });
  };

  const pressLeaveRoom = (roomRid) => {
    if (lockAction) {
      toastInfo("Bạn chỉ có thể thao tác sau khi hoàn thành 1 ván");
      return;
    }

    leaveRoom({ user, roomRid });
  };

  const renderJoinCancel = (room) => {
    return (
      <>
        {joinStatus === JOIN_STATUS.NOT_JOIN && (
          <Button
            onPress={() => pressJoin(room?.rid)}
            isDisabled={lockAction}
            _loading={{
              bg: "amber.400:alpha.70",
              _text: {
                color: "warmGray.50",
                fontWeight: "medium",
              },
            }}
            _spinner={{
              color: "white",
            }}
            isLoadingText="Joining"
          >
            Join
          </Button>
        )}

        {joinStatus === JOIN_STATUS.JOINED && (
          <Button
            onPress={() => pressLeaveRoom(room?.rid)}
            isDisabled={lockAction}
            _loading={{
              bg: "amber.400:alpha.70",
              _text: {
                color: "warmGray.50",
                fontWeight: "medium",
              },
            }}
            _spinner={{
              color: "white",
            }}
            isLoadingText="Canceling"
          >
            Leave
          </Button>
        )}
      </>
    );
  };

  const roomRender = (room, key) => {
    return (
      <div
        key={key}
        style={{
          position: "relative",
        }}
      >
        <Center
          m="2"
          variant="outline"
          bg="primary.500"
          width={200}
          height={120}
          rounded="sm"
          _text={{
            color: "warmGray.50",
            fontWeight: "medium",
          }}
          shadow="3"
        >
          <span
            style={{
              color: "#fff",
              margin: "12px",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {room?.name || "Room 1"}
          </span>
          {room?.hostUid !== user?.uid ? (
            renderJoinCancel(room)
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  onPress={() => pressDelete(room)}
                  isDisabled={lockAction}
                  variant="outline"
                  _text={{
                    color: "warmGray.50",
                    fontWeight: "medium",
                  }}
                >
                  Delete
                </Button>
              </div>
              <div>
                <Button
                  onPress={() => pressStart(room)}
                  isDisabled={lockAction}
                  variant="outline"
                  _text={{
                    color: "warmGray.50",
                    fontWeight: "medium",
                  }}
                >
                  Start
                </Button>
              </div>
            </div>
          )}
        </Center>
        <div
          style={{
            position: "absolute",
            top: 12,
            right: 12,
          }}
        >
          <div
            style={{
              backgroundColor: "#2F91B2",
              width: 32,
              height: 32,
              borderRadius: 16,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {room.members.length || 0}
            </div>
          </div>
        </div>
        <HStack justifyContent="center" space={2}>
          {room.members.map((member) => {
            return (
              <>
                {/* <img width={16} height={16} alt="" src={member.avatar}></img> */}
                <Avatar
                  size={"sm"}
                  bg="green.500"
                  source={{ uri: member.avatar }}
                >
                  {convertName(member.name)}
                </Avatar>
              </>
            );
          })}
        </HStack>
      </div>
    );
  };

  return (
    <VStack space="2.5" mt="4" m="2">
      <Heading size="md">
        {`Room '${socket?.id || "No socket"}' (-)`}
        <div onClick={pressCreateRoom} style={{ justifyContent: "center" }}>
          <FaPlusCircle
            name="home"
            size={16}
            color={"#9A10BF"}
            style={{ margin: "4px" }}
          />
        </div>
      </Heading>
      <Stack direction="row" mb="2.5" mt="1.5" space={3}>
        {rooms && rooms.map((room) => roomRender(room))}
      </Stack>
      <Divider />
    </VStack>
  );
};

function WrapGame() {
  // user
  const { user } = useLoginGoogle();
  // page
  const [selectedPage, setSelectedPage] = useState([]);
  const [allPage, setAllPage] = useState([]);
  // room
  const [rooms, setRooms] = useState([]);
  const [joinStatus, setJoinStatus] = useState(JOIN_STATUS.NOT_JOIN);
  const [lockAction, setLockAction] = useState(false);
  const [joinRoomRid, setJoinRoomRid] = useState(null);

  const [showResultPopup, setShowResultPopup] = useState(true);
  // playing status
  const [status, setStatus] = useState(null);
  const [callNumber, setCallNumber] = useState(null);
  const [callNumberHistory, setCallNumberHistory] = useState([]);
  const [bingoNumber, setBingoNumber] = useState(0);
  const [waitPages, setWaitPages] = useState([]);
  const [winners, setWinners] = useState([]);

  const { resetGame, leaveRoom, deleteRoom } = useSocketLoto();

  useEffect(() => {
    if (joinRoomRid) {
    }
  }, [joinRoomRid]);

  const getCurrentRoom = () => {
    const currentRoom = rooms.find((room) => room.rid === joinRoomRid);
    return currentRoom;
  };

  const checkIsHostCurrentRoom = () => {
    const curRoom = getCurrentRoom();
    console.log("curRoom: ", curRoom);
    // console.log("user: ", user);
    const isHost = curRoom.hostUid === user.uid;
    return isHost;
  };

  const cancelRoom = () => {
    setShowResultPopup(false);
    resetGameLocal();

    const isHost = checkIsHostCurrentRoom();
    if (isHost) {
      deleteRoom({
        userUid: user?.uid,
        roomRid: joinRoomRid,
      });
    }
  };

  const selectAnotherPage = () => {
    setShowResultPopup(false);
    resetGameLocal();

    const isHost = checkIsHostCurrentRoom();
    if (!isHost) {
      const room = getCurrentRoom();
      leaveRoom({ roomRid: room?.rid });
    }
  };

  const keepThisPage = () => {
    resetGameLocal();

    const room = getCurrentRoom();
    console.log("keepThisPage:room: ", room);
    const curUser = room.members.find((member) => member.uid === user.uid);
    console.log("curUser: ", curUser);

    const isHost = checkIsHostCurrentRoom();
    console.log("isHost: ", isHost);
    resetGame({
      roomRid: room.rid,
      userRid: curUser.rid,
    });
  };

  const resetGameLocal = () => {
    setBingoNumber(0);
    setWaitPages([]);
    setCallNumber(null);
    setCallNumberHistory([]);
    setWinners([]);
  };

  return (
    <>
      <NavigateMenu />
      <StackJoin
        {...{
          selectedPage,
          setSelectedPage,
          joinStatus,
          setJoinStatus,
          status,
          setStatus,
          allPage,
          setAllPage,
          lockAction,
          setLockAction,
          showResultPopup,
          setShowResultPopup,
          rooms,
          setRooms,
          winners,
          setWinners,
          joinRoomRid,
          setJoinRoomRid,
        }}
      />
      <Center paddingTop={8}>
        <Game
          {...{
            selectedPage,
            setSelectedPage,
            joinStatus,
            setJoinStatus,
            status,
            setStatus,
            allPage,
            setAllPage,
            lockAction,
            setLockAction,
            showResultPopup,
            setShowResultPopup,
            winners,
            setWinners,
            //
            bingoNumber,
            setBingoNumber,
            waitPages,
            setWaitPages,
            callNumber,
            setCallNumber,
            callNumberHistory,
            setCallNumberHistory,
            //
            keepThisPage,
            selectAnotherPage,
            joinRoomRid,
            setJoinRoomRid,
          }}
        />
        {winners && winners.length > 0 && (
          <ResultPopup
            {...{
              showResultPopup,
              setShowResultPopup,
              rooms,
              setRooms,
              selectAnotherPage,
              keepThisPage,
              winners,
              setWinners,
              user,
              getCurrentRoom,
              checkIsHostCurrentRoom,
              cancelRoom,
            }}
          />
        )}
      </Center>
    </>
  );
}

export default WrapGame;

const ResultPopup = ({
  showResultPopup,
  setShowResultPopup,
  rooms,
  setRooms,
  selectAnotherPage,
  keepThisPage,
  winners,
  setWinners,
  user,
  getCurrentRoom,
  checkIsHostCurrentRoom,
  cancelRoom,
}) => {
  const curRoom = getCurrentRoom();
  const isHost = checkIsHostCurrentRoom();
  console.log("isHost:---- ", isHost);

  return (
    <Center>
      <Modal isOpen={showResultPopup && curRoom} size="lg">
        <Modal.Content maxWidth="350">
          {/* <Modal.CloseButton /> */}
          <Modal.Header alignItems="center">Kết quả</Modal.Header>
          <Modal.Body color={"#fff"}>
            <VStack space={3}>
              {curRoom.members.map((member) => {
                const hasWin = winners.find(
                  (winner) => winner.uid === member.uid
                );
                return (
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text color="#fff" fontWeight="medium">
                      {member.name}
                    </Text>
                    <Text color={hasWin ? "red.500" : "blueGray.400"}>
                      {hasWin ? "KINH" : "THUA"}
                    </Text>
                  </HStack>
                );
              })}
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <HStack
              flex="1"
              space={2}
              alignItems="center"
              justifyContent="space-around"
            >
              {isHost ? (
                <Button flex="1" onPress={cancelRoom}>
                  {`Huỷ phòng`}
                </Button>
              ) : (
                <Button flex="1" onPress={() => selectAnotherPage()}>
                  {`Chọn lại tờ khác`}
                </Button>
              )}
              <Button
                flex="1"
                onPress={() => {
                  setShowResultPopup(false);
                  keepThisPage();
                }}
              >
                Tiếp tục
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>
  );
};
