import { useContext, useState, createContext } from "react";
import LoadingPageComponent from "../ReusedComponents/LoadingComponent";

const LoadingContext = createContext({});

const LoadingProvider = (props) => {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(false);
  let context = {
    isLoading: isLoading,
    show: () => setIsLoading(true),
    hide: () => setIsLoading(false),
  };
  return (
    <LoadingContext.Provider value={context}>
      {children}
      {isLoading && <LoadingPageComponent isLoading={isLoading} />}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);

export default LoadingProvider;
