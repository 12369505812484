export const CARD_TEMPLATES = {
  NORMAL: "elements/card-templates/card-normal.jpg",
  EFFECT: "elements/card-templates/card-effect.jpg",
  FUSION: "elements/card-templates/card-fusion.jpg",
  RITUAL: "elements/card-templates/card-ritual.jpg",
  SPELL: "elements/card-templates/card-spell.jpg",
  TRAP: "elements/card-templates/card-trap.jpg",
  TOKEN: "elements/card-templates/card-token.jpg",
};

export const ATTRIBUTE_IMAGES = {
  EARTH: "elements/attributes/earth.png",
  DARK: "elements/attributes/dark.png",
  LIGHT: "elements/attributes/light.png",
  FIRE: "elements/attributes/fire.png",
  WATER: "elements/attributes/water.png",
  WIND: "elements/attributes/wind.png",
  DIVINE: "elements/attributes/divine.png",
  SPELL: "elements/attributes/spell.png",
  TRAP: "elements/attributes/trap.png",
};
