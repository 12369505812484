import {
  BasicStorage,
  ChatMessage,
  ChatProvider,
  Conversation,
  ConversationId,
  ConversationRole,
  IStorage,
  MessageContentType,
  Participant,
  Presence,
  TypingUsersList,
  UpdateState,
  User,
  UserStatus
} from "@chatscope/use-chat";
import {nanoid} from "nanoid";

export const createUserModel = (name, avatar) => {
  return {
    name,
    avatar
  }
}

// const generateId1 = (message: ChatMessage<MessageContentType>) => nanoid();
// const groupIdGenerator = () => nanoid();
export const createUserChat = (username='', userStorage: BasicStorage) => {
  const womenChat = { name: username, storage: userStorage };
  return womenChat;
}

export const createUserStorage = (groupIdGenerator=nanoid(), messageIdGenerator=nanoid()) => {
  const userStorage = new BasicStorage({groupIdGenerator, messageIdGenerator});
  return userStorage;
}

export const createUser = (username, avatar) => {
  const newUser = new User({
    id: username,
    presence: new Presence({status: UserStatus.Available, description: ""}),
    firstName: "",
    lastName: "",
    username: username,
    email: "",
    avatar: avatar,
    bio: ""
  });
  return newUser;
}