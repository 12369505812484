import React, { useEffect, useState } from "react";
import Block from "../Plan/elements/Block";
import { UPLOAD_FILE_SERVER } from "../../configs/api";
import axios from "axios";
import UploadFileFB from "../../elements/Flowbite/UploadFileFB";
import { toast } from "react-toastify";
import GalleryFB from "../../elements/Flowbite/MasonryGridGalleryFB";
import DefaultGalleryFB from "../../elements/Flowbite/DefaultGalleryFB";
import { isHttpUrl } from "../../utils/url";
import { getFiles, uploadFile } from "../../apis/upload";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import { UPLOAD_TYPE } from "../Plan/constants";
import { Button, Modal } from "flowbite-react";
import ReactPlayer from "react-player";
import PlayerFullScreen from "../_modules/PlayerFullScreen";

const UploadFileComponent = (props) => {
  const { className } = props;
  const [url, setUrl] = useState(null);
  const [list, setList] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const { user } = useLoginGoogle();
  const [uploadType, setUploadType] = useState(UPLOAD_TYPE.UNKNOWN);
  const uid = user?.uid;

  useEffect(() => {
    (async () => {
      const files = await getFiles(user?.uid);
      // console.log("files:--- ", files);
      if (files) {
        const removeTypes = ["EVENT"];
        const uploadFileList = files.filter(
          (it) => !removeTypes.includes(it.uploadType)
        );
        console.log("uploadFileList: ", uploadFileList);
        setList(uploadFileList);
      }
    })();
  }, [url, user]);

  const downloadFile = async (url, uid) => {
    console.log("downloadFile:url: ", url, uid);
    try {
      const response = await axios.post(
        `${UPLOAD_FILE_SERVER}/upload/download`,
        {
          url,
          uid,
        }
      );
      return response?.data;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleFileChange = async (file) => {
    // console.log("uploadType:--- ", uploadType);
    // return;
    const resData = await uploadFile(file, {
      uploadType,
    });
    if (resData?.url) {
      setUrl(resData?.url);
      // clickCopy(resData?.url);
    }
    if (resData?.fileInfo) {
      setList([...list, resData?.fileInfo]);
    }
  };

  const clickCopy = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("Text copied to clipboard:", url);
        toast.info("Copy successful!");
        // Optionally, you can show a success message to the user
      })
      .catch((error) => {
        toast.info("Copy failed!");
        console.error("Unable to copy text to clipboard:", error);
        // Handle the error, such as displaying an error message to the user
      });
  };

  const clickUploadFile = async () => {
    if (!fileUrl) {
      toast.error("Url is empty");
      return;
    }
    console.log("isHttpUrl(fileUrl): ", isHttpUrl(fileUrl));
    if (!isHttpUrl(fileUrl)) {
      toast.info("This is not a url");
      return;
    }

    const resData = await downloadFile(fileUrl, uid);
    if (resData?.url) {
      setUrl(resData?.url);
      toast.info("Get url successful!");
      // setFileUrl("");
      // clickCopy(resData?.url);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [accessFile, setAccessFile] = useState(null);

  return (
    <div
      className={`flex flex-1 flex-col p-0 m-0 bg-white max-h-max ${className}`}
    >
      <div className="flex flex-1 items-center flex-col self-center max-w-lg">
        <div>
          <UploadFileFB
            onChange={(e) => handleFileChange(e.target.files[0])}
            onDrop={(e) => {
              e.preventDefault();
              // const files = Array.from(e.dataTransfer.files);
              console.log("e.dataTransfer.files[0]: ", e.dataTransfer.files[0]);
              handleFileChange(e.dataTransfer.files[0]);
            }}
          />

          <select
            id="countries"
            className="m-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(event) => setUploadType(event.target.value)}
          >
            {Object.keys(UPLOAD_TYPE).map((uType, i) => {
              return (
                <option key={uType} selected={i === 0} value={uType}>
                  {uType}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex flex-1 pb-2">
          <div className="grid grid-cols-8 gap-2 w-full max-w-xs">
            <input
              id="file-url"
              type="text"
              className="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={fileUrl}
              onChange={(e) => {
                setFileUrl(e.target.value);
              }}
              // disabled
              // readOnly
            />
            <button
              onClick={clickUploadFile}
              data-copy-to-clipboard-target="file-url"
              className="px-4 col-span-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-center inline-flex justify-center"
            >
              <span id="text-name">Upload</span>
            </button>
          </div>
        </div>

        {url && (
          <div className="flex flex-1">
            <div className="grid grid-cols-8 gap-2 w-full max-w-xs">
              {/* <label htmlFor="npm-install" className="sr-only">
                Label
              </label> */}
              <input
                id="npm-install"
                type="text"
                className="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={url}
                disabled
                readOnly
              />
              <button
                onClick={() => clickCopy(url)}
                data-copy-to-clipboard-target="npm-install"
                className="px-4 col-span-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-center inline-flex justify-center"
              >
                <span id="default-message">Copy</span>
                {/* <span id="success-message" className="inline-flex items-center">
                <svg
                  className="w-3 h-3 text-white me-1.5"
                  ariaHidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 12"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5.917 5.724 10.5 15 1.5"
                  />
                </svg>
                Copied!
              </span> */}
              </button>
            </div>
          </div>
        )}

        {url && (
          <img
            className="max-w-md max-h-96"
            src={url}
            alt="display upload file"
          />
        )}
      </div>
      {list && (
        <Block className="p-3">
          <DefaultGalleryFB
            {...{
              setList,
              list,
              openModal,
              setOpenModal,
              accessFile,
              setAccessFile,
            }}
          />
        </Block>
      )}
      {openModal && (
        <PlayerFullScreen
          {...{
            openModal,
            setOpenModal,
            videoUrl: `${UPLOAD_FILE_SERVER}/uploads/${setAccessFile}`,
          }}
        />
      )}
      {openModal && (
        <Modal
          show={openModal}
          position={"center"}
          onClose={() => setOpenModal(false)}
        >
          {/* <Modal.Header>Small modal</Modal.Header> */}
          <Modal.Body className="p-2">
            <ReactPlayer
              controls={true}
              width={"100%"}
              height={"auto"}
              url={`${UPLOAD_FILE_SERVER}/uploads/${accessFile}`}
            />
          </Modal.Body>
          <Modal.Footer className="flex items-center justify-center p-2">
            <Button
              onClick={() => {
                // setIsPlaying(false);
                setOpenModal(false);
              }}
            >
              Close
            </Button>
            {/* <Button color="gray" onClick={() => setOpenModal(false)}>
            Decline
          </Button> */}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default UploadFileComponent;
