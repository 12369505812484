import { forwardRef } from "react";
import { disableScroll } from "../utils/browserAPI";

const ImagePreview = forwardRef((props, ref) => {
  const { show, setShow, selectItem } = props || {};
  return (
    <div
      onClick={() => {
        setShow(!show);
        disableScroll(false);
      }}
      // className='image-display'
      style={{
        display: show ? "flex" : "none",
        backgroundColor: "#000000",
        width: "100%",
        height: "100%",
      }}
      className="absolute"
    >
      <div ref={ref} className="flex" style={{}}>
        <div
          className="flex flex-row p-2 absolute font-medium text-xl left-0 bottom-0 text-center"
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="text-white py-2"
            style={{ fontSize: 18 }}
          >{`${selectItem?.title}`}</div>
        </div>
      </div>
    </div>
  );
});

export default ImagePreview;
