import React, { useCallback, useEffect, useRef, useState } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { useWindowSize } from "@uidotdev/usehooks";
import moment from "moment";
import useLocalStorageTrigger from "../../hooks/useLocalStorageTrigger";
import { BITCOIN_PRICE_OHLC, BITCOIN_PRICE_OHLC_BINANCE } from "./keys";
import { getOHLC } from "./apis/coingecko";
import { getOHLCVBinance } from "./apis/chart";
import { BigNumber } from "bignumber.js";
import FinancialChart from "./FinancialChart";
import { Skeleton } from "@mui/material";

const calculatorMA = (data, maLength = 13) => {
  // Calculate the Moving Average data
  // const maLength = 13; // Adjust the length of the Moving Average
  const maData = [];
  for (let i = maLength - 1; i < data.length; i++) {
    const sum = data
      .slice(i - maLength + 1, i + 1)
      .reduce((acc, val) => acc + val.close, 0);
    maData.push({
      time: moment(data[i].time).unix(),
      value: sum / maLength,
    });
  }
  return maData;
};

const CandleStickChartBTC = (props) => {
  const { nowTriggerDate } = props;
  const chartContainerRef = useRef(null);
  const chartInstance = useRef(null);
  const { width, height } = useWindowSize();
  const [chartData, setChartData] = useState(null);
  const [coinChart, setCoinChart] = useState({
    symbol: "BTCUSDT",
    timeframe: "4h",
    since: undefined,
    limit: 500,
  });

  const getOHLCBNCb = useCallback(
    () =>
      getOHLCVBinance(coinChart)
        .then((data) =>
          data.data.prices.map((ohlc) => ({
            date: ohlc[0],
            open: BigNumber(ohlc[1]).toNumber(),
            high: BigNumber(ohlc[2]).toNumber(),
            low: BigNumber(ohlc[3]).toNumber(),
            close: BigNumber(ohlc[4]).toNumber(),
            volume: BigNumber(ohlc[5]).toNumber(),
          }))
        )
        .catch((error) => {
          console.log("error: ", error);
        }),
    [coinChart]
  );

  useState(() => {
    getOHLCBNCb().then((data) => {
      setChartData(data);
    });
  }, [getOHLCBNCb]);

  // const [btcOHLCBN, setBtcOHLCBN] = useLocalStorageTrigger(
  //   BITCOIN_PRICE_OHLC_BINANCE,
  //   nowTriggerDate,
  //   null,
  //   getOHLCBNCb,
  //   nowTriggerDate
  // );

  return (
    <div
      style={{
        backgroundColor: "#fff",
        margin: 24,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {!chartData ? (
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rectangular"
          width={996}
          height={700}
          animation="wave"
        />
      ) : (
        <FinancialChart initData={chartData} />
      )}
      {/* <div ref={chartContainerRef} id="chart-container" /> */}
    </div>
  );
};

export default CandleStickChartBTC;
