export async function setItem(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Error while setting item in localStorage:", error);
  }
}

export async function getItem(key) {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  } catch (error) {
    console.error("Error while getting item from localStorage:", error);
    return null;
  }
}

export async function deleteItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error("Error while deleting item from localStorage:", error);
  }
}

export async function deleteAllItems() {
  try {
    localStorage.clear();
  } catch (error) {
    console.error("Error while deleting all items from localStorage:", error);
  }
}
