import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  CACHE_TASK,
  CALENDAR_EVENTS,
  COLOR_CALENDAR,
  COLOR_CALENDAR_HEX,
  GOOGLE_SCOPES,
} from "../config";
import { useCacheLocalStorage } from "../../../hooks/useCacheLocalStorage";
import { deleteCacheItem } from "../../../helpers/cache.simple";
import { hasGrantedAllScopesGoogle, useGoogleLogin } from "@react-oauth/google";
import { authGoogle } from "../Task/task.function";
import { getEventCalendar } from "./calendar";
import CommonButton from "../../basic/CommonButton";
import Block from "../elements/Block";
// import "./CalendarComponent.css";
import { PieChart } from "@mui/x-charts/PieChart";
import _ from "lodash";
import {
  convertMinuteToHourMinute,
  formatHourMinute,
} from "../../../utils/datetime";

const GOOGLE_PERMISSION_TOKEN = "GOOGLE_PERMISSION_TOKEN";

moment.locale("en", {
  week: {
    dow: 1,
  },
});

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CalendarComponent = () => {
  // console.log("GanttChartComponent: ");

  const [permissionToken, setPermissionToken] = useLocalStorage(
    GOOGLE_PERMISSION_TOKEN
  );
  // console.log("permissionToken: ", permissionToken);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log("tokenResponse: ", tokenResponse);
      const hasAccess = hasGrantedAllScopesGoogle(
        tokenResponse,
        ...GOOGLE_SCOPES
      );
      if (hasAccess) {
        setPermissionToken(tokenResponse?.access_token);
      }
    },
    scope: GOOGLE_SCOPES.join(" "),
    onError: (err) => {
      console.log("err:----6 ", err);
    },
  });

  const memoizedGetEvent = useCallback(() => {
    return getEventCalendar(permissionToken);
  }, [permissionToken]);

  const [events, updateEvent] = useCacheLocalStorage(
    CALENDAR_EVENTS,
    0,
    memoizedGetEvent
  );

  const eventPropGetter = (event) => {
    const backgroundColor = COLOR_CALENDAR[event?.colorId || 0]; // Retrieve the color property from the event
    return {
      style: {
        backgroundColor,
        border: "none",
        fontSize: 11,
        fontFamily: "Roboto-Medium, sans-serif",
        fontWeight: "bold",
      },
    };
  };

  const handleSelectEvent = (event) => {
    console.log("handleSelectEvent:e: ", event, JSON.stringify(event, null, 2));
  };

  const reMapTasks = !events
    ? null
    : events.map((item) => {
        const { calStart, calEnd } = item || {};
        return {
          ...item,
          calStart: moment(calStart).toDate(),
          calEnd: moment(calEnd).toDate(),
        };
      });

  const today = moment().format("DD-MM-YYYY");

  const todayTasks = reMapTasks?.filter((ev) => {
    const calStart = moment(ev.calStart).format("DD-MM-YYYY");
    const calEnd = moment(ev.calEnd).format("DD-MM-YYYY");
    // console.log("calStart: ", calStart);
    // console.log("calEnd: ", calEnd);
    if (calStart === today && today === calEnd) {
      return true;
    }
    return false;
  });

  console.log("todayTasks: ", todayTasks);

  let pieTask = {};
  for (let tTask of todayTasks || []) {
    // console.log("tTask: ", tTask);
    const { calStart, calEnd } = tTask;
    let minute = moment(calEnd).diff(moment(calStart), "minute");
    console.log("minute: ", minute);

    // const key = tTask?.title?.split(":")[0];
    const key = tTask?.colorId;
    // console.log("COLOR_CALENDAR_HEX[key || 0]: ", COLOR_CALENDAR_HEX[key || 0]);

    const colorNames = {
      3: "Retroactive",
      2: "Relax",
      7: "Future Code",
      8: "Cần thiết",
      9: "Việc bổ trợ tương lai",
      10: "My Meaning",
      11: "Chiến lược",
    };

    if (!pieTask[key]) {
      pieTask[key] = {
        value: minute,
        label: colorNames[key] || key,
        color: COLOR_CALENDAR_HEX[key || 0],
      };
    } else {
      pieTask[key].value += minute;
    }
  }

  const pieTasks = _.values(pieTask).map((task) => {
    const hm = formatHourMinute(convertMinuteToHourMinute(task.value));

    return {
      ...task,
      label: `${task.label} - ${hm}`,
    };
  });
  console.log("pieTasks: ", pieTasks);

  const cHeight = window?.innerHeight - 41;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <Block className="flex bg-slate-400">
        {pieTasks?.length && (
          <div className="flex flex-1 justify-center items-center">
            <div className="flex flex-1 max-w-lg">
              <PieChart
                // colors={["red", "blue", "green"]}
                series={[
                  {
                    data: pieTasks,
                  },
                ]}
                // className=""
                margin={{
                  top: 12,
                  bottom: 32 * Math.ceil(pieTasks?.length / 2),
                  left: 12,
                  right: 12,
                }}
                width={400}
                height={400}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 4,
                    // markGap: 12,
                    // itemMarkWidth: 26,
                  },
                }}
              />
            </div>
          </div>
        )}
      </Block>
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          alignContent: "center",
        }}
      >
        <CommonButton
          title="LOGIN GOOGLE"
          onClick={() => {
            login();
          }}
        />
        <CommonButton title="UPDATE" onClick={() => updateEvent()} />
        <CommonButton
          title="CLEAR CACHE"
          onClick={() => deleteCacheItem(CACHE_TASK)}
        />
      </div>

      {reMapTasks && (
        <DnDCalendar
          defaultDate={moment().toDate()}
          defaultView="week"
          events={reMapTasks}
          step={15}
          localizer={localizer}
          // onEventDrop={onEventDrop}
          // onEventResize={onEventResize}
          resizable
          style={{ height: cHeight }}
          scrollToTime={moment().set({
            hour: 7,
            minute: 0,
            second: 0,
            millisecond: 0,
          })}
          startAccessor="calStart"
          endAccessor="calEnd"
          eventPropGetter={eventPropGetter}
          onSelectEvent={handleSelectEvent}
        />
      )}
    </div>
  );
};

export default CalendarComponent;
