import React, { useState } from "react";
import AccordionMUI from "./components/AccordionMUI";
import moment from "moment";
import {
  getRandomDarkHexColor,
  getRandomLightHexColor,
} from "../../utils/color";

function Expeariment(props) {
  const { data, title, hideTitle = false } = props || {};
  const [show, setShow] = useState(false);
  return (
    <div
      style={{
        margin: 24,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: getRandomDarkHexColor(),
      }}
    >
      {!hideTitle && (
        <div
          style={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
            padding: 8,
            cursor: "pointer",
            color: "#fff",
          }}
          onClick={() => setShow(!show)}
        >
          {title}
        </div>
      )}
      <div style={{ display: show ? "flex" : "none", flexDirection: "column" }}>
        {data.map((exp, key) => {
          const titleNote = exp?.title || `${exp.symbol} (${exp.chain})`;
          return (
            <AccordionMUI
              key={key}
              title={`- ${titleNote}`}
              // titleStyle={{ backgroundColor: getRandomLightHexColor() }}
            >
              <div style={{ fontWeight: "bold" }}>Kinh nghiệm</div>
              {exp?.notes?.map((note, nKey) => {
                return (
                  <div key={nKey} style={{ paddingLeft: 12 }}>{`${
                    nKey + 1
                  }. ${note}`}</div>
                );
              })}
              {/* <div style={{ paddingLeft: 12 }}>{exp?.whyMiss}</div> */}
              {/* <div style={{ fontWeight: "bold" }}>Cách giải quyết:</div>
            <div style={{ paddingLeft: 12 }}>{exp?.resolve}</div> */}
            </AccordionMUI>
          );
        })}
      </div>
    </div>
  );
}

export default Expeariment;
