import React from "react";
import { IMAGE_DEFAULT } from "../../components/Plan/constants";

const hintTextDefault = "SVG, PNG, JPG or GIF (MAX. 800x400px)";
function UploadFileFB(props) {
  const {
    onChange,
    onDrop,
    hintText = hintTextDefault,
    imagePreview = IMAGE_DEFAULT,
  } = props || {};
  return (
    <div
      onDrop={onDrop}
      onDragEnter={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}
      onDragOver={(e) => e.preventDefault()}
      className="flex items-center justify-center w-full p-2"
    >
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="relative flex flex-col max-w-md rounded items-center justify-center ">
          <img
            className="w-full opacity-20 max-w-md max-h-80 object-cover"
            src={imagePreview}
            alt="preview"
          />
          <div className="absolute flex flex-col items-center justify-center w-full">
            <svg
              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
              <span className="font-semibold">Click to upload</span>
              <div>or</div>
              <div>drag and drop</div>
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {hintText}
            </p>
          </div>
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          onChange={onChange}
        />
      </label>
    </div>
  );
}

export default UploadFileFB;
