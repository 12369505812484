import React from "react";
import {
  elderRay,
  ema,
  sma,
  discontinuousTimeScaleProviderBuilder,
  Chart,
  ChartCanvas,
  CurrentCoordinate,
  BarSeries,
  CandlestickSeries,
  ElderRaySeries,
  LineSeries,
  MovingAverageTooltip,
  OHLCTooltip,
  SingleValueTooltip,
  lastVisibleItemBasedZoomAnchor,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
  ZoomButtons,
  withDeviceRatio,
  withSize,
  TooltipContainer,
  StraightLine,
  ToolTipText,
  ToolTipTSpanLabel,
  SingleTooltip,
  HoverTooltip,
  Annotate,
  LabelAnnotation,
  SvgPathAnnotation,
} from "react-financial-charts";

function useMa(props) {
  // console.log("props: ", props);

  const objMa = props.reduce((obj, item) => {
    const { type, length, name, color = "=#F44336" } = item || {};
    const isSma = type === "ma";
    obj[name] = (isSma ? sma() : ema())
      .id(2)
      .options({
        windowSize: length,
        stroke: "green",
        fill: "green",
        color: "green",
      })
      .stroke(color)
      .merge((d, c) => {
        d[name] = c;
      })
      .accessor((d) => d[name]);
    return obj;
  }, {});

  // const maNumber = (isSma ? sma() : ema())
  //   .id(2)
  //   .options({ windowSize: length })
  //   .merge((d, c) => {
  //     d.maNumber = c;
  //   })
  //   .accessor((d) => d.maNumber);

  const objTooltip = props.reduce((obj, item) => {
    const { type, length, name } = item || {};
    const isSma = type === "ma";

    const tooltip = {
      yAccessor: objMa[name].accessor(),
      type: isSma ? "SMA" : "EMA",
      stroke: objMa[name].stroke(),
      windowSize: objMa[name].options().windowSize,
    };
    obj[name + "Tooltip"] = tooltip;
    return obj;
  }, {});

  // const createTooltip = (objMa) => {
  //   return {
  //     yAccessor: objMa[name].accessor(),
  //     type: isSma ? "SMA" : "EMA",
  //     stroke: objMa[name].stroke(),
  //     windowSize: objMa[name].options().windowSize,
  //   };
  // }

  const render = (maNumber) => {
    return (
      <>
        <LineSeries
          yAccessor={maNumber.accessor()}
          strokeStyle={maNumber.stroke()}
        />
        {/* <CurrentCoordinate
          yAccessor={maNumber.accessor()}
          fillStyle={maNumber.stroke()}
        /> */}
      </>
    );
  };

  const objRender = props.reduce((obj, item) => {
    obj[item.name + "Render"] = render(objMa[item.name]);
    return obj;
  });

  return {
    objMa,
    objTooltip,
    render,
    objRender,
  };
}

export default useMa;
