import React from "react";

function Title({ children, style = {}, className = "" }) {
  return (
    <h3
      style={{ fontWeight: "bold", textAlign: "center", margin: 8, ...style }}
      className={className}
    >
      {children}
    </h3>
  );
}

export default Title;
