import React, { useContext, useCallback, useEffect } from "react";
import io from "socket.io-client";
import useToast from "../hooks/useToast";
import { LOTO_SOCKET_SERVER } from "../configs/api";

export const SOCKET_EMIT = {
  CREATE_ROOM: "CREATE_ROOM",
  DELETE_ROOM: "DELETE_ROOM",
  LOAD_ROOM: "LOAD_ROOM",
  JOIN_ROOM: "JOIN_ROOM",
  LEAVE_ROOM: "LEAVE_ROOM",
  // game
  LOAD_GAME: "LOAD_GAME",
  START_GAME: "START_GAME",
  END_GAME: "END_GAME",
  RESET_GAME: "RESET_GAME",
  DISCONNECT_GAME: "DISCONNECT_GAME",
  // number
  CALL_NUMBER: "CALL_NUMBER",
  MESSAGE: "MESSAGE",
};

export const socket = io(LOTO_SOCKET_SERVER, {
  transports: ["websocket"],
  upgrade: false,
  reconnectionAttempts: 15,
  // reconnection: false,
});
const SocketContext = React.createContext();

const SocketLotoProvider = (props) => {
  const { children } = props;
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocketLoto = (loadGameListener) => {
  const socket = useContext(SocketContext);
  const { toastInfo } = useToast();

  useEffect(() => {
    socket.io.on("error", (error) => {
      console.log("error: ", error);
      toastInfo(error.message);
    });
  }, [socket, toastInfo]);

  const joinRoom = (data) => {
    socket.emit(SOCKET_EMIT.JOIN_ROOM, data);
  };

  const leaveRoom = (data) => {
    socket.emit(SOCKET_EMIT.LEAVE_ROOM, data);
  };

  const deleteRoom = ({ roomRid }) => {
    console.log("deleteRoom: ", { roomRid });
    socket.emit(SOCKET_EMIT.DELETE_ROOM, { roomRid });
  };

  const createRoom = (data) => {
    socket.emit(SOCKET_EMIT.CREATE_ROOM, data);
  };

  const loadRoom = (data) => {
    socket.emit(SOCKET_EMIT.LOAD_ROOM, data);
  };

  const startGame = (data) => {
    socket.emit(SOCKET_EMIT.START_GAME, data);
  };

  const resetGame = (data) => {
    socket.emit(SOCKET_EMIT.RESET_GAME, data);
  };

  const loadGame = useCallback(
    (data) => {
      if (!socket) return;
      socket.emit(SOCKET_EMIT.LOAD_GAME, data);
    },
    [socket]
  );

  return {
    socket,
    // room events
    loadRoom,
    joinRoom,
    leaveRoom,
    deleteRoom,
    createRoom,
    // game events
    loadGame,
    startGame,
    resetGame,
  };
};

export default SocketLotoProvider;
