import { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { Button, Center, Divider, HStack, VStack } from "native-base";
import { toast } from "react-toastify";
import {
  MAX_GROUP_SELECTED_PAGE,
  MAX_ROW_PAGE,
  MAX_SELECTED_PAGE,
  TIME_CALL_NUMBER,
  GAME_STATE,
} from "./config";
import { checkPageStatus } from "./utils";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import AlertDialogCustom from "./AlertDialogCustom";
import { SOCKET_EMIT, useSocketLoto } from "../../Provider/SocketLotoProvider";
import useToast from "../../hooks/useToast";
import { filterPages, customSortfunc } from "./page";

const RenderCircleNumber = ({ number, circleStyle }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
      }}
    >
      <div
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            width: 50,
            height: 50,
            borderRadius: 25,
            backgroundColor: "#fff",
          },
          circleStyle && circleStyle,
        ]}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 30,
          }}
        >
          {number || ""}
        </div>
      </div>
    </div>
  );
};

export default function Game({
  selectedPage,
  setSelectedPage,
  status,
  setStatus,
  allPage,
  setAllPage,
  lockAction,
  setLockAction,
  showResultPopup,
  setShowResultPopup,
  rooms,
  setRooms,
  winners,
  setWinners,
  //
  bingoNumber,
  setBingoNumber,
  waitPages,
  setWaitPages,
  callNumber,
  setCallNumber,
  callNumberHistory,
  setCallNumberHistory,
}) {
  const toastId = useRef(null);
  const [messageDialog, setMessageDialog] = useState(null);
  const { user } = useLoginGoogle();
  const { toastInfo } = useToast();

  const { socket, loadGame } = useSocketLoto();

  useEffect(() => {
    socket.on(SOCKET_EMIT.LOAD_GAME, (socketData) => {
      console.log("socketData: ", socketData);
      const { PAGES = [], allRoomDetails, allowSheets } = socketData;
      if (!PAGES) {
        console.log("CANNOT LOAD PAGE");
        toastInfo("Không thể kết nối server.");
        return;
      }
      setAllPage(filterPages(allowSheets));
      setStatus(GAME_STATE.PREPARE);
    });

    socket.on(SOCKET_EMIT.START_GAME, (socketData) => {
      console.log("socketData: ", socketData);
      const { success, data, error } = socketData;

      if (error) {
        const { message } = error;
        toastInfo(message);
        return;
      }

      if (success) {
        const { lockAction } = data;
        setLockAction(lockAction);
        setStatus(GAME_STATE.PLAYING);
      }
    });

    socket.on(SOCKET_EMIT.CALL_NUMBER, (socketData) => {
      console.log("SOCKET_EMIT.CALL_NUMBER: ", socketData);
      const {
        success,
        data: { isEndGame, winners, callNumber },
        error,
      } = socketData || {};

      if (!success) {
        if (error?.message) {
          toastInfo(error?.message);
        }
        return;
      }

      setCallNumber(callNumber);
    });

    socket.on(SOCKET_EMIT.END_GAME, (socketData) => {
      console.log("SOCKET_EMIT.END_GAME: ", socketData);
      const { success, data, error } = socketData || {};

      if (error?.message) {
        const { message } = error;
        toastInfo(message);
      }

      if (success) {
        const { lockAction, winners } = data;
        setLockAction(lockAction);

        if (winners.some((winner) => winner.sid === socket.id)) {
          toastInfo("Bạn KINH rồi");
        } else {
          const name = winners.map((w) => w.name).join(", ");
          toastInfo(`${name} KINH rồi. Chúc bạn may mắn lần sau.`);
        }
        setWinners(winners);
        setShowResultPopup(true);
      }
    });
  }, [socket]);

  useEffect(() => {
    if (loadGame && user) loadGame({ user });
  }, [user]);

  useEffect(() => {
    const arrWait = [];
    selectedPage.forEach((page) => {
      let waitPage = {
        ...page,
        waits: [],
      };
      const pageStatus = checkPageStatus(page?.matrix, callNumberHistory);
      if (pageStatus?.status === "WAIT") {
        waitPage = {
          ...waitPage,
          waits: [...new Set([...pageStatus?.waits, waitPage.waits])],
        };
        arrWait.push(waitPage);
      } else if (pageStatus?.status === "KINH") {
        setBingoNumber(callNumber);
        setStatus(GAME_STATE.END);
      }
    });
    setWaitPages(arrWait);
  }, [callNumber, user.name, callNumberHistory]);

  useEffect(() => {
    if (callNumber) {
      setCallNumberHistory((ch) => {
        return [...ch, callNumber];
      });
    }
  }, [callNumber]);

  const notify = (info) => {
    toastId.current = toast.info(info, {
      closeButton: false,
      theme: "colored",
    });
  };

  const pressSelectPage = (page) => {
    if (status !== GAME_STATE.PREPARE) {
      notify(`State ${status} Không thể chọn`);
      return;
    }

    if (selectedPage?.length >= MAX_SELECTED_PAGE) {
      notify("Mỗi người chơi chỉ chọn được tối đa 3 tấm.");
      return;
    }

    const fPage = selectedPage.find((p) => p.id === page.id);
    if (fPage) {
      return;
    }

    setSelectedPage((slPage) => {
      return [...slPage, page].sort(customSortfunc);
    });
    setAllPage((allPage) => {
      const newAllPage = allPage.filter((p) => p.id !== page.id);
      return newAllPage.sort(customSortfunc);
    });
  };

  let objStatus = {
    [GAME_STATE.PREPARE]: "Chọn ít nhất 1 tờ trước khi tham gia",
    [GAME_STATE.WAIT]: "Chờ chủ phòng bắt đầu game...",
    [GAME_STATE.PLAYING]: "Đang gọi số",
    [GAME_STATE.END]: "Hết game rồi",
  };

  return (
    <>
      {/* <div style={{
        fontSize: 18,
        color: "#fff",
        alignSelf: "center",
        margin: 8,
      }}>
        {"----------"}
      </div> */}
      <div
        style={{
          fontSize: 18,
          color: "#fff",
          alignSelf: "center",
          margin: 8,
        }}
      >
        {objStatus[status] || ""}
      </div>
      <Divider />

      {status === GAME_STATE.PREPARE && (
        <RenderPageGroup
          pages={allPage}
          status={status}
          pressPage={pressSelectPage}
          callNumberHistory={[]}
          isSmall={true}
        />
      )}
      {[GAME_STATE.PLAYING, GAME_STATE.END].includes(status) &&
        callNumberHistory?.length && (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: 8,
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {callNumberHistory.map((cNumber, key) => {
              return (
                <div
                  key={key}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                    backgroundColor: "#fff",
                    margin: 2,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    {cNumber || ""}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      {[GAME_STATE.PLAYING, GAME_STATE.END].includes(status) && callNumber && (
        <RenderCircleNumber number={callNumber} />
      )}
      <Divider />

      <div
        style={{
          flexDirection: "row",
        }}
      >
        {_.flattenDeep(waitPages.map((wPage, wPageKey) => wPage?.waits)).map(
          (number) => {
            return (
              <RenderCircleNumber
                number={number}
                circleStyle={{
                  backgroundColor: "pink",
                }}
              />
            );
          }
        )}
      </div>

      <RenderPageGroup
        pages={selectedPage}
        status={status}
        pressPage={(page) => {
          setSelectedPage((slPage) => {
            const newSelectPage = slPage.filter((p) => p.id !== page.id);
            return newSelectPage.sort(customSortfunc);
          });
          setAllPage((allPage) => [...allPage, page].sort(customSortfunc));
        }}
        callNumberHistory={callNumberHistory}
        bingoNumber={bingoNumber}
        waitPages={waitPages}
      />
      <AlertDialogCustom
        setIsOpen={setMessageDialog}
        isOpen={messageDialog}
        message={messageDialog}
      />
    </>
  );
}

const RenderManyPage = ({
  groupPage,
  status,
  callNumberHistory,
  pressPage,
  isSmall = false,
  bingoNumber = 0,
  waitPages = [],
}) => {
  return (
    <>
      {groupPage.map((page, pageKey) => {
        return (
          <div
            key={pageKey}
            disabled={status !== GAME_STATE.PREPARE}
            onPress={() => pressPage(page)}
            style={{
              marginLeft: 4,
            }}
          >
            <Page
              {...page}
              {...{ borderColor: "#edede9", paddingColor: "#000" }}
              status={status}
              callNumberHistory={callNumberHistory}
              isSmall={isSmall}
              bingoNumber={bingoNumber}
              waitPages={waitPages}
            />
          </div>
        );
      })}
    </>
  );
};

const RenderPageGroup = ({
  pages,
  status,
  pressPage,
  callNumberHistory,
  isSmall = false,
  bingoNumber = 0,
  waitPages = [],
}) => {
  return (
    <div>
      <div
        style={{
          paddingTop: 16,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <RenderManyPage
          {...{
            groupPage: pages,
            status,
            callNumberHistory,
            pressPage,
            isSmall,
            bingoNumber,
            waitPages,
          }}
        />
      </div>
      <div style={{ borderBottomWidth: 4, borderBottomColor: "#000" }}></div>
    </div>
  );
};

const Page = (props) => {
  const {
    matrix,
    color,
    borderColor,
    textColor,
    number,
    isSmall,
    paddingColor,
    status,
    callNumberHistory,
    bingoNumber = 0,
    waitPages = [],
  } = props;

  const renderColumn = (matrix) => {
    return (
      <div>
        <div
          style={{
            backgroundColor: borderColor,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {number}
          </div>
        </div>
        {matrix.map(renderRow)}
      </div>
    );
  };

  const renderRow = (row, key) => {
    let rowStatus = "";
    const hasNumber = _.intersection(row, callNumberHistory)?.length;
    if (hasNumber === 5) {
      if (bingoNumber !== 0) {
        console.log("rowBingo: ", row);
        rowStatus = "BINGO";
      }
    } else if (hasNumber === 4) {
      rowStatus = "WAIT";
    }

    const objColor = {
      WAIT: "#14213d",
      BINGO: "#d62828",
    };

    return (
      <div
        key={key}
        style={{
          flexDirection: "row",
          backgroundColor: objColor[rowStatus] || "white",
        }}
      >
        {row.map(renderCell)}
      </div>
    );
  };

  const renderCell = (number, cellKey) => {
    const isSelected = callNumberHistory && callNumberHistory.includes(number);
    const isPlaying =
      status === GAME_STATE.PLAYING || status === GAME_STATE.END;
    const smallStyle = {
      width: 16,
      height: 24,
    };
    const smallText = {
      fontSize: 8,
    };

    return (
      <div
        key={cellKey}
        style={[
          {
            width: 28,
            height: 44,
            justifyContent: "center",
            alignItems: "center",
            margin: 2,
          },
          isSmall && smallStyle,
          { backgroundColor: color },
        ]}
      >
        {isPlaying && isSelected ? (
          <div
            style={{
              width: 24,
              height: 24,
              backgroundColor: "red",
              borderRadius: 16,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={[
                {
                  color: textColor,
                  fontSize: 12,
                  fontWeight: "bold",
                },
                isSmall && smallText,
              ]}
            >
              {number || ""}
            </div>
          </div>
        ) : (
          <div
            style={[
              {
                color: textColor,
                fontSize: 12,
                fontWeight: "bold",
              },
              isSmall && smallText,
            ]}
          >
            {number || ""}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          borderColor,
          borderWidth: 2,
        }}
      >
        {renderColumn(matrix)}
      </div>
      <div
        style={{
          borderWidth: 2,
          borderColor: paddingColor,
        }}
      ></div>
    </div>
  );
};
