export function checkImageExists(imageUrl, callback) {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    // Image loaded successfully
    callback(true);
  };

  img.onerror = () => {
    // Image failed to load
    callback(false);
  };
}

export async function getImageSize(url) {
  return new Promise((resolve, reject) => {
    const newImage = new Image();
    newImage.onload = () => {
      resolve({ width: newImage.width, height: newImage.height });
    };
    newImage.onerror = () => {
      reject(new Error("Failed to load image"));
    };
    newImage.src = url;
  });
}

export const calculatorImageSizeFitWindowSize = async (imgUrl) => {
  const size = await getImageSize(imgUrl);
  const width = window.innerWidth;
  const height = window.innerHeight - 32 - 20;
  let jW = width;
  let jH = height;
  if (size.width > size.height) {
    // nằm ngang
    jH = Math.round((width * 9) / 16);

    if (jH > height) {
      jH = height - 32;
      jW = (height * 16) / 9;
    }
  } else {
    jW = Math.round((height * 9) / 16);
    // nằm dọc
    jH = jH - 32;
  }

  return {
    width: jW,
    height: jH,
  };
};

export const setImageCenter = (imageRef, imgUrl, imageSize) => {
  if (!imageRef?.current?.style) return;
  const iStyle = imageRef.current.style;
  iStyle.display = "flex";
  iStyle.backgroundImage = `url(${imgUrl})`;
  iStyle.transform = "scale(1, 1)";
  iStyle.width = `${imageSize.width}px`;
  iStyle.height = `${imageSize.height}px`;
  iStyle.backgroundSize = "cover";
  iStyle.marginTop =
    window.scrollY + (window.innerHeight - imageSize.height) / 2 + "px";
  iStyle.marginLeft =
    window.scrollX + (window.innerWidth - imageSize.width) / 2 + "px";
};

export const setImageCenterByImageUrl = async (imageRef, imgUrl) => {
  const imageSize = await calculatorImageSizeFitWindowSize(imgUrl);
  setImageCenter(imageRef, imgUrl, imageSize);
};

export function resizeImageToFitContainer(
  imageDimensions,
  containerDimensions
) {
  const { width: imageWidth, height: imageHeight } = imageDimensions;
  const { width: containerWidth, height: containerHeight } =
    containerDimensions;

  // Calculate aspect ratios
  const imageAspectRatio = imageWidth / imageHeight;
  const containerAspectRatio = containerWidth / containerHeight;

  let newWidth, newHeight;

  // Scale image to fit container
  if (imageAspectRatio > containerAspectRatio) {
    // Image is wider than container
    newWidth = containerWidth;
    newHeight = containerWidth / imageAspectRatio;
  } else {
    // Image is taller or same aspect ratio as container
    newHeight = containerHeight;
    newWidth = containerHeight * imageAspectRatio;
  }

  // Round dimensions to nearest whole number
  newWidth = Math.round(newWidth);
  newHeight = Math.round(newHeight);

  return { width: newWidth, height: newHeight };
}
