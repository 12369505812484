import moment, { months } from "moment";
import { emptyImageCard } from "../../assets/images";

export const COLOR_MATRIX = [
  ["#f43f5e", "#be123c", "#881337"],
  ["#ec4899", "#be185d", "#831843"],
  ["#d946ef", "#a21caf", "#701a75"],
  ["#8b5cf6", "#6d28d9", "#4c1d95"],
  // ["#3b82f6", "#1d4dd8", "#1e3a8a"],
  ["#06b6d4", "#0e7490", "#164e63"],
  ["#22c55e", "#15803d", "#14532d"],
  ["#84cc16", "#4d7c0f", "#365314"],
  ["#eab308", "#a16207", "#713f12"],
  // ["#ef4444", "#b91c1c", "#7f1d1d"],
  ["#5d5d5d", "#404040", "#3d3d3d"],
];

export const COLOR_CALENDAR_MATRIX = [
  ["#d50000", "#e67c73", "#ff5959"],
  ["#f4511e", "#f6bf26", "#7a1d14"],
  ["#33b679", "#0b8043", "#0c593c"], //, "#01321a"
  ["#039be5", "#3f51b5", "#323d76"],
  ["#7986cb", "#8e24aa", "#c14fe6"],
  ["#616161", "#888888", "#3d3d3d"],
];

export const COLOR_CALENDAR_TOOLTIP = {
  "#d50000": "Việc quản lý, chiến lược",
  "#e67c73": "",
  "#f4511e": "Việc tạo thu nhập cố định hằng tháng",
  "#f6bf26": "Việc làm không hiệu quả hoặc tạo cảm xúc tiêu cực cần phải bỏ",
  "#33b679": "Thời gian giải trí, relax",
  "#0b8043": "Việc làm có ý nghĩa dài hạn về sở thích (Sở thích 75%)",
  "#039be5":
    "Việc làm vì sở thích có bổ trợ khả năng kiếm tiền chính trong tương lai (Sở thích 50% + Kiếm tiền 50%)",
  "#3f51b5": "Thời gian đầu tư thử nghiệm một việc làm trong tương lai",
  "#7986cb": "",
  "#8e24aa": "Việc làm kiếm tiền phụ (Coin)",
  "#616161": "Việc thiết yếu trong ngày",
  "#888888": "",
};

export const MINDOMO_COLOR = [
  "#A7CCFF",
  "#F9BEAB",
  "#FFDD8F",
  "#B1E6FC",
  "#D4FC7E",
  "#FFF8B7",
  "#FFB2D3",
  "#EDBCFF",
];

export const COLOR_CARD_DEFAULT = "#404040";

export const CARD_EMPTY_DEFAULT = {
  name: "Lorem Ipsum",
  subHeading: "What is Lorem Ipsum?",
  description:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  startTime: moment().toDate(),
  // image: emptyImageCard,
  image: "",
  completedTime: moment().toDate(),
  color: COLOR_CARD_DEFAULT,
};

// const colorMatrix = [
//   ["rose.500", "rose.700", "rose.900"],
//   ["fuchsia.500", "fuchsia.700", "fuchsia.900"],
//   ["violet.500", "violet.700", "violet.900"],
//   ["blue.500", "blue.700", "blue.900"],
//   ["cyan.500", "cyan.700", "cyan.900"],
//   ["green.500", "green.700", "green.900"],
//   ["yellow.500", "yellow.700", "yellow.900"],
//   // ["orange.500", "orange.700", "orange.900"],
//   ["red.500", "red.700", "red.900"],
//   ["trueGray.500", "trueGray.700", "trueGray.900"],
// ]

export const TAG_TYPES = [
  {
    label: "Phim",
    value: "Movie",
  },
  {
    label: "Thử một điều mới",
    value: "Try a new thing",
  },
  {
    label: "Ăn một món mới",
    value: "New food",
  },
  {
    label: "Chơi một game mới",
    value: "New game",
  },
  {
    label: "Chơi boardgame yêu thích",
    value: "Play my favorite boardgame",
  },
  {
    label: "Du ngoạn",
    value: "Trip",
  },
  {
    label: "Thử thách lập trình",
    value: "Code Challenge",
  },
  {
    label: "Airdrop",
    value: "Airdrop",
  },
  {
    label: "Push yourself",
    value: "Push yourself",
  },
  {
    label: "Đầu tư Skin in the Game",
    value: "Invest Skin in the Game",
  },
  {
    label: "Kỉ niệm",
    value: "Memories",
  },
];
