import React from "react";
import TitlebarImageList from "../../elements/MUI/TitlebarImageListMUI";
import { YUGI_THE_DESTINY } from "../../constants/yugithedestiny.constants";

function YugiTheDestiny(props) {
  // const itemData = [
  //   {
  //     img: "/yugithedestiny/000002_card.png",
  //     title: "Image",
  //     author: "author",
  //   },
  // ];

  const itemData = YUGI_THE_DESTINY.map((name, key) => {
    const strId = String(Number(key) + 1).padStart(6, "0");
    return {
      img: `/yugithedestiny/${strId}_card.png`,
      title: name,
      id: strId,
      // author: "author",
    };
  });

  return (
    <div className="flex flex-1 bg-white h-screen w-screen">
      <TitlebarImageList header="Yugi the Destiny" {...{ itemData }} />
    </div>
  );
}

export default YugiTheDestiny;
