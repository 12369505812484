import _ from "lodash";

export const WALLET_TYPE = {
  EVM: "EVM",
  SOLANA: "SOLANA",
  APTOS: "APTOS",
  STAKNET: "STAKNET",
  KEPLR: "KEPLR",
  UNISAT: "UNISAT",
  DOGE: "DOGECHAIN",
  RONIN: "RONIN",
  TON: "TON",
};

export const WALLETS = [
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.EVM,
    name: "Metamask",
    walletName: "Metamask",
    address: "0x3798Ad941CeE2838706A21601Fd0A047861e741c",
    browser: "Chrome",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.SOLANA,
    name: "Solana-Phantom",
    walletName: "Phantom",
    address: "6mkAc4NTtxsGSJqymVNM9q1FaVuLYg1uhSkFeu4KdsBn",
    browser: "Chrome",
    notes: "Sử dụng cho ví phantom",
  },
  {
    retroactiveNumber: 0,
    type: WALLET_TYPE.EVM,
    name: "Metamaskx5",
    walletName: "Metamask",
    address: "0x6d2DA1281ffE67219CAD6f4fB0986CBcA66f40d00",
    browser: "Chrome Canary",
  },
  {
    retroactiveNumber: 2,
    type: WALLET_TYPE.EVM,
    name: "jiinguyen",
    address: "0xE0A4AAa7A7D0445ef3df96AC3365aa42D9677bA0",
    browser: "Edge",
  },
  {
    retroactiveNumber: 2,
    type: WALLET_TYPE.SOLANA,
    name: "jiinguyen-Solana",
    address: "8SRHy61zjrPoYMXJstT1XnM4NoSBoW71XXuHS8wveaG7",
    browser: "Edge",
    notes: "Sử dụng chung key với mtm",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.STAKNET,
    name: "Starknet",
    walletName: "AgentX",
    address:
      "0x02570861c28657237957B4062Bb0cc15bC88eA9C2613d075E515d1Bc7Da69DcC",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.APTOS,
    name: "Pontem",
    walletName: "Pontem",
    address:
      "0x51ae9ecf0224f2c917d689bbe165c709af8d325cc6f781ff60b3288014a9f4ab",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.KEPLR,
    name: "Keplr",
    walletName: "Keplr",
    address: "cosmos1y57lcr8g5zhwv62l0gvr8yyzpfxp09mapku37z",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.SOLANA,
    name: "Metamask-Solana",
    walletName: "Metamask-Sol",
    address: "dU7i4JWJsHm59fmTcvoX6tCKCJqzUBmp7mwEMDtNcZ8",
    notes: "Sử dụng chung key với mtm, port từ ví metamask thông qua solflare",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.RONIN,
    name: "Ronin",
    walletName: "Ronin",
    address: "0xf5c101d0039365a589b36915b53338873e3ea2e9",
    notes: "Ví ronin",
  },
  {
    retroactiveNumber: 2,
    type: WALLET_TYPE.STAKNET,
    name: "Starknet 2",
    walletName: "AgentX 3",
    address:
      "0x00B12F09E1EcdAE9124D914d0dDB6615F824D2270b0DE1aF550adD98bE711A9a",
    browser: "Edge",
    notes: "Sử dụng chung key với mtm",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.UNISAT,
    name: "Unisat",
    walletName: "DogiWallet",
    address: "bc1q7yp62kkqrkzupnk2k075j0zkuk4wndn9yvgzwx",
    browser: "Chrome",
    notes: "",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.UNISAT,
    name: "Unisat",
    walletName: "DogiWallet",
    address: "tb1q7yp62kkqrkzupnk2k075j0zkuk4wndn9w2n344",
    browser: "Chrome",
    notes: "Testnet Bitcoin",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.UNISAT,
    name: "Unisat",
    walletName: "UnisatBTC Mainnet",
    address: "bc1qsx99fnkfs0vgq0fy6kga2zu0azw7kcg03kad5y",
    browser: "Chrome",
    notes: "Mainnet BTC",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.UNISAT,
    name: "Unisat",
    walletName: "UnisatBTC Testnet",
    address: "tb1qsx99fnkfs0vgq0fy6kga2zu0azw7kcg0msx70h",
    browser: "Chrome",
    notes: "Testnet Bitcoin",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.DOGE,
    name: "Dog Labs",
    walletName: "Dog Labs",
    address: "DLUexiajbWu46o6bFQhkpzWt1qy8wsEHzK",
    browser: "Chrome",
    notes: "Chung key với Unisat",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.DOGE,
    name: "Gate Dogechain",
    walletName: "Gate Dogechain",
    address: "DSttxPEcRKxdTsZpV3CJxf54N3WostQjju",
    browser: "Chrome",
    notes: "Tài khoản gate io",
  },
  {
    retroactiveNumber: 1,
    type: WALLET_TYPE.TON,
    name: "TON",
    walletName: "TonKeeper",
    address: "UQBHr9JM3FrPqs2ptY0HJnkHNkCnJNXQ3ireDqyQIdh6vJ5m",
    browser: "Chrome",
    notes: "Tài khoản ton",
  },
  // {
  //   retroactiveNumber: 0,
  //   type: WALLET_TYPE.EVM,
  //   name: "",
  //   address: "",
  //   browser: "",
  // },
  // {
  //   retroactiveNumber: 0,
  //   type: WALLET_TYPE.EVM,
  //   name: "",
  //   address: "",
  //   browser: "",
  // },
  // {
  //   retroactiveNumber: 0,
  //   type: WALLET_TYPE.EVM,
  //   name: "",
  //   address: "",
  //   browser: "",
  // },
  // {
  //   retroactiveNumber: 0,
  //   type: WALLET_TYPE.EVM,
  //   name: "",
  //   address: "",
  //   browser: "",
  // },
  // {
  //   name: '',
  //   address: ''
  // },
];

export const EVM_WALLETS = WALLETS.filter((it) => it.type === WALLET_TYPE.EVM);

export const SOLANA_WALLETS = WALLETS.filter(
  (it) => it.type === WALLET_TYPE.SOLANA
);

export const APTOS_WALLETS = WALLETS.filter(
  (it) => it.type === WALLET_TYPE.APTOS
);

export const STARKNET_WALLETS = WALLETS.filter(
  (it) => it.type === WALLET_TYPE.STAKNET
);

export const GROUP_WALLET = Object.values(
  _.groupBy(WALLETS, "retroactiveNumber")
);
