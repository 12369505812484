// import { configureStore } from '@reduxjs/toolkit';
// import challengeGroupReducer from './challengeGroup';

// const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//     challengeGroup: challengeGroupReducer,
//   },
// })
// export default store;

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { pokemonApi } from "./services/pokemon";
import { challengeAPI } from "./services/challenge";
import { challengeGroupAPI } from "./services/challengeGroup";
import challangeSlice from "./challangeSlice";

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [challengeAPI.reducerPath]: challengeAPI.reducer,
    [challengeGroupAPI.reducerPath]: challengeGroupAPI.reducer,
    challenge: challangeSlice,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    return middleware
      .concat(pokemonApi.middleware)
      .concat(challengeAPI.middleware)
      .concat(challengeGroupAPI.middleware);
  },
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
export default store;
