import React, { useState, useEffect } from 'react'
import _ from 'lodash';
const GoogleAuthContext = React.createContext({ user: null });
 
const GoogleLoginProvider = ({ children }) => {

  const [user, setUser] = useState();
  
  useEffect(() => {
    if(_.isNull(user)){
      localStorage.removeItem('user');
    } if(user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  return (
    <GoogleAuthContext.Provider value={{ user, setUser }}>
      {children}
    </GoogleAuthContext.Provider>
  )
}
 
export const useLoginGoogle = () => React.useContext(GoogleAuthContext)

export default GoogleLoginProvider