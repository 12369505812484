import React, { useRef } from "react";
import { toast } from "react-toastify";
import { useCallback } from "react";

const useToast = () => {
  const toastId = useRef(null);

  const toastInfo = useCallback(
    (info) => {
      toastId.current = toast.info(info, {
        closeButton: false,
        theme: "colored",
      });
    },
    [toastId]
  );

  return {
    toastInfo,
  };
};

export default useToast;
