import React, { useState } from "react";
import AccordionMUI from "./components/AccordionMUI";
import { getRandomLightHexColor } from "../../utils/color";

function MissAccordion(props) {
  const { title = "", data = [], style, defaultShow = false } = props || {};
  const [show, setShow] = useState(defaultShow);
  return (
    <div
      style={{
        backgroundColor: getRandomLightHexColor(),
        margin: 24,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        cursor: "pointer",
        ...(style || {}),
      }}
    >
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          textAlign: "center",
          padding: 8,
          color: "#ff5e5e",
        }}
        onClick={() => setShow(!show)}
      >
        {title}
      </div>
      <div style={{ display: show ? "flex" : "none", flexDirection: "column" }}>
        {data.map((miss, key) => {
          return (
            <AccordionMUI
              key={key}
              title={`${miss?.label}`}
              titleStyle={{ backgroundColor: getRandomLightHexColor() }}
            >
              {miss?.parts?.map((part, key1) => {
                return (
                  <div key={key1}>
                    <div style={{ fontWeight: "bold" }}>{part?.label}</div>

                    {typeof part?.value === "string" ? (
                      <div style={{ paddingLeft: 12 }}>{part?.value}</div>
                    ) : (
                      part?.value?.map((v, k) => {
                        return (
                          <div key={k} style={{ paddingLeft: 12 }}>
                            - {v}
                          </div>
                        );
                      })
                    )}
                  </div>
                );
              })}
            </AccordionMUI>
          );
        })}
      </div>
    </div>
  );
}

export default MissAccordion;
